(function(page, api, root, section) {

    function get(vehicle, location) {
                
        var requested = new Date().toISOString();
        return Promise.resolve(api.base())
        .then(function(base) {
            return base + "v1/" + (!!location ? "locations/" + location + "/" : "") + "vehicles/" + vehicle + "/permits/temporary/usage";
        })
        .then(function(url) {
            return fetch(url + "?valid=" + encodeURIComponent(dateFns.format(dateFns.subHours(new Date(), (365 * 24) - 0)) + "/" + dateFns.format(dateFns.addHours(new Date(), 30 * 24))) + "&viewpoint=" + requested);
        })
        .then(api.response.process)
        .then(function(json) {
            //var data = response.json();
            json.requested = requested;
            //console.log(data);

            return json;
            
        })
        .catch(function(err) {
            alert(err);
            return Promise.reject(err);  
        });
    }

    var header = _.template('<h1 class="vehicle id" data-type="<%= _.get(record, "type") %>"><%= _.get(record, "display") || _.get(record, "name") %></h1>', {
        "variable": "record",
        // imports: {
        //     cal: generated,
        // },
    });

    var chart = _.template('<h1><%= _.get(record, "issuer.permits.title") %>: <%= _.get(record, "limit.display") %></h1><figure data-records="usage/<%= _.get(record, "limit.id") %>"></figure>', {
        "variable":"record",
    });

    function permits(json) {

        // pull out the permits
    }

    function setup(vehicle, location) {

        _.set(section.querySelector("header"), "innerHTML", "");

        if(!!vehicle) {
            _.invoke(root, "setAttribute", "data-record", "vehicles/" + vehicle);
            _.invoke(section, "setAttribute", "data-record", "vehicles/" + vehicle);
        } else {
            _.invoke(section, "setAttribute", "data-record", "vehicle");
        }

        return Promise.resolve();
    }

    function usage(json) {

        var elem = section.querySelector("section[data-records='usage']");
        var items = _.get(json, "usage.items");
        items = _.map(items, function(item) {

            _.set(item, "limit", _.get(json, [ "limits", "items", _.get(item, "limit") ]) || _.get(item, "limit"));
            _.set(item, "issuer", _.get(json, [ "issuers", "items", _.get(item, "issuer") ]) || _.get(item, "issuer"));

            return item;

        });
        elem.innerHTML = _.reduce(items, function(html, item) {
            return html + chart(item);
        }, "");

        _.each(items, function(item) {

            //var maxHours = moment.duration(_.get(item, "limit.time")).asHours();
            var divHours = moment.duration(_.get(item, "limit.divisor")).asHours();
            var max = moment.duration(_.get(item, "limit.time"));
            var maxHours = max.asHours();
            if(divHours === 1 && maxHours > 72) divHours = 24;

            console.log(maxHours, divHours, _.range(-1 * maxHours, maxHours + 1, divHours));

            var available = _.map(_.get(item, "available"), function(val, key) {
                return {
                    x:new Date(key),
                    y:moment.duration(_.get(val, "duration", val)).asHours(),
                };
            });

            var used = _.map(_.get(item, "used"), function(val, key) {
                return {
                    x:new Date(key),
                    y:moment.duration(_.get(val, "duration", val)).asHours(),
                };
            });


            //console.log(remaining, used);

            // init
            var fig = elem.querySelector("figure[data-records='usage/" + _.get(item, "limit.id") + "']");
            //console.log(fig);
            new Chartist.Line(fig, {
                    series: [
                        {
                            name:"available",
                            data: available,
                            //className: "permit",
                        },
                        {
                            name:"used",
                            data: used,
                            //className: "donotpermit",
                        },
                    ],
                }, {
                    showArea: true,
                	showLine: false,
                	showPoint: false,
                    chartPadding:2,
                    //stackBars: true,
                    series: {
                        "used": {
                            showArea:false,
                            showLine:true,
                        },
                    },
                    axisY: {
                        type: Chartist.FixedScaleAxis,
                        position: "end",
                        referenceValue: 0,
                        high: maxHours + (2 * divHours),
						//low:0 - maxHours - (2 * divHours), 
                        // showGrid:false,
                        ticks: _.filter(_.range(-1 * maxHours, maxHours + 1, divHours), function(val, i, col) {
                            if(col.length <= 9) return true;
                            var every = Math.round(col.length / 9);
                            return i % every === 0;
                        }),
						labelInterpolationFnc: function(value) {
                            //if(value <= 0) return "";
                            return divHours === 24 ? Math.abs(value) / 24 + "d" : Math.abs(value) + "h";
						}
                    },
                    axisX: {
						type: Chartist.FixedScaleAxis,
                        showGrid: true,
                        position: "start",
                        
						ticks: _.filter(_.map(available, "x"), function(value) {
							return new Date(value).getDay() == 1 && new Date(value).getHours() == 0;
						}),
						labelInterpolationFnc: function(value) {
						  return dateFns.format(value, 'd');
						}
                    },
                    plugins: [
						Chartist.plugins.ctTodayNow(),
						Chartist.plugins.timeline(),
					]
				});
            

        });
    }

    function view(json) {

        return Promise.join(json, function(json) {
            var record = _.get(json, [ "vehicles", "items", _.get(json, "vehicles.item") ]) ||  _.get(json, [ "accounts", "items", _.get(json, "accounts.item") ]);// || _.get(json, "tenants.item");

            if(!record) return Promise.reject(new Error("No Record Found"));

            //console.log(record);

            _.set(section.querySelector("header"), "innerHTML", header(record));

            _.invoke(section, "setAttribute", "data-record", "vehicles/" + _.get(record, "id", record));
            _.invoke(root, "setAttribute", "data-record", "vehicles/" + _.get(record, "id", record));

            // permits

            usage(json);

            return json;
        });
        
    }

    function error(err) {

    }

    var init = _.once(function(ctx) {

        var id = ctx.params.location;

        var attendant = api.attendant.get(id)
        .then(function(attendant) {

            if(!!attendant && !!attendant.id) {

                var title = _.filter([ "Parking at " , _.get(attendant, "address.name") ]).join(" ");

                if(!!attendant.id && id !== attendant.id && !!window.history && !!history.replaceState) history.replaceState({} , title, ctx.pathname.replace("/" + id + "/", "/" + attendant.id + "/"));

            }

            document.documentElement.dispatchEvent(new CustomEvent("ready", { "bubbles": true }));

        });

    });

    function show(ctx) {
        return Promise.join(init(ctx), setup(ctx.params.vehicle, ctx.params.location))
        .then(function() {
            return view(get(ctx.params.vehicle, ctx.params.location));
        })
        .catch(function(err) {
            alert(err);
        });
    };

    _.each({
        "/:location/vehicles/:vehicle":show,
    }, function(handler, path) {
        //console.log(path, handler);
        page(path, handler);
    });


}(page, ParkIQ.API, document.documentElement, document.querySelector("section[data-record^='vehicle']")));

// INDEX
(function(page, api, root, section) {

    function get(vehicle, location) {
                
        var requested = new Date().toISOString();
        return Promise.resolve(api.base())
        .then(function(base) {
            return base + "v1/" + (!!location ? "locations/" + location + "/" : "") + "vehicles/" + vehicle + "/permits/temporary/usage";
        })
        .then(function(url) {
            return fetch(url + "?valid=" + encodeURIComponent(dateFns.format(dateFns.subHours(new Date(), (365 * 24) - 0)) + "/" + dateFns.format(dateFns.addHours(new Date(), 30 * 24))) + "&viewpoint=" + requested);
        })
        .then(api.response.process)
        .then(function(json) {
            //var data = response.json();
            json.requested = requested;
            //console.log(data);

            return json;
            
        })
        .catch(function(err) {
            alert(err);
            return Promise.reject(err);  
        });
    }

    function view(json) {

        return Promise.join(json, function(json) {
            
            _.invoke(root, "setAttribute", "data-record", "vehicles");

            return json;
        });
        
    }

    function error(err) {

    }

    var init = _.once(function(ctx) {

        var id = ctx.params.location;

        var attendant = api.attendant.get(id)
        .then(function(attendant) {

            if(!!attendant && !!attendant.id) {

                var title = _.filter([ "Parking at " , _.get(attendant, "address.name") ]).join(" ");

                if(!!attendant.id && id !== attendant.id && !!window.history && !!history.replaceState) history.replaceState({} , title, ctx.pathname.replace("/" + id + "/", "/" + attendant.id + "/"));

            }

            document.documentElement.dispatchEvent(new CustomEvent("ready", { "bubbles": true }));

        });

    });

    function index(ctx) {
        return Promise.join(init(ctx))
        .then(function() {
            return view({});
        })
        .catch(function(err) {
            alert(err);
        });
    };

    _.each({
        "/:location/vehicles":index,
    }, function(handler, path) {
        //console.log(path, handler);
        page(path, handler);
    });


    root.addEventListener("submit", function(e) {
        if(!_.invoke(e, "target.matches", "form[data-ref='vehicle']")) return;

        e.preventDefault();

        var form = e.target;

        if(!!form.matches(":invalid")) {
            alert("Please complete all fields");
            return;
        }

        if (form.classList.contains("submitting")) return;
        
        var disable = _.map(form.querySelectorAll("button,input,textarea,select")).filter(function(input) {
            input.blur();
            return !input.disabled;
        });
        
        form.classList.add("submitting");

        //var formData = new FormData(form);
        
        var location = _.get(form.querySelector("input[name='location']"), "value", "");

        var id =  _.get(form.querySelector("input[name='vehicle']"), "value", "").replace(/[^A-Z0-9]/gi, "");

		disable.forEach(function(input) { input.disabled = true; });

        var requested = new Date().toISOString();

        // lets resolve base just to be sure
        var min = Promise.delay(1 * 1000);

        return Promise.resolve(page.redirect("/" + location + "/permits/new?vehicle=" + id))
        // return violation(api.Violations.get(id, null, location))
        // .then(function(record) {
        //     var id = _.get(record, "item.id") || _.get(record, "id");
        //     document.body.scrollIntoView();
        //     if(!!id) window.history.pushState(null, document.title, "/" + location + "/violations/" + id);
        // })
        // .then(function() {
        //     return min;
        // })
        // .catch(function(error) {
        //     switch(error.status) {
        //         case 404:
        //             alert("Can't find this violation")
        //             break;
        //         default:
        //             alert("Whoops, something went wrong!");
        //             break;
        //     }
            
        // })
        .finally(function() {
            form.classList.remove("submitting");
			disable.forEach(function(input) { input.disabled = false; });
        });
        

    });


}(page, ParkIQ.API, document.documentElement, document.querySelector("section[data-records='vehicles']")));

export default window;