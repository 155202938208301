<script context="module">
  import { parseISO, formatDistanceStrict, differenceInWeeks } from "date-fns";
  import { format, utcToZonedTime } from "date-fns-tz";
  import { toString as duration, between } from "duration-fns";
  import { get, identity } from "lodash-es";
  import PermitFees from "./PermitFees.svelte";
  import PermitPass from "./PermitPass.svelte";
  import PermitPhysicalAccess from "./PermitPhysicalAccess.svelte";
  import { readable } from "svelte/store";
  import { toSVG as svgbarcode } from "bwip-js";
  export const timeStore = function (seconds) {
    return readable(new Date(), (set) => {
      set(new Date());
      const i = setInterval(() => set(new Date()), seconds * 1000);
      return () => clearInterval(i);
    });
  };
  //export const minute = timeStore(60);
  export const now30s = timeStore(30);
</script>

<script>
  import MediaPlacement from "./MediaPlacement.svelte";
  import Record from "./RecordItem.svelte";

  export let permit;
  //export let property;

  let expire = false;

  //$: console.log("permit=", permit);
  $: policy = permit && permit.policy;
  $: property = permit && permit.property;
  $: account = !!property && get(property, "tenants.selfservice.enabled");

  $: noprint = permit && !permit.pass && !permit.expired && !permit.assigned;
  $: instructions = policy && policy.instructions; // && policy.instructions.replace("<br>","\n");
  $: policyRules = policy?.rules?.custom;
  $: addressInstructions =
    property?.address?.instructions || permit?.address?.instructions;
  $: contact =
    permit &&
    permit.assigned &&
    `Please contact ${get(
      permit,
      "location.contact.name",
      "management office"
    )} for more details or ${
      get(permit, "expired", true) ? "re-activation" : "to update"
    }.`;
  $: fees =
    permit &&
    permit.fees &&
    permit.fees.items &&
    Object.values(permit.fees.items);

  $: hasFees = fees && fees.length;

  $: issuedutc = permit && parseISO(permit.issued.utc);

  $: [startstr, endstr] = permit?.valid.interval.split("/") ?? ["", ""];
  $: startutc =
    startstr &&
    new Date(Math.max(parseISO(startstr), Math.min(issuedutc, Date.now())));

  $: startlocal = startutc && utcToZonedTime(startutc, permit.timezone);
  $: endutc = endstr && parseISO(endstr);
  $: endlocal = endutc && utcToZonedTime(endutc, permit.timezone);

  $: nowutc = permit && new Date(Math.max(Date.now(), $now30s));

  $: console.log("now", nowutc);

  $: pending = permit && nowutc < startutc;
  $: expired = permit && (permit.expired || nowutc > endutc);
  $: active = permit && !expired && !pending;
  //$: edited = permit && permit.edited;

  $: repermit =
    permit &&
    !pending &&
    policy &&
    !permit.assigned &&
    (!permit.media ||
      !policy.media ||
      !policy.media.request ||
      (policy.media.request && policy.media.repermit && permit.expired));

  $: expirable =
    permit &&
    !hasFees &&
    ((permit.cancelable && pending) || // may be cancelled pre-start and not cancelled
      (permit.reclaimable && active)); // may be ended early and still running

  //$: console.log("fees=", permit?.fees);

  $: hasDuration = endutc > startutc;

  function barcode(permit, startlocal, endlocal) {
    if (!permit) return "";
    try {
      return svgbarcode({
        bcid: "pdf417",
        //bcid: "datamatrixrectangularextension",
        // text: `${permit.id} ${startstr} until ${
        //   endstr || "revoked"
        // }`.toUpperCase(),
        text:
          (permit.vehicle ? `VEHICLE=${permit.vehicle.display} / ` : "") +
          `START=${format(startlocal, "h:mm a EEE MMM d yyyy", {
            timeZone: permit.timezone,
          })} / END=${
            endlocal
              ? format(endlocal, "h:mm a EEE MMM d yyyy", {
                  timeZone: permit.timezone,
                })
              : "not set"
          } / RECORD#=${permit.number} ${permit.id}`,
        binarytext: true,
        scale: 1,
        height: 6,
        padding: 0,
        //rows: 6,
        //columns: 10,
        includetext: false,
      });
    } catch {
      return "";
    }
  }
</script>

<figure class="permit">
  <header>
    {#if permit}
      <h1>
        <!-- <time class="{permit.valid.status}" datetime="{permit.valid.interval}">
                <dfn>{permit.valid.title}</dfn>
            </time>  -->
        {permit?.title || ""}
      </h1>
      <time
        class="status {permit.valid.status}"
        datetime={permit.valid.interval}
      >
        <dfn>{permit.valid.title}</dfn>
        {#if permit.cancelled}
          <time datetime="" />
        {:else if nowutc < startutc}
          <time class="relative" datetime={duration(between(nowutc, startutc))}
            >{formatDistanceStrict(startutc, nowutc, {
              addSuffix: true,
            })}</time
          >
        {:else if !endutc}
          <!-- <time class="relative" datetime="">Until Revoked</time> -->
        {:else if endutc && nowutc < endutc && differenceInWeeks(nowutc, endutc) < 1}
          <time class="relative" datetime={duration(between(nowutc, endutc))}
            >until {format(endlocal, "h:mm a EEEE")}
            <!-- {formatDistanceStrict(endutc, nowutc, {
                addSuffix:false
            })} -->
          </time>
        {:else if endutc && nowutc < endutc}
          <time class="relative" datetime={duration(between(nowutc, endutc))}>
            {formatDistanceStrict(endutc, nowutc, {
              addSuffix: false,
            })}
          </time>
        {:else if endutc && nowutc > endutc}
          <time class="relative" datetime={duration(between(nowutc, endutc))}
            >{formatDistanceStrict(endutc, nowutc, {
              addSuffix: true,
            })}</time
          >
        {/if}
      </time>
    {/if}
  </header>
  <!-- {#if permit && !permit.expired && permit.pass}
    <section class="pass">
        <header><p>Please print this pass and display it on your dashboard while parked.</p></header>
        <nav><a target="_blank" type="application/pdf" href="{permit.pass.doc.pdf.url.replace("passes.parkingboss.com", "parking-passes.imgix.net")}"></a><a href="{permit.pass.doc.pdf.url.replace("passes.parkingboss.com", "parking-passes.imgix.net")}" download="download" target="_blank" type="application/pdf">PDF</a><p><a href="{permit.pass.doc.pdf.url.replace("https://passes.parkingboss.com", "http://docs.parkingboss.net")}" download="download" target="_blank" type="application/pdf">Trouble printing?</a></p></nav>
        <figure class="pass doc preview letter margin0 loaded"><p class="content"><img src="{permit.pass.doc.pdf.url.replace("passes.parkingboss.com", "parking-passes.imgix.net")}?fm=png8&w=320&dpr=2"></p></figure></section>
    {/if} -->
  {#if permit}
    {#if permit.revoked}
      <dl class="subjects">
        <dt>{permit.revoked.title}</dt>
        <dd>
          <time datetime={permit.revoked.datetime}
            ><abbr title={parseISO(permit.revoked.datetime).toString()}
              >{format(
                utcToZonedTime(
                  parseISO(permit.revoked.datetime),
                  permit.timezone
                ),
                "h:mm a EEE MMM d yyyy",
                {
                  timeZone: permit.timezone,
                }
              )}</abbr
            ></time
          >
        </dd>
        {#if permit.successor}
          <!-- <dt>Changed</dt> -->
          <dd><a href={permit.successor.url}>See Updated Permit</a></dd>
        {/if}
      </dl>
    {:else if pending || endutc}
      <dl class="subjects">
        <dt class:min={endutc > startutc}>Start</dt>
        <dd>
          <time class:min={hasDuration} datetime={startstr}>
            <dfn>Start</dfn>
            <abbr class="time" title={startutc.toString()}
              >{format(startlocal, "h:mm a", {
                timeZone: permit.timezone,
              })}</abbr
            >
            <abbr class="date" title={startutc.toString()}
              >{format(startlocal, "EEE MMM d yyyy", {
                timeZone: permit.timezone,
              })}</abbr
            ></time
          >
        </dd>
        {#if endutc}
          <dt class:max={endutc > startutc}>End</dt>
          <dd>
            {#if !endutc}
              <time>When Revoked</time>
            {:else if permit.cancelled}
              <time>Cancelled</time>
            {:else if endutc > startutc}
              <time class="max" datetime={endstr || ""}>
                <dfn>End</dfn>
                <abbr class="time" title={endutc.toString()}
                  >{format(endlocal, "h:mm a", {
                    timeZone: permit.timezone,
                  })}</abbr
                >
                <abbr class="date" title={endutc.toString()}
                  >{format(endlocal, "EEE MMM d yyyy", {
                    timeZone: permit.timezone,
                  })}</abbr
                >
              </time>
            {:else}
              <time>Cancelled</time>
            {/if}
          </dd>
        {/if}
      </dl>
    {/if}
    <!-- <time class="validity" datetime="{permit.valid.interval}">
            <time class="min" datetime="{startstr}">
                <dfn>Start</dfn>
            <abbr title="{startutc.toString()}">{@html format(startlocal, "EEE'&nbsp;'MMM'&nbsp;'d'&nbsp;'yyyy h:mm'&nbsp;'a'&nbsp;'zzz", {
                timeZone: permit.timezone
            })}</abbr></time>
            <time class="max" datetime="{endstr||""}">
                {#if endlocal}
                <dfn>End</dfn>
                <abbr title="{endutc.toString()}">{@html format(endlocal, "EEE'&nbsp;'MMM'&nbsp;'d'&nbsp;'yyyy h:mm'&nbsp;'a'&nbsp;'zzz", {
                    timeZone: permit.timezone
                })}</abbr>
                {:else}When Revoked
                {/if}
                </time>
        </time> -->
    {#if permit && !expired && permit.pass}
      <PermitPass {permit} />
    {/if}
    {#if permit && permit.entry}
      <PermitPhysicalAccess {permit} />
    {/if}

    <!-- <a class="pass" href="{permit.pass.doc.pdf.url.replace("https://passes.parkingboss.com", "http://docs.parkingboss.net")}" target="_blank" download>
        <h1>Must display on dash while parked</h1>
        <dfn>Download & Print Pass</dfn>
    </a> -->
    <dl class="subjects">
      {#if permit && permit.visiting}
        <dt>Reason</dt>
        <dd>{permit.visiting}</dd>
      {/if}
      <!-- {#if permit && !permit.expired && permit.pass}
        <dt>Reason</dt>
        <dd>{permit.visiting}</dd>
        <dt>Pass</dt>
        <dd><img src="{permit.pass.doc.pdf.url.replace("passes.parkingboss.com", "parking-passes.imgix.net")}?fm=png8&w=320&dpr=2"></dd>
        {/if} -->
      {#each [permit.vehicle, permit.media, permit.tenant].filter(identity) as record}
        <dt>{record.title || record.format || record.type}</dt>
        <dd><Record item={record} /></dd>
      {/each}
      <!-- </dl>
    <dl class="subjects"> -->
      {#each Object.values(permit.spaces || []).filter(identity) as record}
        <dt>{record.format}</dt>
        <dd><Record item={record} /></dd>
      {/each}
    </dl>
    <figure class="barcode">
      {@html barcode(permit, startlocal, endlocal)}
    </figure>
    <!-- {#if noprint && !expired}
    <p>Digital permit, no printing necessary</p>
    {/if} -->
    {#if hasFees}
      <dl class="subjects">
        <dt>Receipt</dt>
        <dd>
          <data class="paid" value={(fees && fees.length) || 0}>
            <PermitFees {permit} />
          </data>
        </dd>
      </dl>
    {/if}

    <dl class="subjects">
      <dt>Recorded</dt>
      <dd>
        <time datetime={permit.issued.utc}
          ><abbr title={parseISO(permit.issued.utc).toString()}
            >{format(
              utcToZonedTime(parseISO(permit.issued.utc), permit.timezone),
              "h:mm a EEE MMM d yyyy",
              {
                timeZone: permit.timezone,
              }
            )}</abbr
          ></time
        >
      </dd>
      {#if permit.predecessor}
        <!-- <dt>Changed</dt> -->
        <dd><a href={permit.predecessor.url}>See Previous Permit</a></dd>
      {/if}
      <dt>Record #</dt>
      <dd><data value={permit.id}>{permit.number}</data></dd>
      <!-- {#if permit.revoked}
        <dt>{permit.revoked.title}</dt>
        <dd>
          <time datetime={permit.revoked.datetime}
            ><abbr title={parseISO(permit.revoked.datetime).toString()}
              >{format(
                utcToZonedTime(
                  parseISO(permit.revoked.datetime),
                  permit.timezone
                ),
                "h:mm a EEE MMM d yyyy",
                {
                  timeZone: permit.timezone,
                }
              )}</abbr
            ></time
          >
        </dd>
        {#if permit.successor}
          <dd><a href={permit.successor.url}>See Updated Permit</a></dd>
        {/if}
      {/if} -->
      {#if !permit.cancelled}
        <dt>Usage</dt>
        <dd>
          {permit.exempt
            ? "Not counted against limits"
            : "Counted against limits"}
        </dd>
      {/if}
      <dt>Synced</dt>
      <dd>
        <time datetime={permit.generated}
          >{format(parseISO(permit.generated), "MMM d yyyy h:mm a zzz")}</time
        >
      </dd>
    </dl>

    <nav>
      {#if expirable && !expire}<button
          class="expire"
          type="button"
          on:click={(e) => (expire = true)}
        >
          Cancel Parking
        </button>{/if}{#if expirable && expire}
        <section class="expire">
          <header />
          <form data-update="permits/{permit.id}" method="POST" novalidate>
            <h1>Cancel Parking</h1>
            <input type="hidden" name="_method" value="PUT" /><input
              type="hidden"
              name="permit"
              value={permit.id}
            /><input
              type="text"
              placeholder="Enter email or phone…"
              required="required"
              id="send-to-email"
              name="to"
            /><button type="submit">Cancel</button>
          </form>
        </section>
      {/if}{#if repermit}<a
          class="repermit"
          href="/{permit.scope}/permits/temporary/new?policy={permit.policy
            .subject}&vehicle={(permit.vehicle && permit.vehicle.key) ||
            ''}&space={(permit.space && permit.space.key) ||
            ''}&tel=&email=&notes={permit.description ||
            ''}&name={permit.name || ''}"
          >Re-{policy.issue.title} for {policy.title}</a
        >{/if}
      <a class="rules" href="/{permit.scope}/rules">Parking Rules</a>
      {#if account}
        <a class="usage" href="/{permit.scope}/account">Check Your Usage</a>
      {/if}
    </nav>
    <!-- <dl class="subjects">
            
        </dl> -->{#if permit.media}
      <MediaPlacement placement={property.media.placement} />
    {/if}
    <aside class="instructions">
      {#each [noprint && !expired && !permit.cancelled && "This permit is digitally recorded, no printing necessary. Approval to park only and does not guarantee space is available.", policyRules, !expired && instructions?.trim(), addressInstructions, contact?.trim()].filter(identity) as text}
        <p>{text}</p>
      {/each}
    </aside>
  {/if}
  <!-- {#if fees && fees.length }
    <ul class="fees" data-records="permits/{permit.id}/fees">
        {#each fees as item}
        <li data-record="fees/{item.id}">
            <h1>A parking fee was charged in accordance with <a href="/{item.scope}/rules">our rules</a>:</h1><dl><dt>{get(item, "description") || "Parking Fee" }</dt><dd><data value="{get(item, "payments.balance.value")}">{get(item, "payments.balance.display")}</data><data value="{get(item, "payments.total.value")}">{get(item, "payments.total.display")}</data></dd></dl>
            <ul data-records="fees/{item.id}/payments">
                {#each Object.values(get(item, "payments.items", [])) as item}
                <li data-record="payments/{item.id}">
                    <dl><dt>Paid</dt><dd><data value="{get(item, "total.value")}">{get(item, "total.display")}</data></dd><dt>on</dt><dd><time datetime="{item.created}">{format(utcToZonedTime(parseISO(item.created), item.timezone), "h:mm a EEE MMM d yyyy zzz")}</time></dd><dt>with</dt><dd>{get(item, "card.display")}</dd></dl>
                </li>
                {/each}
            </ul>
        </li>
        {/each}</ul>
    {/if} -->
</figure>
