export function formDataToURLSearchParams(formData) {

    if(!formData || !formData.entries) return null; // entries edge 17, url search params edge

    //try {

    // this should cover everything that supports iterating form data
    var urlSearchParams = new URLSearchParams();//[...formData.entries().filter(([k,v]) => typeof v == "string")]); // create an array from the entries iterator (edge 18 doesn't take an iterator)
    for(const [k, v] of formData) {
        if(typeof v != "string") continue;
        urlSearchParams.append(k, v);
    }

    return urlSearchParams;

    //let searchParams = null;
    //if(searchParams) return searchParams;

    //} catch(e) {

        const searchParams = new URLSearchParams();

        /**
         * This is all to replace this single line:
         * new URLSearchParams(formData), because Edge
         */
            const keys = [...formData.keys()];
            keys.forEach(key => {
            /**
             * For 'checkboxes', we need to append the values to the search params
             * and not just add a comma separated string.
             */
            if (keys.filter(x => x === key).length > 1) {
                /**
                 * We grab all the values and append them in one go
                 */
                formData.getAll(key).forEach(value => {
                searchParams.append(key, value);
                })

                /**
                 * Then remove all the remaining instances of the key from the
                 * keys array we're looping around
                 */
                keys.forEach((k, i) => {
                if (k === key) {
                    keys.splice(i, 1)
                }
                })
            } else {
                // Strings are simple in comparison
                searchParams.set(key, formData.get(key))
            }
            });

            return searchParams;

}