<script>
    export let placement;
    export let item = true;

    let title;
    let interior;
    let exterior;
    $:switch(placement) {
        case "interior-front-driver":
            title = "front windshield, driver side"
            interior = true;
            exterior = false
            break;
        case "interior-front-passenger":
            interior = true;
            exterior = false;
            title = "front windshield, passenger side"
            break;
        case "exterior-rear-driver":
            exterior = true;
            interior = false;
            title = "rear window, driver side";
            break;
        case "exterior-rear-passenger":
            exterior = true;
            interior = false;
            title = "rear window, passenger side";
            break;
    }

</script>
{#if interior || exterior}
<figure class="media-placement">
    
    <img src="/{placement}.svg">
    <figcaption>
        {#if item}
        <h1>Smart Decal must be displayed on {title}</h1>
        {:else}
        <h1>Smart Decal placement: {title}</h1>
        {/if}
        <ol>
        <li>
            Apply in temperatures between 40 and 120 F
        </li>
        <li>
            Clean and dry the surface before applying decal
        </li>
        <li>
            Apply decal to glass surface only
        </li>
        <li>
            Carefully peel the decal from paper lining by starting at the corner (permit will have tamper-resistant slits in it)
        </li>
        <li>
            Be careful not to touch the adhesive
        </li>
        <li>Ensure the barcode and number are facing out, clearly visible and not in a tinted area</li>
        <li>
            Starting in the center, apply even pressure across entire decal to ensure proper bonding
        </li>
    </ol>
    </figcaption> 
</figure>
    
{/if}