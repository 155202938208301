<script>

    export let policy;
    let select;

    $: if(select) select.dispatchEvent(new CustomEvent("change", { "bubbles": true }));

</script>
{#if policy.send && policy.send.enabled && ((policy.tel && policy.tel.request && policy.tel.send) || (policy.email && policy.email.request && policy.email.send))}
<label class="notification value"><span></span><select bind:this={select}>
    {#if policy.email.request && policy.email.send}
    <option value="email" title="Email confirmation only">Email confirmation only</option>
    {/if}
    {#if policy.tel.request && policy.tel.send}
    <option value="tel" title="Text confirmation only">Text confirmation only</option>
    {/if}
    {#if (policy.tel.send && policy.tel.request && policy.email.request && policy.email.send)}
    <option value="email,tel" title="Email & text confirmation">Email & text confirmation</option>
    {/if}
    <option value="" title="Don't send confirmation">Don't send confirmation</option>
    </select><span></span></label>
{/if}