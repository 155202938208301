(function(root, page, api) {
    

    function show(ctx) {
        

        var id = ctx.params.location;

        var attendant = api.attendant.get(id)
        .then(function(attendant) {

            //console.log("attendant.id", attendant.id);
            //console.log("attendant", attendant);

            // if(!attendant || !attendant.id) {
            //     alert("whoops, something went wrong!");
            //     return;
            // }

            root.setAttribute("data-records", "services");

            //view(attendant);

            document.documentElement.dispatchEvent(new CustomEvent("ready", { "bubbles": true }));
        });

    }

    var exit = function(ctx, next) {
        next();
    }
    
    _.each([
        //"/l/:location", 
        "/:location/services", 
        //"/:location"
    ], function(path) {
        page(path, show);
        page.exit(path, exit);
    });

    
}(document.documentElement, window.page, ParkIQ.API));

export default window;