//import _ from "lodash-es";

(function(page, qs, api, root, section) {

    var form = section.querySelector("form");
    var article = section.querySelector("article");
    var emailForm = root.querySelector("form[data-create='account/auth/tokens/email']");

    var tenantField = _.template('<% if(!!tenant && !!tenant.request) { %><label class="<%= tenant.type %> tenant id<%= tenant.required ? " required" : ""%><%= tenant.predefined ? " constrained" : ""%>" data-type="<%= tenant.type %>"><span><%= tenant.title %></span><input type="text" name="tenant" autocorrect="off" autocapitalize="characters" spellcheck="false" value="" maxlength="<%= tenant.predefined ? "30" : "8" %>" <%= tenant.required ? "required" : ""%> list="<%= tenant.predefined ? "account-tenants" : "" %>" /><% if(!!tenant.predefined) { %><datalist id="account-tenants"><% _.each(_.get(tenant, "items", []), function(item) { %><option><%= item.toUpperCase() %></option><% }); %></datalist><% } %></label><% if(!!tenant.predefined) { %><select tabindex="-1"><% _.each(_.get(tenant, "items", []), function(item) { %><option><%= item %></option><% }); %></select><% } %><% } %>', {
        variable:"tenant",
    });

    var authField = _.template('<label class="password id required"><span>Passcode</span><input type="text" pattern="[0-9]*" inputmode="numeric" autocorrect="off" autocomplete="off" spellcheck="false" name="password" required /></label><% if(!!auth && !!auth.email) { %><a href="<%= auth.email %>">Forgot?</a><% } %>',
    {
        variable:"auth",
    });

    form.addEventListener("submit", function(e) {
        e.preventDefault();

        return Promise.resolve(form)
        .then(function(form) {
        
            var input;

            if(!!(input = form.querySelector("input[name='password']:required:enabled")) && !input.value) {
                alert("Please enter passcode");
                return;
            }
            
            var disable = _.filter(form.querySelectorAll("button,input,textarea,select"), function(input) {
                input.blur();
                return !input.disabled;
            });
            
            if(disable.filter(function(input) {
                return !!input.required && !input.value;
            }).length > 0) return; // required with no value
        
            
            if (form.classList.contains("submitting")) return;
            
            form.classList.add("submitting");

            var formData = serialize(form); // do this sync

            disable.forEach(function(input) {	
                input.disabled = true;
            });


            var requested = new Date().toISOString();

            // lets resolve base just to be sure
            var min = Promise.delay(1 * 1000);
            return Promise.resolve(api.base())
            .then(function(base) {
                return base + "v1/accounts/auth/tokens?viewpoint=" + requested + "&" + formData;
            })
            .then(function(url) {
                return fetch(url, {
                    method: "POST",
                    //body: formData,
                });
            })
            .then(api.response.process)
            .then(function(data) {
                
                // OK
                // have data from response

                //_.set(result, "innerHTML", resultHtml(data));
                disable.forEach(function(input) {	
                    input.disabled = false;
                });
                form.classList.remove("submitting");
                form.reset();

                var account = _.get(data, [ "accounts", "items", _.get(data, "accounts.item") ]);

                page("/" + account.scope + "/accounts/" + account.id + "#Authorization=" + _.get(data, "type") + " " + _.get(data, "token"));
                
            })
            .then(null, function(error) { // better catch errors inside
                //console.debug(error);
                var data = error.data;
                //var responseText = error.text;
                //console.log(data);
                var response = error.response;
                var status = error.status;
                //console.debug(response);
                //console.log(response.status);
                
                
                
                disable.forEach(function(input) {	
                    input.disabled = false;
                });
                form.classList.remove("submitting");
                
                // bad request
                if(status === 400) {
                    alert(error.message);
                    return null;
                } else if(status === 401) {
                    alert("Incorrect passcode");
                    return null;
                }
                
                
                alert(error.message || "Whoops, something went wrong");
                
            });
        });

    });

    emailForm.addEventListener("submit", function(e) {
        e.preventDefault();

        var min = Promise.delay(2000);
        return Promise.resolve(e.target)
        .then(function(form) {
        
            var input;
            
            var disable = _.filter(form.querySelectorAll("button,input,textarea,select"), function(input) {
                input.blur();
                return !input.disabled;
            });
            
            if(disable.filter(function(input) {
                return !!input.required && !input.value;
            }).length > 0) return; // required with no value
        
            form.removeAttribute("data-success");
            form.removeAttribute("data-error");
            
            if (form.classList.contains("submitting")) return;
            
            form.classList.add("submitting");

            var formData = serialize(form).replace("tenant=","subject="); //sync

            disable.forEach(function(input) {	
                input.disabled = true;
            });


            var requested = new Date().toISOString();

            // lets resolve base just to be sure
            var min = Promise.delay(1 * 1000);
            return Promise.resolve(api.base())
            .then(function(base) {
                return base + "v1/account/auth/tokens/email?viewpoint=" + requested + "&" + formData;
            })
            .then(function(url) {
                return fetch(url, {
                    method: "POST",
                    //body: formData,
                });
            })
            .then(api.response.process)
            .then(function(data) {
                return min
                .then(function() {
                    return data;
                });
            })
            .then(function(data) {
                
                // OK
                // have data from response

                //_.set(result, "innerHTML", resultHtml(data));
                disable.forEach(function(input) {	
                    input.disabled = false;
                });
                form.classList.remove("submitting");
                //form.reset();

                form.setAttribute("data-success", "Great, check your inbox");
                
            })
            .then(null, function(error) { // better catch errors inside
                //console.debug(error);
                var data = error.data;
                //var responseText = error.text;
                //console.log(data);
                var response = error.response;
                var status = error.status;
                //console.debug(response);
                //console.log(response.status);
                
                
                
                disable.forEach(function(input) {	
                    input.disabled = false;
                });
                form.classList.remove("submitting");
                
                // bad request
                switch(status) {

                    case 400:
                        form.setAttribute("data-error", "Please check your info");
                        return null;
                        break;

                    case 403:
                        form.setAttribute("data-error", "This email doesn't match what's on file");
                        return null;
                        break;
                }
                
                
                alert(error.message || "Whoops, something went wrong");
                
            });
        });

    });

    function get(account, location, auth) {
                
        var requested = new Date().toISOString();
        return Promise.join(api.base(), new Date(), function(base, viewpoint) {

            // console.log([
            //     viewpoint,
            //     dateFns.addDays(viewpoint, 30)
            // ].map(function(d) { return dateFns.format(d); }).join("/"));

            return Promise.all([
                //fetch(base + "v1/" + (!!location ? "locations/" + location + "/" : "") + "tenants/" + account + "?valid=" + encodeURIComponent(dateFns.format(dateFns.subHours(new Date(), (365 * 24) - 0)) + "/" + dateFns.format(dateFns.addHours(new Date(), 30 * 24))) + "&ts=" + requested).then(api.response.process),
                fetch(base + "v1/" + (!!location ? "locations/" + location + "/" : "") + "tenants/" + account + "/permits?" + "viewpoint=" + encodeURIComponent([
                    viewpoint,
                ].map(function(d) { return dateFns.format(d); }).join("/")) + "&valid=" + encodeURIComponent([
                    viewpoint,
                    dateFns.addDays(viewpoint, 30)
                ].map(function(d) { return dateFns.format(d); }).join("/")) + "&Authorization=" + auth)
                .then(api.response.process),
                fetch(base + "v1/" + (!!location ? "locations/" + location + "/" : "") + "tenants/" + account + "/permits/temporary/usage?sample=PT24H" + "&viewpoint=" + encodeURIComponent([
                    viewpoint,
                ].map(function(d) { return dateFns.format(d); }).join("/")) + "&Authorization=" + auth)
                .then(api.response.process),
                fetch(base + "v1/accounts/" + account + "/auth/code?viewpoint=" + encodeURIComponent([
                    viewpoint,
                ].map(function(d) { return dateFns.format(d); }).join("/")) + "&Authorization=" + auth)
                .then(api.response.process),
                fetch(base + "v1/" + (!!location ? "locations/" + location + "/" : "") + "tenants/" + account + "/violations?" + "viewpoint=" + encodeURIComponent([
                    viewpoint,
                ].map(function(d) { return dateFns.format(d); }).join("/")) + "&valid=" + encodeURIComponent([
                    dateFns.subDays(viewpoint, 90),
                    viewpoint
                ].map(function(d) { return dateFns.format(d); }).join("/")) + "&Authorization=" + auth)
                .then(api.response.process),
            ])
            .then(function(values) {
				return _.merge.apply(_, values);
            });
        
            // function(a, b) {
            //     return _.defaultsDeep(a, b);
            // });


            //return base + "v1/" + (!!location ? "locations/" + location + "/" : "") + "tenants/" + account;
        })
        // .then(function(url) {
        //     return fetch(url + "?valid=" + encodeURIComponent(dateFns.format(dateFns.subHours(new Date(), (365 * 24) - 0)) + "/" + dateFns.format(dateFns.addHours(new Date(), 30 * 24))) + "&ts=" + requested);
        // })
        //.then(api.response.process)
        .then(function(json) {
            //var data = response.json();
            json.requested = requested;
            //console.log(data);

            return json;
            
        });
        // .catch(function(err) {
        //     alert(err);
        //     return Promise.reject(err);  
        // });
    }

    var header = _.template('<h1 class="<%= _.get(item, "type") %> <%= _.get(item, "format") %> id" data-type="<%= _.get(item, "format") %>"><%= _.get(item, "display") || _.get(item, "name") %></h1>', {
        "variable": "item",
        // imports: {
        //     cal: generated,
        // },
    });

    // var chart = _.template('<li><h1><%= _.get(item, "issuer.permits.title") %> limited to <%= _.get( item, "limit.display") %>, <%= _.get( item, "current.consumed.display") %> used</h1><!--<figure data-records="usage/<%= _.get(item, "limit.id") %>"></figure>--></li>', {
    //     "variable":"item",
    // });

    const _permit = '<li data-record="permits/<%= _.get(item, "id") %>"><h1><%= _.get( item, "title") %></h1><% if(!!_.get(item, "vehicle")) { %><data class="vehicle id" value="<%= _.get(item, "vehicle.id") || _.get(item, "vehicle") %>"><%= _.get(item, "vehicle.display") || _.get(item, "vehicle") %></data><% } %><a href="<%= _.get(item, "url") %>">Details</a></li>';

    const permit = _.template(_permit, {
        "variable":"item",
    });


    var issuer = _.template('<li data-record="issuers/<%= _.get(item, "id") %>"><h1><%= _.get(item, "title") %></h1><ul data-records="meters/usage"  data-records-count="<%= _.size(_.get(item, "usage.items")) %>"><% _.each(_.get(item, "usage.items"), function(item) { %><li data-record="meters/<%= _.get(item, "id") %>/usage"><h1>Limited to <%= _.get(item, "limit.display") %></h1><h2><%= _.get(item, "current.consumed.display") || "none" %> used</h2><!--<h2><%= _.get(item, "current.remaining.display") || "none" %> remaining</h2>--></li><% }); %><% if(_.get(item, "vehicle.limits.count", 0) > 0) { %><li data-record="meters/vehicle"><h1><%= _.get(item, "tenant.limits.count", 0) > 0 ? "Separate per-vehicle limits also apply" : "Per-vehicle limits apply" %></h1></li><% } %></ul><p>Active & upcoming:</p><ul data-records="permits/valid" data-records-count="<%= _.size(_.get(item, "permits.valid.items")) %>"><% _.each(_.get(item, "permits.valid.items"), function(item) { %>' + _permit + '<% }); %></ul></li>', {
        "variable":"item",
    });

    function setup(account) {


        if(!!account) {
            _.invoke(root, "setAttribute", "data-record", "accounts/" + account);
            _.invoke(section, "setAttribute", "data-record", "accounts/" + account);
            _.invoke(article, "setAttribute", "data-record", "accounts/" + account);
        } else {
            _.invoke(root, "setAttribute", "data-record", "accounts/?");
            _.invoke(section, "setAttribute", "data-record", "accounts/?");
            _.invoke(article, "setAttribute", "data-record", "accounts/?");
        }

        return Promise.resolve();
    }

    function view(json) {

        return Promise.join(json, function(json) {

            //console.log(json);

            var record = _.get(json, [ "tenants", "items", _.get(json, "tenants.item") ]) ||  _.get(json, [ "accounts", "items", _.get(json, "accounts.item") ]);// || _.get(json, "tenants.item");

            if(!record) return Promise.reject(new Error("No Record Found"));

            //_.set(record, "type", _.get(json, "units.type"), _.get(record, "type"));

            //console.log(record);

            _.set(article.querySelector("header"), "innerHTML", header(record));

            _.invoke(section, "setAttribute", "data-record", "accounts/" + _.get(record, "id", record));
            _.invoke(root, "setAttribute", "data-record", "accounts/" + _.get(record, "id", record));

            // permits
            //permits(json);
            passcodes(json);
            issuers(json);
            permits(json);
            violations(json);

            return json;
        });
        
    }
    
    function valid(datetime, updated) {
		return Promise.resolve(datetime)
		.then(function(datetime) {
			updated.setAttribute("datetime", !datetime ? "" : !!datetime.toISOString ? datetime.toISOString() : datetime);
        	updated.dispatchEvent(new CustomEvent("update", { "bubbles": true }));
			return datetime;
		})
    }

    function interval(data) {
        if(!data) return data;

        //console.log("interval.data", data);
        if(_.isString(data)) return _.filter(_.map(_.split(data, "/")));

        return _.filter(_.map([
            _.get(data, "min"),
            _.get(data, "max")
        ], "datetime"));
    }
    
    function isInInterval(ref, interval) {

        //console.log("isInInterval", interval, ref);

        if(!interval) return false;

        var min = interval[0];
        var max = interval[1];

        if(!!min && dateFns.isBefore(ref, min)) return false; // there is a interval start and ref is before
        if(!!max && dateFns.isAfter(ref, max)) return false; // there is an interval end and ref is after

        return true;

    }

    function permits(json) {

        var permits = _.get(json, "permits.items");
        var types = _.get(json, "media.types.items", _.get(json, "media.types"));
        //var users = _.get(json, "users.items");
        var vehicles = _.get(json, "vehicles.items");
        var tenants = _.get(json, "tenants.items");
        var spaces = _.get(json, "spaces.items");
        
        var tenantType = _.get(json, "units.type");

        // filter down to unique vehicles
        //permits = _.uniqBy(_.map(permits), "vehicle");
        
        permits = _.chain(permits).map(function(item) {
            return _.get(json, [ "items", item ], item);
        }).filter(function(item) {
            return !_.get(item, "issued.policy") && new Date(json.viewpoint) <= new Date(_.get(item, "valid.max.datetime", json.viewpoint));
        }).map(function(item) {

            item._type = "permit";
        
            api.Items.vehicle(item, vehicles);
            api.Items.space(item, spaces);

            return item;

        }).value();

        var list = section.querySelector("section[data-records='permits/valid'] ul[data-records='permits/valid']");
        if(!list) return;
            
        //var items = _.sortBy(permits, "vehicle.display");

        list.innerHTML = _.chain(permits).reduce(function(html, item) {
            //console.log(item);
            return html + permit(item);
        }, "");
        list.classList[list.hasChildNodes() ? "remove" : "add"]("empty");
        list.setAttribute("data-records-count", permits.length);

        var time = section.querySelector("time[data-valid]");
        time.setAttribute("datetime", json.generated);
        time.dispatchEvent(new CustomEvent("change", { bubbles:true, }));

    }

    function passcodes(json) {

        var account = _.get(json, "accounts.item");
        var items = _.get(json, [ "authentication", "items", account ]);

        var count = _.size(items) || 0;

        var list = section.querySelector("ul[data-records='auth/codes']");
        if(!list) return;
            
        //var items = _.sortBy(permits, "vehicle.display");

        list.innerHTML = _.reduce(items, function(html, code, id) {
            //console.log(item);
            return html + '<li><h1 class="auth id">' + code + '</hq></li>';
        }, "");
        list.classList[list.hasChildNodes() ? "remove" : "add"]("empty");
        list.setAttribute("data-records-count", count);

        // var time = section.querySelector("time[data-valid]");
        // time.setAttribute("datetime", json.generated);
        // time.dispatchEvent(new CustomEvent("change", { bubbles:true, }));

    }

    function violations(json) {

        var count = _.get(json, "violations.count", 0);

        var items = _.get(json, "violations.items");
        var types = _.get(json, "media.types.items", _.get(json, "media.types"));
        //var users = _.get(json, "users.items");
        var vehicles = _.get(json, "vehicles.items");
        var tenants = _.get(json, "tenants.items");
        var spaces = _.get(json, "spaces.items");
        
        var tenantType = _.get(json, "units.type");

        // filter down to unique vehicles
        //permits = _.uniqBy(_.map(permits), "vehicle");
        
        items = _.map(items, function(item) {
            
            item._type = "violation";
            
            api.Items.vehicle(item, vehicles);
            api.Items.tenant(item, tenants);
            api.Items.space(item, spaces);
            
            
            
            if(!!item.tenant) item.tenant.type = item.tenant.type || tenantType;
            
            if(!!item.media && item.media.type && !!types && _.isString(item.media.type)) item.media.type = types[item.media.type];

            //return item;
            
            return item; //api.Permits.normalize(item);
            
        });

        var list = section.querySelector("ul[data-records='violations']");
        if(!list) return;
            
        //var items = _.sortBy(permits, "vehicle.display");

        list.innerHTML = _.chain(items).orderBy(['issued.datetime'], ['desc']).reduce(function(html, item) {
            //console.log(item);
            return html + api.Templates[item._type](item);
        }, "");
        list.classList[list.hasChildNodes() ? "remove" : "add"]("empty");
        list.setAttribute("data-records-count", count);

        // var time = section.querySelector("time[data-valid]");
        // time.setAttribute("datetime", json.generated);
        // time.dispatchEvent(new CustomEvent("change", { bubbles:true, }));

    }

    function issuers(json) {

        var elem = section.querySelector("ul[data-records='issuers']");
        var issuers = _.get(json, "issuers.items");
        var usage = _.get(json, "usage.items");
        var limits = _.get(json, "limits.items");
        var permits = _.get(json, "permits.items");
        var vehicles = _.get(json, "vehicles.items");
        var tenants = _.get(json, "tenants.items");
        var spaces = _.get(json, "spaces.items");

        var items = _.map(issuers, function(item) {

            var issuer = item;

            if(!item.tenant || !item.tenant.request) return null;

            _.set(item, "usage.items", _.map(_.filter(usage, ["issuer", item.id]), function(item) {

                _.defaults(item, {
                    id: item.limit,
                });
                _.set(item, "limit", _.get(limits, _.get(item, "limit")) || _.get(item, "limit"));
                _.set(item, "issuer", _.get(issuers, _.get(item, "issuer")) || _.get(item, "issuer"));

                _.set(item, "current", {
                    datetime: json.viewpoint,
                    remaining: _.chain(item).get("available").findLast(function(item) {
                        return isInInterval(json.viewpoint, interval(item.interval));
                    }).value(),
                    consumed: _.chain(item).get("used").findLast(function(item, id) {
                        return isInInterval(json.viewpoint, interval(item.interval));
                    }).value(),
                });

                return item;
            }));


            _.set(item, "permits.valid.items", _.chain(permits).filter(function(item) {
                return _.get(item, "issued.policy") === _.get(issuer, "subject") && new Date(json.viewpoint) <= new Date(_.get(item, "valid.max.datetime", json.viewpoint)); //isInInterval(json.viewpoint, interval(_.get(item, "valid")));
            }).map(function(item) {

                item._type = "permit";
            
                api.Items.vehicle(item, vehicles);
                api.Items.space(item, spaces);

                return item;

            }).value());

            //console.log(item);

            item.rank = item.rank || 0;
            if(!item.rank && !!item.payments && !!item.payments.enabled) item.rank = (!!item.payments.always) ? 1 : 2;
            if(!item.rank && !!_.get(item, "permit.continuous", false)) item.rank = 3;

            return item;

        });
        elem.innerHTML = _.reduce(_.sortBy(_.filter(items), [ "rank", "name" ]), function(html, item) {
            if(!item) return html;
            return html + issuer(item);
        }, "");

        var time = section.querySelector("time[data-valid]");
        time.setAttribute("datetime", json.generated);
        time.dispatchEvent(new CustomEvent("change", { bubbles:true, }));

    }

    function error(err) {

    }

    var init = _.once(function(ctx) {

        var id = ctx.params.location;

        //console.log(section, form);

        var attendant = api.attendant.get(id)
        .then(function(attendant) {

            if(!!attendant && !!attendant.id) {

                var title = _.filter([ "Parking at " , _.get(attendant, "address.name") ]).join(" ");

                if(!!attendant.id && id !== attendant.id && !!window.history && !!history.replaceState) history.replaceState({} , title, ctx.pathname.replace("/" + id + "/", "/" + attendant.id + "/"));

            }

            

            document.documentElement.dispatchEvent(new CustomEvent("ready", { "bubbles": true }));

        });

        var tenants = Promise.resolve(api.base())
        .then(function(base) {

            return fetch(base + "v1/locations/" + id + "/tenants")
            .then(api.response.process)
            .then(function(json) {

                var account = _.get(json, "tenants");
                account.request = account.required = true;
                account.items = _.chain(json).get("tenants.items").map(function(item, key) {
                    return _.get(item, "display") || item;
                }).value().sort();

                form.querySelector("fieldset.id").innerHTML = '<input name="location" type="hidden" value="' + id + '" />';

                form.querySelector("fieldset.account").innerHTML = tenantField(account);
                form.querySelector("fieldset.auth").innerHTML = authField({
                    //email: !!_.get(json, "tenants.selfservice.auth") ? "/" + id + "/account/email" : false,
                });

                emailForm.querySelector("fieldset.id").innerHTML = '<input name="scope" type="hidden" value="' + id + '" />';
                emailForm.querySelector("fieldset.account").innerHTML = tenantField(account) + '<label class="email required"><span>Email on file</span><input type="email" name="email" required /></label>'

            });

        })

        return Promise.join(attendant, tenants);

    });

    function show(ctx) {

        //console.log("show", ctx, window.location);

        var id = ctx.params.account;
        var auth = _.get(ctx, "query.Authorization") || _.get(qs.parse(ctx.hash), "Authorization") || _.get(ctx, "params.Authorization");

        var jwt = null;
        if(!!auth) {
            try {
                jwt = jwt_decode(_.get(_.split(auth, " "), "[1]"));
                jwt.name = jwt.name || jwt.unique_name;
            } catch(error) {
                jwt = null;
            }
            if(!!jwt && dateFns.isPast(jwt.exp * 1000)) return page("/" + ctx.params.location + "/account"); //auth = null; // expired
        }
        
        
        
        //console.log(id, jwt);

        return Promise.join(init(ctx), setup(id))
        .then(function() {

            if(!id) return null;

            return view(get(id, ctx.params.location, auth));

        });
        // .catch(function(err) {
        //     alert(err);
        // });
    };

    function email(ctx) {
        
        
        //console.log(id, jwt);

        return Promise.join(init(ctx))
        .then(function() {

            root.setAttribute("data-record", "account/email");

        });
        // .catch(function(err) {
        //     alert(err);
        // });
    };

    _.each({
        "/:location/tenants/:account":show,
        "/:location/accounts/:account":show,
        "/:location/account":show,
    }, function(handler, path) {
        //console.log(path, handler);
        page(path, handler);
    });

    _.each({
        "/:location/account/email":email,
    }, function(handler, path) {
        //console.log(path, handler);
        page(path, handler);
    });

}(page, window.qs || window.Qs, ParkIQ.API, document.documentElement, document.querySelector("section[data-record='accounts/?']")));

export default window;