// import the stuff we need to run

import uiinit from "./parts/ui-init";
import index from "./parts/index";
import alias from "./parts/alias";
import tenant from "./parts/tenant";
import address from "./parts/address";
import location from "./parts/location";
import attendant from "./parts/attendant";
//import issuers from "./parts/issuers";
import violation from "./parts/violation";
import redirect from "./parts/redirect";
import permittable from "./parts/permittable";
import permits from "./parts/permits";
import permit from "./parts/permit";
import pass from "./parts/pass";
import send from "./parts/send";

import datetimeduration from "./datetimeduration";
import newpermit from "./newpermit";

import violations from "./parts/violations";
import media from "./parts/media";
import mediacheck from "./parts/mediacheck";
import account from "./parts/account";
import vehicle from "./parts/vehicle";
import preauth from "./parts/preauthorize";
import authorize from "./parts/authorize";
import rules from "./parts/rules";
import qr from "./parts/qr";

import style from "../styles/app.scss";

export default window;

import Nav from "./components/Nav.svelte";

var nav = new Nav({
  target: document.querySelector("section[data-records='services']"),
  props: {
    property: null,
    policies: null,
  },
});

postal.subscribe({
  topic: "issuers.items",
  callback: function (data, envelope) {
    nav.$set({
      policies: data.items,
    });
  },
});

postal.subscribe({
  topic: "location.item",
  callback: function (data, envelope) {
    nav.$set({
      property: data.item,
    });
  },
});

(function (page) {
  var reserved = [
    "q",
    "qr",
    "my",
    "passes",
    "permits",
    "attendant",
    "validate",
  ];

  function getLocationFromHost(host) {
    if (!host) return null;

    host = host.toLowerCase().replace("www.", "");
    var s = host.split(".");
    if (s.length < 3) return null;
    var id = s[0];
    if (_.includes(reserved, id)) return null;

    return id;
  }

  // bootstrap
  page("/", function (ctx, next) {
    var sent = ctx.query.sent || ctx.query.s;
    var location = ctx.query.location || ctx.query.l;
    var attendant = ctx.query.attendant || ctx.query.a;
    var permit = ctx.query.permit || ctx.query.p;
    var violation = ctx.query.violation || ctx.query.v;
    var warning = ctx.query.warning || ctx.query.w;
    var decal = ctx.query.media || ctx.query.decal || ctx.query.d;
    var map = ctx.query.map || ctx.query.address || ctx.query.m;

    if (!!location) return page("/l/" + location);
    if (!!attendant) return page("/l/" + attendant);
    if (!!sent) return page("/s/" + sent);
    if (!!permit)
      return (window.location.href =
        "https://passes.parkingattendant.com/validate/" + permit + "?qr");
    //if(!!violation) return page("/violations/" + permit);
    if (!!violation) return page("/violation/" + violation);
    if (!!warning) return page("/violation/" + warning);
    if (!!decal) return page("/d/" + decal);
    if (!!map) return page("/map/" + map);

    var id = getLocationFromHost(
      window.location.hostname || window.location.host
    );
    if (!!id) return page.redirect("/" + id);
    else return (window.location.href = "https://parkingattendant.app/");

    alert("Whoops, not found");
  });

  page("/:location", function (ctx) {
    return page.redirect("/" + _.get(ctx, "params.location") + "/services");
  });
})(page);

page.start();

// dropdown
(function (root) {
  function validate(e) {
    //console.log("validate", e);
    if (
      !_.invoke(
        e,
        "target.matches",
        "label.id.constrained input[name]:required"
      )
    )
      return;
    var input = e.target;
    if (!input) return;
    if (!input.value) return; // no value (this would be controlled through a different validation);

    // TODO: rewrite to use labels

    var label =
      (input.labels || [])[0] || input.closest("label") || input.parentNode;
    var datalist = input.list || label.querySelector("datalist");
    //console.log("datalist", input.list, datalist);
    if (!datalist) return; // nothing to validate against

    var options = datalist.options || datalist.getElementsByTagName("option");

    if (!options || options.length <= 0) return;

    label.classList.toggle(
      "invalid",
      !_.find(options, { value: input.value.toUpperCase() }) &&
        !_.find(options, { value: input.value })
    );
  }

  root.addEventListener("change", validate);
  //root.addEventListener("blur", validate);
  root.addEventListener("focusout", validate);

  root.addEventListener("input", function (e) {
    if (
      !_.invoke(
        e,
        "target.matches",
        "label.id.constrained.invalid input[name]:required"
      )
    )
      return;
    var input = e.target;

    var label = (input.labels || [])[0] || input.closest("label");

    //var label = input.closest("label.id.constrained");
    if (!!label) label.classList.remove("invalid");
  });

  root.addEventListener("change", function (e) {
    if (
      !_.invoke(
        e,
        "target.matches",
        "label.id.constrained ~ select:not([name]), label.id.constrained input[name] ~ select:not([name]), label.id + select:not([name])"
      )
    )
      return;

    var select = e.target;
    var el = select;

    while ((el = el.previousSibling)) {
      var input = null;

      if (el.matches("input[name]")) {
        input = el;
      } else if (el.matches("label")) {
        input = el.querySelector("input[name]");
      }

      if (!input) continue;

      input.value = select.value;
      input.dispatchEvent(new CustomEvent("input", { bubbles: true }));
      input.dispatchEvent(new CustomEvent("change", { bubbles: true }));

      if (!!input.value)
        select.dispatchEvent(
          new CustomEvent("track", {
            bubbles: true,
            detail: {
              category: "select",
              action: input.name,
              label: input.value,
            },
          })
        );

      break;
    }

    select.selectedIndex = 0;
  });
})(document.documentElement);
