<script>
  import WarningScanner from "./WarningScanner.svelte";
  import go from "../util/router";

  export let property = null;

  //   function result(result) {
  //     console.log(result);

  //     // parse the url
  //     var url = _.attempt(Reflect.construct, URL, [result]); // not a url

  //     console.log("url", url);

  //     if (!url || !scope) return;

  //     var w = url.searchParams.get("w") || _.get(url.pathname.split("/w/"), "1");
  //     if (!!w) return go("/violations/" + w + "?qr=warning&scanner=inline");
  //   }

  function onwarning(e) {
    e?.detail?.id &&
      go(
        "/" +
          (property?.id || property) +
          "/violations/" +
          e.detail.id +
          "?qr=" +
          e.detail.type +
          "&scanner=inline"
      );
  }

  function onsubmit(e) {
    e.preventDefault();

    var form = e.target;

    if (!!form.matches(":invalid")) {
      alert("Please complete all fields");
      return;
    }

    var id = _.get(
      form.querySelector("input[name='violation']"),
      "value",
      ""
    ).replace(/[^A-Z0-9]/gi, "");

    go("/" + (property?.id || property) + "/violations/" + id);
  }
</script>

<section data-records="violations/search">
  <header />
  <h1>Lookup Violation</h1>
  <WarningScanner on:warning={onwarning} />
  <p>Scan warning barcode or enter the number:</p>
  <form novalidate data-ref="violation" on:submit={onsubmit}>
    <input
      type="hidden"
      name="location"
      value={property?.id || property || ""}
    />
    <label data-type="warning" class="warning" />
    <input
      class="violation warning id"
      name="violation"
      placeholder="Violation number"
      type="text"
      pattern="[0-9 ]*"
      inputmode="numeric"
      autocapitalize="characters"
      autocorrect="off"
      autocomplete="off"
      spellcheck="false"
      maxlength="8"
      value=""
      required
    />
    <button type="submit">Continue</button>
  </form>
</section>
