(function(page, api) {
    
    page("/l/:location", function(ctx, next) {
        
        var qr = true;
        
        return Promise.resolve(api.base())
        .then(function(base) {
            return fetch(base + "v1/locations/" + ctx.params.location + "/attendant");
        }).then(function(res) {
            return res.json();
        }).then(function(json) {
            
            var attendant = _.get(json, [ "attendants", "items", _.get(json, "attendants.item")], _.get(json, "similar.attendant"));

            if(!!attendant) return window.location.href = attendant.url + ("?qr=" + (ctx.query.qr || ""));
        });
        
    });
    
    // page("/v/:permit", function(ctx, next) {
    //     var permit = ctx.params.permit;
	//     return window.location.href = "https://passes.parkingattendant.com/p/" + permit + "?qr";
    // });
    
    page("/d/:location/:media", function(ctx, next) {

        var location = ctx.params.location;
        var media = ctx.params.media;
        
        return window.location.href = "https://smartpermit.app/?l=" + location + "&m=" + media + "&qr";
        
    
    });
    
    page("/d/:media", function(ctx, next) {

        var media = ctx.params.media;
                
        return window.location.href = "https://smartpermit.app/?id=" + media + "&qr";
    
    });
        
    

    page("/s/:send", function(ctx, next) {
        
        return Promise.resolve(api.base())
        .then(function(base) {
            return fetch(base + "v1/sent/" + ctx.params.send);
        }).then(function(res) {
            return res.json();
        }).then(function(json) {
            if(!!json.url) return window.location.href = json.url;
        });
        
    });

}(page, ParkIQ.API));

(function(page, api) {
    
    
	
	var address = Promise.method(function(context) {
		// get the address
        
        var id = context.params.address;
        
        return Promise.resolve(api.base())
        .then(function(base) {
            return fetch(base + "v1/addresses/" + id);
        })
        // .then(function(url) {
        //     return fetch(url);
        // })
        .then(api.response.process).then(function(data) {

            var url = _.get(data, [ "addresses", "items", _.get(data, "addresses.item"), "map", "url" ]);
            if(!!url) return window.location.href = url;
			
			alert("Whoops, something went wrong!");
            
        }, function() {
            alert("Whoops, something went wrong!");
        });
		
	});
	
	
	

	page("/map/:address", address);
	page("/m/:address", address);
	page("/directions/:address", address);
    page("/:address/map", address);
    page("/:address/directions", address);
	
	
}(page, ParkIQ.API));

export default window;