(function (api, postal) {
  // var friendlyDuration = function(duration, noPlural) {
  // 	var hours = duration.asHours();
  // 	if(hours == 1) return "1 hour";
  // 	if(hours <= 72) return hours + " hour" + (!noPlural ? "s" : "");
  // 	var sb = [];
  // 	//console.log(hours);
  // 	var days = Math.floor(hours / 24);
  // 	hours = duration.hours();
  // 	sb.push(days);
  // 	sb.push(" day");
  // 	if(1 != days && (!noPlural || hours > 0)) sb.push("s");
  // 	if(hours > 0) {
  // 		sb.push(", ");
  // 		sb.push(hours);
  // 		sb.push(" hr");
  // 		if(1 != hours) sb.push("s");
  // 	}
  // 	return sb.join("");
  // };

  var comparer =
    !!window.Intl && !!window.Intl.Collator
      ? new Intl.Collator(undefined, {
          numeric: true,
          sensitivity: "base",
          caseFirst: "lower",
        }).compare
      : undefined;

  function spaceComparer(a, b) {
    if (a === b) return 0;
    if (a === "OPEN") return -1;
    if (b === "OPEN") return 1;
    return comparer(a, b);
  }

  function rules(elem, rules, summary) {
    //elem.classList.remove("custom");
    _.each(elem.querySelectorAll("p,ul"), function (item) {
      item.remove();
    });

    var html = _.reduce(
      _.filter([_.get(rules, "description", ""), _.get(rules, "custom", "")]),
      function (html, item) {
        return "<p>" + item + "</p>";
      },
      ""
    );

    if (!!html) {
      elem.insertAdjacentHTML(
        "beforeend",
        !!summary ? "<p>" + rules.summary + "</p>" : html
      );
    }

    return html;

    // if(!!rules && !!rules.description) {
    //     //elem.classList.add("custom");
    //     elem.insertAdjacentHTML("beforeend", '<p>' + (!!summary ? rules.summary : rules.description) + '</p>');
    //     return '<p>' + rules.description + '</p>';
    // }

    //return null;
  }

  var last = null;

  function state(state) {
    if (!state) return;

    //console.log("state=", state);

    var generated = state.generated;
    var location = _.get(state, [
      "locations",
      "items",
      _.get(state, "locations.item"),
    ]);

    var address = _.get(state, [
      "addresses",
      "items",
      _.get(location, "address"),
    ]);

    if (!!address)
      address = _.merge(address, _.pick(location, ["photo", "logo"]));

    if (!!location && !!location.id)
      postal.publish({
        topic: "location.item",
        data: {
          generated: generated,
          item: location,
        },
      });

    if (!!address && !!address.id)
      postal.publish({
        topic: "address.item",
        data: {
          generated: generated,
          item: address,
        },
      });

    var spaces = _.map(_.get(state, "spaces.items", {}), _.toUpper).sort(
      spaceComparer
    );
    var tenants = _.map(_.get(state, "tenants.items", {}), _.toUpper).sort(
      comparer
    );

    if (!!tenants)
      postal.publish({
        topic: "tenants.items",
        data: {
          generated: generated,
          predefined: !!_.get(state, "tenants.predefined"),
          type: _.get(state, "tenants.type"),
          //items: _.map(_.get(tenants || {}, "items", [])),
          items: tenants,
        },
      });

    //console.log("publish spaces?", spaces);

    // if(!!spaces) postal.publish({
    //     topic   : "spaces.items",
    //     data    : {
    //         generated: generated,
    //         predefined:!!_.get(state, "spaces.predefined"),
    //         //items: _.map(_.get(spaces || {}, "items", [])),
    //         items: spaces,
    //     }
    // });

    var payments = null;

    for (const [k1, v1] of Object.entries(state.spaces?.["for"] || {})) {
      for (const [k2, v2] of Object.entries(v1)) {
        v1[k2] = state.items[k2] || v2;
      }
    }

    var issuers = _.sortBy(
      _.map(_.get(state, "issuers.items"), function (item) {
        // ok, this is a policy
        item.pricing = _.get(state, ["pricing", "for", item.policy]);
        item.metered = _.get(state, ["metered", "for", item.policy]);
        item.validity = _.get(state, ["validity", "for", item.policy]);
        item.spaces = _.get(state, ["spaces", "for", item.policy]);

        if (!payments && !!item.payments) payments = item.payments;
        if (!payments && !!item.pricing) payments = item.pricing;

        // ok, this is a policy

        _.set(
          item,
          "location",
          _.get(state, ["items", item.location]) ||
            _.get(state, ["locations", "items", item.location]) ||
            item.location
        );

        // only set items if not already defined
        if (!_.get(item, "space.items")) _.set(item, "space.items", spaces);
        else
          item.space.items = _.map(item.space.items, _.toUpper).sort(
            spaceComparer
          );

        if (
          !_.get(item, "tenant.items") ||
          !!_.get(item, "tenant.list.all", false)
        )
          _.set(item, "tenant.items", tenants);
        else
          item.tenant.items = _.map(item.tenant.items, _.toUpper).sort(
            comparer
          );

        if (!_.get(item, "vehicle.items"))
          _.set(
            item,
            "vehicle.items",
            _.map(store.get("ParkingVehicles") || {})
          );
        item.vehicle.items = _.map(item.vehicle.items, _.toUpper).sort(
          comparer
        );

        item.rank = item.rank || 0;
        if (!item.rank && !!item.payments && !!item.payments.enabled)
          item.rank = !!item.payments.always ? 1 : 2;
        if (!item.rank && !!_.get(item, "permit.continuous", false))
          item.rank = 3;

        //console.log("policy=", item);

        return item;
      }).filter(function (item) {
        return (
          item.audience.public && (!item.amenity || item.amenity === "parking")
        );
      }),
      ["rank", "title"]
    );

    postal.publish({
      topic: "issuers.items",
      data: {
        generated: generated,
        count: _.get(state, "issuers.count"),
        items: issuers,
      },
    });

    if (!!payments)
      postal.publish({
        topic: "payments.item",
        data: {
          generated: generated,
          item: payments,
        },
      });
  }

  // function publish(attendant, generated, tenants, spaces) {

  //     if(!attendant) return;

  //     if(!!attendant.location && !!attendant.location.id) postal.publish({
  //         topic   : "location.item",
  //         data    : {
  //             generated: generated,
  //             item:attendant.location,
  //         }
  //     });

  //     if(!!attendant.address && !!attendant.address.id) postal.publish({
  //         topic   : "address.item",
  //         data    : {
  //             generated: generated,
  //             item:attendant.address,
  //         }
  //     });

  //     postal.publish({
  //         topic   : "attendant.item",
  //         data    : {
  //             generated: generated,
  //             item:attendant,
  //         }
  //     });

  //     if(!!tenants) postal.publish({
  //         topic   : "tenants.items",
  //         data    : {
  //             generated: generated,
  //             predefined:!!_.get(tenants, "predefined"),
  //             type:_.get(tenants, "type"),
  //             //items: _.map(_.get(tenants || {}, "items", [])),
  //             items: _.get(tenants || {}, "items", []),
  //         }
  //     });

  //     //console.log("publish spaces?", spaces);

  //     if(!!spaces) postal.publish({
  //         topic   : "spaces.items",
  //         data    : {
  //             generated: generated,
  //             predefined:!!_.get(spaces, "predefined"),
  //             //items: _.map(_.get(spaces || {}, "items", [])),
  //             items: _.get(spaces || {}, "items", []),
  //         }
  //     });
  // };

  api.attendant = {
    // set:function(data, id) {
    //     //if(!data || !data.attendants) return null;

    //     var attendant = _.get(data, [ "attendants", "items", _.get(data, "attendants.item") ]);

    //     _.set(attendant, "enabled", true);

    //     // we need to handle the no-attendant scenario
    //     if(!attendant) {

    //         attendant = {
    //             enabled:false,
    //             id: _.get(data, "attendants.item"),
    //             location: _.get(data, "locations.item"),
    //         };

    //         //console.log("attendant", attendant);

    //         // postal.publish({
    //         //     topic   : "attendant.item",
    //         //     data    : {
    //         //         generated: data.generated,
    //         //         item:attendant,
    //         //     }
    //         // });
    //         // return null;
    //     }

    //     attendant.generated = data.generated;
    //     attendant.location = _.get(data, [ "locations", "items", attendant.location ], attendant.location);
    //     attendant.address = _.get(data, [ "addresses", "items", attendant.address ], attendant.address) || _.get(data, [ "addresses", "items", _.get(attendant, "location.address") ], _.get(attendant, "location.address"));

    //     //console.log("attendant", attendant);

    //     //console.log(address);
    //     //console.log("set", attendant.tenant, _.get(data, "tenants.items"));
    //     _.set(attendant.space, "items", _.map(_.get(data, "spaces.items", {}), _.toUpper).sort(comparer));
    //     _.set(attendant.tenant, "items", _.map(_.get(data, "tenants.items", {}), _.toUpper).sort(comparer));
    //     //_.set(attendant.vehicle, "items", _.map([ "abc123" ], _.toUpper).sort(comparer));

    //     //api.cache.set(attendant.id, attendant, data.generated);
    //     //api.cache.set(attendant.id, attendant, data.generated);
    //     //if(!!id) api.cache.set(id, attendant, data.generated);
    //     if(!!id) api.cache.set(id, data, data.generated);

    //     //if(!!data.tenants) api.cache.set(attendant.id + "/tenants", data.tenants, data.generated);
    //     //if(!!data.spaces) api.cache.set(attendant.id + "/spaces", data.spaces, data.generated);

    //     //return attendant;

    //     return data;
    // },
    get: Promise.method(function (location) {
      var id = location;
      var cached = api.cache.get(location);
      if (!!cached) {
        //console.log("cached", cached);

        //var tenants = api.cache.get(cached.value.id + "/tenants");
        //var spaces = api.cache.get(cached.value.id + "/spaces");

        // always use latest
        state(cached.value);

        return cached.value;
      }

      var requested = new Date().toISOString();

      return (
        Promise.resolve(api.base())
          .then(function (base) {
            return fetch(
              base +
                "v1/locations/" +
                id +
                "/attendant?header=true&viewpoint=" +
                new Date().toISOString()
            );
          })
          // .then(function(url) {
          //     return fetch(url);
          // })
          .then(api.response.process)
          .then(function (data) {
            //var data = response.json();
            data.requested = requested;
            //console.log(data);

            if (!!data && !!data.similar && !!data.similar.attendant) {
              window.location.href = data.similar.attendant.url;
              return data.similar.attendant;
            }

            if (!!data && !!data.similar && !data.similar.attendant) {
              alert("Not found");
              return null;
            }

            //console.log(attendant, data);

            //_.set(attendant, "tenant.items", _.get(data, "tenants.items"));

            //var attendant = api.attendant.set(data, id);

            //var location = data.locations.items[data.locations.item];
            //var address = location.address = data.addresses.items[location.address] || location.address;

            //publish(attendant, data.generated, data.tenants, data.spaces);
            state(data);
            return data;
            //return attendant;
          })
      );
    }),
  };

  postal.subscribe({
    topic: "vehicles.items",
    callback: function (data, envelope) {
      if (!data.items) return;

      var saved = store.get("ParkingVehicles") || {};

      _.each(data.items, function (item) {
        var vehicle = item.display || item.key || item;
        saved[vehicle] = vehicle;
      });

      store.set("ParkingVehicles", saved);
    },
  });
})(ParkIQ.API, window.postal);

export default window;
