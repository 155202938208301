<script>
  import Send from "./Send.svelte";

  export let policy;
  export let email;
  export let notes;
  export let tel;
  export let name;

  let container;

  $: if (container)
    for (const input of container.querySelectorAll("input")) {
      input.dispatchEvent(new CustomEvent("change", { bubbles: true }));
    }
</script>

{#if policy}
  <fieldset class="contact" bind:this={container}>
    {#if policy.notes && policy.notes.request}
      <label class:required={!!policy.notes.required} class="notes"
        ><span>{policy.notes.label || ""}</span><input
          type="text"
          name="notes"
          required={!!policy.notes.required}
          value={notes || ""}
          spellcheck="false"
        /></label
      >
    {/if}
    {#if policy.name && policy.name.request}
      <label class:required={!!policy.name.required} class="name"
        ><span>Contact name</span><input
          type="text"
          name="name"
          required={!!policy.name.required}
          value={name || ""}
          spellcheck="false"
          autocomplete="name"
        /></label
      >
    {/if}
    {#if policy.email && policy.email.request}
      <label class:required={!!policy.email.required} class="email"
        ><span>Contact email</span><input
          type="email"
          name="email"
          required={!!policy.email.required}
          value={email || ""}
          spellcheck="false"
          autocomplete="email"
          multiple
        /></label
      >
    {/if}
    {#if policy.tel && policy.tel.request}
      <label class:required={!!policy.tel.required} class="phone tel"
        ><span>Contact phone</span><input
          type="tel"
          name="tel"
          required={!!policy.tel.required}
          value={tel || ""}
          spellcheck="false"
          autocomplete="tel"
        /></label
      >
    {/if}
    <Send {policy} />
  </fieldset>
{/if}
