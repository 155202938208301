<script>
  export let fees;
  export let cancelled = false;
  $: items = Object.values(fees?.items || fees || {});
  $: hasFees = items.length;
</script>

{#if hasFees}
  <ul class="fees">
    {#each items as fee}
      <li>
        <h1>{fee.description}</h1>
        <data value={fee.total.value}>{fee.total.display}</data>
        <ul>
          {#each Object.values(fee.payments.items || []) as payment}
            <li>
              {#if payment.captured}
                <data class="card" value={payment.card.type}
                  >{payment.card.last4}</data
                > <time datetime={payment.captured.datetime}>Paid</time>
              {:else if cancelled}
                <data class="card" value={payment.card.type}
                  >{payment.card.last4}</data
                > <time datetime="">Cancelled</time>
              {:else if payment.authorized && payment.capture && payment.capture.explicitly}
                <data class="card" value={payment.card.type}
                  >{payment.card.last4}</data
                > <time datetime={payment.authorized.datetime}>Pending</time>
              {:else if payment.authorized}
                <data class="card" value={payment.card.type}
                  >{payment.card.last4}</data
                > <time datetime={payment.authorized.datetime}>Paid</time>
              {:else}
                <data class="card" value={payment.card.type}
                  >{payment.card.last4}</data
                > <time datetime={payment.created}>Pending</time>
              {/if}
            </li>
          {/each}
        </ul>
      </li>
    {/each}
  </ul>
{/if}
