export const Stripe = new window.Stripe("pk_live_ERdgZ1FtE6lFvBhFnem3IT7Q");
export const StripePaymentRequest = Stripe.paymentRequest({
    country:"US",
    currency:"usd",
    total: {
        label:"Test",
        amount:0
    }
});

export const canUseWallet = StripePaymentRequest.canMakePayment();