import MediaLookup from "../components/MediaLookup.svelte";
var component = null;

(function (page, api, root, section) {
  function search(location, policy) {
    root.setAttribute("data-records", "media/smartdecals");
    root.removeAttribute("data-record");

    component = new MediaLookup({
      target: document.querySelector("body > main"),
      anchor: document.querySelector("body > main > footer"),
      props: {
        property: location,
        policy,
      },
    });
  }

  var init = _.once(function (ctx) {
    return api.attendant.get(ctx.params.location).then(function (attendant) {
      if (!!attendant && !!attendant.id) {
        var title = _.filter([
          "Parking at ",
          _.get(attendant, "address.name"),
        ]).join(" ");

        if (
          !!attendant.id &&
          id !== attendant.id &&
          !!window.history &&
          !!history.replaceState
        )
          history.replaceState(
            {},
            title,
            ctx.pathname.replace("/" + id + "/", "/" + attendant.id + "/")
          );
      }

      document.documentElement.dispatchEvent(
        new CustomEvent("ready", { bubbles: true })
      );
    });

    document.documentElement.dispatchEvent(
      new CustomEvent("ready", { bubbles: true })
    );
  });

  function activate(ctx, next) {
    init(ctx);

    return search(ctx.params.location, ctx.query.policy);
  }

  function exit(ctx, next) {
    component?.$destroy();
    next();
  }

  _.each(["/:location/permits/media/new", "/:location/media"], function (path) {
    page(path, activate);
    page.exit(path, exit);
  });
})(
  window.page,
  ParkIQ.API,
  document.documentElement,
  document.querySelector("[data-records='media/smartdecals']")
);

export default window;
