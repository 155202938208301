<script>
  import EntryItem from "./EntryItem.svelte";

  export let permit = null;

  $: entry = permit?.entry;

  $: console.log("entry=", entry);
</script>

{#if entry}
  <ul class="physicalaccess">
    {#each Object.values(entry.items) as item}
      <li>
        <EntryItem {item} {permit} />
      </li>
    {/each}
  </ul>
{/if}
