<script>
    export let permit;
</script>
{#if permit && permit.pass}
<aside class="pass">
    <h1>Pass Display Required</h1>
    <h2>Print and place on dash</h2>
    <a class="pass" href="{permit.pass.doc.pdf.url.replace("https://passes.parkingboss.com", "https://parking-passes.imgix.net")}" target="_blank" download>
        Print
    </a>
</aside>
{/if}