(function(root, page, api, section) {
 
    var tenantField = _.template('<% if(!!tenant && !!tenant.request) { %><label class="<%= tenant.type %> tenant id<%= tenant.required ? " required" : ""%><%= tenant.predefined ? " constrained" : ""%>" data-type="<%= tenant.type %>"><span><%= tenant.title %></span><input type="text" name="tenant" autocorrect="off" autocapitalize="characters" spellcheck="false" value="" maxlength="<%= tenant.predefined ? "30" : "8" %>" <%= tenant.required ? "required" : ""%> list="<%= tenant.predefined ? "authorize-tenants" : "" %>" /><% if(!!tenant.predefined) { %><datalist id="authorize-tenants"><% _.each(_.get(tenant, "items", []), function(item) { %><option><%= item.toUpperCase() %></option><% }); %></datalist><% } %></label><% if(!!tenant.predefined) { %><select tabindex="-1"><% _.each(_.get(tenant, "items", []), function(item) { %><option><%= item %></option><% }); %></select><% } %><% } %>', {
        variable:"tenant",
    });

    var authField = _.template('<label class="password id required"><span>Passcode</span><input type="text" pattern="[0-9]*" inputmode="numeric" autocorrect="off" autocomplete="off" spellcheck="false" name="password" required /></label>', {
        variable:"item",
    });

    var form = section.querySelector("form");
    
    form.addEventListener("submit", function(e) {
        e.preventDefault();

        return Promise.resolve(form)
        .then(function(form) {
        
            var input;

            if(!!(input = form.querySelector("input[name='password']:required:enabled")) && !input.value) {
                alert("Please enter passcode");
                return;
            }
            
            var disable = _.filter(form.querySelectorAll("button,input,textarea,select"), function(input) {
                input.blur();
                return !input.disabled;
            });
            
            if(disable.filter(function(input) {
                return !!input.required && !input.value;
            }).length > 0) return; // required with no value
        
            
            if (form.classList.contains("submitting")) return;
            
            form.classList.add("submitting");

            var formData = serialize(form); // do this sync

            var tenant = _.get(form, "elements.tenant.value") || "";
            var policy = _.get(form, "elements.policy.value") || "";

            disable.forEach(function(input) {	
                input.disabled = true;
            });


            var requested = new Date().toISOString();

            // lets resolve base just to be sure
            var min = Promise.delay(1 * 1000);
            return Promise.resolve(api.base())
            .then(function(base) {
                return base + "v1/accounts/auth/tokens?viewpoint=" + requested + "&" + formData;
            })
            .then(function(url) {
                return fetch(url, {
                    method: "POST",
                    //body: formData,
                });
            })
            .then(api.response.process)
            .then(function(data) {
                
                // OK
                // have data from response

                //_.set(result, "innerHTML", resultHtml(data));
                disable.forEach(function(input) {	
                    input.disabled = false;
                });
                form.classList.remove("submitting");
                form.reset();

                page(window.location.pathname + "?policy=" + policy + "&tenant=" + tenant + "#Authorization=" + _.get(data, "type") + " " + _.get(data, "token"));
                
            })
            .then(null, function(error) { // better catch errors inside
                //console.debug(error);
                var data = error.data;
                //var responseText = error.text;
                //console.log(data);
                var response = error.response;
                var status = error.status;
                //console.debug(response);
                //console.log(response.status);
                
                
                
                disable.forEach(function(input) {	
                    input.disabled = false;
                });
                form.classList.remove("submitting");
                
                // bad request
                if(status === 400) {
                    alert(error.message);
                    return null;
                } else if(status === 401) {
                    alert("Incorrect passcode");
                    return null;
                }
                
                
                alert(error.message || "Whoops, something went wrong");
                
            });
        });

    });

    var view = function(policy) {
        
        
        if(!form) return;
           
        form.reset();
        
        if(!policy) {
            
            return;
        }

        
        form.querySelector("fieldset.id").innerHTML = !!policy ? ('<input name="location" type="hidden" value="' + (policy.location.id || policy.location) + '" /><input name="policy" type="hidden" value="' + policy.suject + '" />') : "";

        form.querySelector("fieldset.tenant").innerHTML = tenantField(policy.tenant);
        form.querySelector("fieldset.auth").innerHTML = authField({
            //email: !!_.get(json, "tenants.selfservice.auth") ? "/" + id + "/account/email" : false,
        });
        
    }

    var _init = _.once(function(ctx) {
        
        // not sure what we do here...
        
    });
    
    var setup = function(ctx, next) {
        
        _init(ctx);
        next();
        
    }

    var show = function(ctx, next) {
        

        root.setAttribute("data-records", "tenant/auth");
        
        // reset

        var id = ctx.query.policy;

        var attendant = api.attendant.get(ctx.params.location)
        .then(function(attendant) {

            //console.log("attendant.id", attendant.id);
            //console.log("attendant", attendant);

            if(!id) id = _.get(attendant, "attendants.item");

            var policy = _.get(attendant, [ "issuers", "items", id ]) || _.find(attendant.issuers.items, { subject: id });

            if(!policy || !policy.id) {
                alert("whoops, something went wrong!");
                return;
            }

            if(!!policy && !!policy.id) {

                //var title = _.filter([ attendant.title , _.get(attendant, "address.name") ]).join(" - ");

            }

            view(policy);

            document.documentElement.dispatchEvent(new CustomEvent("ready", { "bubbles": true }));
        });
        
        
    };
    
    
    
    var exit = function(ctx, next) {
        view();
        next();
    }
    
    _.each([
        //"/l/:location", 
        "/:location/permits/temporary/auth", 
       // "/"
    ], function(path) {
        page(path, show);
        page.exit(path, exit);
    });

}(document.documentElement, window.page, ParkIQ.API, document.querySelector("section[data-records='tenant/auth']")));

export default window;