const section = document.querySelector("section[data-records='rules']");
import Rules from "../components/Rules.svelte";

var component = new Rules({
  target: section,
  props: {
    updated: null,
    property: null,
    policies: null,
    violations: null,
  },
});

(function (root, page, api, section) {
  var policy = "";

  var policies =
    '<% if(!!_.get(item, "violations.policies.issued.enabled") && _.get(item, "violations.policies.issued.count", 0) > 0) { %><h2>Violations</h2><ul><% _.chain(item).get("violations.policies.issued.items", []).map().orderBy([ "rank" ]).value().forEach(function(item) { %><li><p><%= _.get(item, "name") %>: <%= _.get(item, "description") %></p></li><% }); %></ul><% } %>';

  var documents =
    '<ul data-records="documents"><% _.chain(item).get("documents.items", []).map().value().forEach(function(document) { %><li><p><a href="<%= document.url %>" target="_blank" type="<%= document.type %>"><%= document.title || document.name %></a></p></li><% }); %></ul>';

  //var prices = '<ul data-records="prices"><% _.chain(item).get("payments.prices.items", []).filter(function(price) { if(!!price.minimum && !!price.maximum) return true; return true || !price.maximum; }).sortBy([ "total.value" ]).value().forEach(function(item) { %><li><p><%= _.get(item, "description") %></p></li><% }); %></ul>';

  var issuer =
    '<li class="policy"><h2><%= _.get(item, "title") %></h2><p><%= _.get(item, "rules.description", "") %></p><p><%= _.get(item, "metered.description", "") %></p><p><%= _.get(item, "rules.custom", "") %></p><p><%= _.get(item, "instructions", "") %></p>' +
    documents +
    '<p><%= _.get(item, "pricing.description", "") %></p></li>';

  var template = _.template(
    '<header><h1>Parking Rules</h1><time data-valid datetime="<%= _.get(item, "generated") %>"><%= dateFns.format(_.get(item, "generated"), "ddd MMM D h:mm A [(UTC]Z[)]") %></time></header><ul><% _.each(_.sortBy(_.filter(_.get(item, "issuers.items", []), function(item) { return item.audience.public && (!item.amenity || item.amenity === "parking"); }), [ "rank", "title" ]), function(item) { %>' +
      issuer +
      "<% }); %></ul>" +
      policies,
    {
      variable: "item",
      imports: {
        dateFns: dateFns,
      },
    }
  );

  function get(location) {
    return Promise.resolve(api.base()).then(function (base) {
      return Promise.all([
        api.attendant.get(location).then(function (json) {
          return {};
        }),
        api.fetch("GET", base + "v1/locations/" + location + "/attendant"),
        // .then(function(json) {
        //     return _.pick(json, [ "violations", "locations" ]);
        // })
        api
          .fetch("GET", base + "v1/locations/" + location + "/violations")
          .then(function (json) {
            return _.pick(json, ["violations", "locations"]);
          }),
      ]).then(function (values) {
        return _.merge.apply(_, values);
      });
      // .then(function(json) {
      //     console.log(json);
      //     return json;
      // });
    });
  }

  var view = function (json) {
    return Promise.resolve(json).then(function (json) {
      if (!json) return json;

      _.each(_.get(json, "issuers.items"), function (item) {
        // ok, this is a policy
        item.pricing = _.get(json, ["pricing", "for", item.policy]);
        item.metered = _.get(json, ["metered", "for", item.policy]);
        item.validity = _.get(json, ["validity", "for", item.policy]);

        item.rank = item.rank || 0;
        if (!item.rank && !!item.payments && !!item.payments.enabled)
          item.rank = !!item.payments.always ? 1 : 2;
        if (!item.rank && !!_.get(item, "permit.continuous", false))
          item.rank = 3;
          
      });

      //console.log(json);

      _.set(
        json,
        "location",
        _.get(json, "location") ||
          _.get(json, ["locations", "items", _.get(json, "locations.item")])
      );

      _.set(document, "title", _.get(json, "location.name") || document.title);

      component.$set({
        updated: json.generated,
        property: json.location,
        policies: json.issuers,
        violations: json.violations,
      });

      //_.set(section, "innerHTML", template(json));

      return json;
    });
  };

  var _init = _.once(function (ctx) {
    // not sure what we do here...
  });

  var setup = function (ctx, next) {
    _init(ctx);
    next();
  };

  var show = function (ctx, next) {
    root.setAttribute("data-records", "rules");

    // reset
    var id = ctx.params.location;

    return view(get(id));
    //.then(view);

    document.documentElement.dispatchEvent(
      new CustomEvent("ready", { bubbles: true })
    );
  };

  var exit = function (ctx, next) {
    view();
    next();
  };

  _.each(["/:location/rules"], function (path) {
    page(path, show);
    page.exit(path, exit);
  });
})(document.documentElement, window.page, ParkIQ.API, section);
export default window;
