import Violation from "../components/Violation.svelte";
import ViolationLookup from "../components/ViolationLookup.svelte";

var root = document.documentElement;

var container = root.querySelector("section[data-record^='violations/']");

const component = new Violation({
  target: container,
  props: {
    violation: null,
  },
});

var lookup = null;

(function (page, api) {
  function view(violation) {
    //var container = document.querySelector("section.violation");
    //var container = document.querySelector("section[data-record^='violations/']");

    component.$set({
      violation,
    });

    if (!violation) {
      root.removeAttribute("data-record");
      // while (container.firstChild) container.firstChild.remove();
      // _.each(
      //   document.querySelectorAll("form.send input[name='container']"),
      //   function (input) {
      //     if (!!input) input.setAttribute("value", "");
      //   }
      // );
      return violation;
    }

    //container.innerHTML =
    // header({ violation: violation }) +
    // template({ violation: violation }) +
    // footer({ violation: violation });

    //document.title = _.filter([ "Parking Violation" , _.get(violation, "address.name") ]).join(" - ");

    // _.each(
    //   document.querySelectorAll("form.send input[name='container']"),
    //   function (input) {
    //     if (!!input) input.setAttribute("value", violation.id);
    //   }
    // );

    root.setAttribute("data-record", "violations/" + violation.id);
    container.setAttribute("data-record", "violations/" + violation.id);

    document.body.scrollIntoView();

    return violation;
  }

  function violation(violation) {
    //root.removeAttribute("data-records");

    root.classList.add("ready");

    return Promise.resolve(violation)
      .then(function (result) {
        root.removeAttribute("data-records");
        root.setAttribute("data-record", "violations/show");
        return result;
      })
      .then(view)
      .then(function () {
        return violation;
      });
  }

  api.Violations = {
    get: Promise.method(function (violation, vehicle, location) {
      var requested = new Date().toISOString();

      return Promise.resolve(api.base())
        .then(function (base) {
          return (
            base +
            "v1" +
            "/violations/" +
            (!!vehicle ? vehicle + "/" + violation : violation) +
            "?viewpoint=" +
            requested +
            (!!location ? "&location=" + location : "")
          );
        })
        .then(function (url) {
          return fetch(url);
        })
        .then(api.response.process)
        .then(function (data) {
          //var data = response.json();
          data.requested = requested;
          //console.log(data);

          var violation = _.get(data, [
            "violations",
            "items",
            _.get(data, "violations.item"),
          ]);

          if (!violation)
            return Promise.reject(new Error("No violation found"));

          violation.generated = data.generated;
          if (!!violation.vehicle)
            violation.vehicle = (data.vehicles.items || data.vehicles)[
              violation.vehicle
            ];
          if (!!violation.tenant)
            violation.tenant = (data.tenants.items || data.tenants)[
              violation.tenant
            ];
          // if (!!violation.tenant)
          //   violation.tenant.type = _.get(data, "tenants.type");
          // if (!violation.notes) {
          //   var c = (data.notes.containers || data.notes)[violation.id];
          //   if (!!c) violation.notes = _.map(c.items || c);
          // }
          if (!violation.files) {
            var c = (data.files.items || data.files)[violation.id];
            if (!!c) violation.files = _.map(c.items || c);
          }

          violation.payment = data.payment;

          _.set(
            violation,
            "fees.items",
            _.chain(data)
              .get("fees.items", [])
              .filter(["subject", violation.id])
              .map(function (item) {
                // _.set(
                //   item,
                //   "policy",
                //   _.get(data, [
                //     "violations",
                //     "policies",
                //     "items",
                //     _.get(item, "authorized.by"),
                //   ])
                // );
                return item;
              })
              .orderBy(["created", "desc"])
              .value()
          );
          _.set(violation, "fee.item", _.get(violation, "fees.items[0]"));

          _.set(
            violation,
            "exceptions.items",
            _.map(
              _.get(data, "violations.exceptions.items", []),
              function (item) {
                // _.set(
                //   item,
                //   "threshold",
                //   _.get(
                //     data,
                //     ["violations", "thresholds", "items", item.threshold],
                //     item.threshold
                //   )
                // );
                return item;
              }
            )
          );

          var address = (violation.address =
            data.addresses.items[violation.address]);
          var location =
            (violation.property =
            violation.location =
              data.locations.items[violation.location]);

          if (!!address)
            address = _.merge(address, _.pick(location, ["photo", "logo"]));

          // var attendant = _.get(data, [ "attendants", "items", _.get(data, "attendants.item") ]);

          // if(!!attendant) attendant.location = data.locations.items[attendant.location] || attendant.location;

          postal.publish({
            topic: "violation.item",
            data: {
              requested: data.requested,
              generated: data.generated,
              item: violation,
            },
          });

          // run the things
          postal.publish({
            topic: "location.item",
            data: {
              requested: data.requested,
              generated: data.generated,
              item: location,
            },
          });

          postal.publish({
            topic: "address.item",
            data: {
              requested: data.requested,
              generated: data.generated,
              item: address,
            },
          });

          // run the things
          // postal.publish({
          //     topic: "attendant.item",
          //     data: {
          //         requested: data.requested,
          //         generated: data.generated,
          //         item: attendant,
          //     },
          // });

          return violation;
        });
    }),
  };

  var _init = _.once(function (ctx) {
    var id = ctx.params.location;

    var attendant = api.attendant.get(id).then(function (attendant) {
      if (!!attendant && !!attendant.id) {
        var title = _.filter([
          "Parking at ",
          _.get(attendant, "address.name"),
        ]).join(" ");

        if (
          !!attendant.id &&
          id !== attendant.id &&
          !!window.history &&
          !!history.replaceState
        )
          history.replaceState(
            {},
            title,
            ctx.pathname.replace("/" + id + "/", "/" + attendant.id + "/")
          );
      }

      document.documentElement.dispatchEvent(
        new CustomEvent("ready", { bubbles: true })
      );
    });
  });

  var show = function (ctx, next) {
    // show a specific violation
    root.removeAttribute("data-records");
    root.setAttribute("data-record", "violation/show");

    // reset

    view();

    return api.Violations.get(
      ctx.params.violation,
      ctx.params.vehicle,
      ctx.params.location
    )
      .then(function (violation) {
        if (!violation) return null;

        view(violation);
        document.documentElement.classList.add("ready");
      })
      .catch(function (error) {
        switch (error.status) {
          case 404:
            alert("Can't find this violation");
            break;
          default:
            alert("Whoops, something went wrong!");
            break;
        }
      });
  };

  var exit = function (ctx, next) {
    view();
    lookup?.$destroy();
    if (!!next) next();
  };

  _.each(
    [
      "/:location/violations/:violation",
      "/violations/:vehicle/:violation",
      "/violation/:vehicle/:violation",
      "/violations/:violation",
      "/violation/:violation",
    ],
    function (path) {
      page(path, show);
      page.exit(path, exit);
    }
  );

  var search = function (ctx, next) {
    _init(ctx);

    lookup = new ViolationLookup({
      target: document.querySelector("body > main"),
      anchor: document.querySelector("body > main > footer"),
      props: {
        property: ctx.params.location,
      },
    });

    // show a specific violation
    root.removeAttribute("data-record");
    root.setAttribute("data-records", "violations/search");

    document.documentElement.classList.add("ready");
  };

  _.each(["/:location/violations"], function (path) {
    page(path, search);
    page.exit(path, exit);
  });
})(window.page, ParkIQ.API);

export default window;
