(function(root, page, qs, api, section) {

    var title = null;
 
    var tenantField = _.template('<% if(!!tenant && !!tenant.request) { %><label class="<%= tenant.type %> tenant id<%= tenant.required ? " required" : ""%><%= tenant.predefined ? " constrained" : ""%>" data-type="<%= tenant.type %>"><span><%= tenant.title %></span><input type="text" name="tenant" autocorrect="off" autocapitalize="characters" spellcheck="false" value="" maxlength="<%= tenant.predefined ? "30" : "8" %>" <%= tenant.required ? "required" : ""%> list="<%= tenant.predefined ? "preauthorize-tenants" : "" %>" /><% if(!!tenant.predefined) { %><datalist id="preauthorize-tenants"><% _.each(_.get(tenant, "items", []), function(item) { %><option><%= item.toUpperCase() %></option><% }); %></datalist><% } %></label><% if(!!tenant.predefined) { %><select tabindex="-1"><% _.each(_.get(tenant, "items", []), function(item) { %><option><%= item %></option><% }); %></select><% } %><% } %>', {
        variable:"tenant",
    });

    var authField = _.template('<label class="password id required"><span>Passcode</span><input type="text" pattern="[0-9]*" inputmode="numeric" autocorrect="off" autocomplete="off" spellcheck="false" name="password" required /></label>', {
        variable:"item",
    });

    var form = section.querySelector("form");

    var resultHtml = _.template('<% if(!!item) { %><h1><%= _.get(item, "subject") %></h1><figure class="preauth <%= !!_.get(item, "expired") ? "expired" : "valid" %>"><figcaption><%= !!_.get(item, "expired") ? "Preauthorization expired" : "Preauthorized" %> <time datetime="<%= _.get(item, "expires") %>">until <%= dateFns.format(item.expires, "ddd MMM D YYYY h:mm A") %></time></figcaption><dl><dt><%= _.get(item, "title") %></dt><dd><data data-record="tenant" data-type="<%= _.get(item, "type") %>" value="<%= _.get(item, "name") %>"><%=  _.get(item, "name") %></data></dd><dt>Passcode</dt><dd><data data-record="auth" value="<%= _.get(item, "token") %>">••••</data></dd></dl></figure><p><%= _.get(item, "subject") %> passcode is pre-entered and encrypted</p><% if(!item.expired) { %><a href="<%= _.get(item, "action.href") %>" type="text/html">Continue to <%= _.get(item, "action.title") %></a><figure class="share"><figcaption>Share private link</figcaption><input type="text" readonly value="<%= _.get(item, "href") %>"><button type="button"><%= share ? "Share" : "Copy" %></button></figure><% } %><time datetime="<%= _.get(item, "expires") %>"><%= !!item.expired ? "Expired" : "Expires" %> <%= dateFns.format(item.expires, "ddd MMM D YYYY h:mm A") %></time><% } %>', {
        variable:"item",
        imports: {
            dateFns:dateFns,
            share:!!navigator.share,
        },
    });

    var resultElem = section.querySelector("article");

    if(!navigator.share && ClipboardJS.isSupported()) {

        // no native share

        resultElem.addEventListener("focusin", function(e) {
            if(!_.invoke(e, "target.matches", "input[readonly]")) return;
            e.target.select();
        });
    
        resultElem.addEventListener("click", function(e) {
            if(!_.invoke(e, "target.matches", "input[readonly]")) return;
            e.target.focus();
            e.target.select();
        });

        var clipboard = new ClipboardJS("figure.share button", {
            // target: function(trigger) {
            //     return trigger.previousElementSibling;
            // },
            text: function() {
                return window.location.href;
            }
        });

        clipboard.on("success", function(e) {
            //console.info('Action:', e.action);
            //console.info('Text:', e.text);
            //console.info('Trigger:', e.trigger);

            e.trigger.textContent = "Copied!";
            window.setTimeout(function() {
                e.trigger.textContent = "Copy";
            }, 3000);
        });

        clipboard.on("error", function(e) {

            window.prompt("Copy link:", window.location.href);

            //console.error('Action:', e.action);
            //console.error('Trigger:', e.trigger);

        });

    } else if (!!navigator.share) {

        // native share

        resultElem.addEventListener("click", function(e) {
            if(!e.target.matches("figure.share *")) return; // only figure click

            e.preventDefault();

            navigator.share({
                title:"Preauthorized",
                url:window.location.href,
                //text:title + ": " + window.location.href + " - follow link for more info",
            }).then(function() {
                // gtg
            })
            .catch(function() {
                // error sharing
            });
        });
    } else {
        // no clipboard, no share
        resultElem.addEventListener("click", function(e) {
            if(!e.target.matches("figure.share *")) return; // only figure click

            e.preventDefault();

            window.prompt("Copy link:", window.location.href);

        });
    }

    form.addEventListener("submit", function(e) {
        e.preventDefault();

        return Promise.resolve(form)
        .then(function(form) {
        
            var input;

            if(!!(input = form.querySelector("input[name='password']:required:enabled")) && !input.value) {
                alert("Please enter passcode");
                return;
            }
            
            var disable = _.filter(form.querySelectorAll("button,input,textarea,select"), function(input) {
                input.blur();
                return !input.disabled;
            });
            
            if(disable.filter(function(input) {
                return !!input.required && !input.value;
            }).length > 0) return; // required with no value
        
            
            if (form.classList.contains("submitting")) return;
            
            form.classList.add("submitting");

            var formData = serialize(form); // do this sync

            var tenant = _.get(form, "elements.tenant.value") || "";
            var policy = _.get(form, "elements.policy.value") || "";

            disable.forEach(function(input) {	
                input.disabled = true;
            });


            var requested = new Date().toISOString();

            // lets resolve base just to be sure
            var min = Promise.delay(1 * 1000);
            return Promise.resolve(api.base())
            .then(function(base) {
                return base + "v1/accounts/auth/tokens?viewpoint=" + requested + "&" + formData;
            })
            .then(function(url) {
                return fetch(url, {
                    method: "POST",
                    //body: formData,
                });
            })
            .then(api.response.process)
            .then(function(data) {
                
                // OK
                // have data from response

                //_.set(result, "innerHTML", resultHtml(data));
                disable.forEach(function(input) {	
                    input.disabled = false;
                });
                form.classList.remove("submitting");
                form.reset();


                //page(window.location.pathname + "?policy=" + policy + "&tenant=" + encodeURIComponent(tenant) + "&token=" + _.get(data, "token").replace(/[.]/g, "*"));
                page(window.location.pathname + "?policy=" + policy + "&tenant=" + encodeURIComponent(tenant) + "&token=" + _.get(data, "id") + "#Authorization=" + _.get(data, "type") + " " + _.get(data, "token"));//.replace(/[.]/g, "*"));
                
            })
            .then(null, function(error) { // better catch errors inside
                //console.debug(error);
                var data = error.data;
                //var responseText = error.text;
                //console.log(data);
                var response = error.response;
                var status = error.status;
                //console.debug(response);
                //console.log(response.status);
                
                
                
                disable.forEach(function(input) {	
                    input.disabled = false;
                });
                form.classList.remove("submitting");
                
                // bad request
                if(status === 400) {
                    alert(error.message);
                    return null;
                } else if(status === 401) {
                    alert("Incorrect passcode");
                    return null;
                }
                
                
                alert(error.message || "Whoops, something went wrong");
                
            });
        });

    });

    var view = function(policy, result) {
        
        _.set(resultElem, "innerHTML", resultHtml(result));
        
        if(!form) return;
           
        form.reset();
    
        if(!policy) {

            form.querySelector("h1").innerHTML = "";
            title = null;
            
            form.querySelector("fieldset.id").innerHTML = "";

            form.querySelector("fieldset.tenant").innerHTML = "";
            form.querySelector("fieldset.auth").innerHTML = "";
            
            return;
        }

        form.querySelector("h1").innerHTML = _.filter([ "Preauthorize", policy.title ]).join(" ");
        title = _.filter([ "Preauthorized", policy.title ]).join(" ");
        
        form.querySelector("fieldset.id").innerHTML = !!policy ? ('<input name="location" type="hidden" value="' + (policy.location.id || policy.location) + '" /><input name="policy" type="hidden" value="' + policy.subject + '" />') : "";

        form.querySelector("fieldset.tenant").innerHTML = tenantField(policy.tenant);
        form.querySelector("fieldset.auth").innerHTML = authField(policy);

        _.set(resultElem, "innerHTML", resultHtml(result));
        
    }

    var _init = _.once(function(ctx) {
        
        // not sure what we do here...
        
    });
    
    var setup = function(ctx, next) {
        
        _init(ctx);
        next();
        
    }

    // parse a jwt, bearer jwt, or id
    function auth(input) {
        return Promise.resolve(input)
        .then(function(input) {

            //console.log("input=", input);

            if(!input) return {};

            // normal jwt, with or without "bearer "
            if(typeof input === "string" && input.indexOf(".") > 0) return {
                "token": _.invoke(_.invoke(input, "split", " "), "pop"),
                "type": "bearer",
            };

            if(typeof input === "string" && input.length <= 36) return Promise.resolve(api.base())
            .then(function(base) {
                return base + "v1/auth/tokens/" + input + "?viewpoint=" + (new Date().toISOString());
            })
            .then(function(url) {
                return fetch(url);
            })
            .then(api.response.process)
            .then(function(data) {
                return data;
            });

            // normal jwt, with or without "bearer "
            return {
                "token": _.invoke(_.invoke(input, "split", " "), "pop"),
                "type": "bearer",
            };

        })
        .then(function(data) {
            var jwt = null;
            try {
                jwt = jwt_decode(data.token);
                jwt.name = jwt.name || jwt.unique_name;
            } catch(e) {
                jwt = null;
            }
            data.jwt = jwt;
            return data;
        })
        .then(function(data) {
            if(!data || !data.type) return data;
            data.header = data.type + " " + data.token;
            return data;
        });
    }

    var show = function(ctx, next) {
        
        view(); // init

        
        
        // reset

        var id = ctx.query.policy;

        Promise.join(auth(
            _.get(ctx, "query.Authorization")
            || _.get(qs.parse(ctx.hash), "Authorization")
            || _.get(ctx, "params.Authorization")
            || _.get(ctx, "query.token")
        ), api.attendant.get(ctx.params.location), function(auth, attendant) {

            root.setAttribute("data-records", "tenant/authorize/links");

            //console.log(window.location.href);

            if(!!_.get(ctx, "query.token") && !!window.history && !!window.history.replaceState) window.history.replaceState({} , document.title, window.location.pathname + window.location.search);

            if(!id) id = _.get(attendant, "attendants.item");

            var policy = _.get(attendant, [ "issuers", "items", id ]) || _.find(attendant.issuers.items, { subject: id });

            if(!policy || !policy.id) {
                alert("whoops, something went wrong!");
                return;
            }

            if(!!policy && !!policy.id) {

                //var title = _.filter([ policy.title , _.get(policy, "address.name") ]).join(" - ");

            }

            // check
            var result = null;

            var jwt = auth.jwt;

            if(!!jwt) {
                result = {
                    required: !!_.get(policy, "authentication"),
                    id:jwt.sub,
                    title:_.get(policy, "tenant.title"),
                    type:_.get(policy, "tenant.type"),
                    name:jwt.name || jwt.unique_name,
                    expires:dateFns.format(jwt.exp * 1000),
                    expired:dateFns.isPast(jwt.exp * 1000),
                    valid:dateFns.format((jwt.nbf || jwt.iat) * 1000) + "/" + dateFns.format(jwt.exp * 1000),
                    token: auth.header,
                    jwt:jwt,
                    subject: _.get(policy, "title", ""),
                    action: {
                        //href = "/" + (_.get(policy, "location.id") || policy.location) + "/permits/temporary/new" + window.location.search + window.location.hash,
                        "href":"/" + (_.get(policy, "location.id") || policy.location) + "/permits/temporary/new" + window.location.search + "#Authorization=" + auth.header,
                        //href:"/" + (_.get(policy, "location.id") || policy.location) + "/permits/temporary/new?policy=" + policy.subject + "&tenant=" + encodeURIComponent(ctx.query.tenant) + "#Authorization=" + auth,
                        title: _.get(policy, "issue.title") || "Register " + _.get(policy, "title", ""),
                    },
                    href:window.location.href,
                };
                //console.log("result", result);
            }

            view(policy, result);

            document.documentElement.dispatchEvent(new CustomEvent("ready", { "bubbles": true }));

        });

        
    };
    
    
    
    var exit = function(ctx, next) {
        view();
        next();
    }
    
    _.each([
        //"/l/:location", 
        //"/:location/permits/new/link", 
        "/:location/permits/temporary/link", 
       // "/"
    ], function(path) {
        page(path, show);
        page.exit(path, exit);
    });

}(document.documentElement, window.page, window.qs || window.Qs, ParkIQ.API, document.querySelector("section[data-records='tenant/authorize/links']")));

export default window;