<script>
  import Camera from "./Camera.svelte";

  import { createEventDispatcher, onMount } from "svelte";
  import { infotag } from "@parkingboss/utils";

  export let selected = null;
  export let property = null; // property gates observation flag?

  const dispatch = createEventDispatcher();

  let preview;
  let cam;

  $: cam?.scrollIntoView();

  onMount(() => {
    return clearPreview;
  });

  function clearPreview() {
    setPreview(null);
  }

  function setPreview(fileOrBlob) {
    if (preview) {
      URL.revokeObjectURL(preview);
    }
    preview = fileOrBlob && URL.createObjectURL(fileOrBlob);
    //console.log("preview=", preview);
  }

  function select(media) {
    clearPreview();

    selected = media;
    if (selected) dispatch("media", selected);
  }

  async function delayClearPreview(sec) {
    await new Promise((r) => setTimeout(r, sec * 1000));
    clearPreview();
  }

  let last;

  async function onbarcode(e) {
    // If we're already processing, don't process more than one at a time.
    if (preview) return;

    const { barcode, blob } = e.detail;

    //console.log("onbarcode=", barcode, blob);

    const photo = blob;

    if (!photo || !barcode || barcode == last) return clearPreview();

    setPreview(photo);

    if (!barcode) {
      return await delayClearPreview(1);
    }

    last = barcode;

    const value = infotag.parse(barcode);
    if (!value || "media" != value.format) {
      return await delayClearPreview(1);
    }

    const media = {
      type: value.format,
      id: value.id,
      key: value.serial,
    };
    return select(media);
  }
</script>

<nav class="scanner">
  <Camera
    bind:this={cam}
    class="smart-decal"
    barcode={true}
    cover={preview}
    on:barcode={onbarcode}
  />
</nav>
