import Permittable from "../components/Permittable.svelte";

var container = document.querySelector("section[data-record^='permittables/']");

const component = new Permittable({
  target: container,
  props: {
    details: null,
  },
});

(function (page, api) {
  var root = document.documentElement;

  var cal = {
    sameDay: "ddd MMM D h:mm A",
    nextDay: "ddd MMM D h:mm A",
    nextWeek: "ddd MMM D h:mm A",
    lastDay: "ddd MMM D h:mm A",
    lastWeek: "ddd MMM D h:mm A",
    sameElse: "MMM D YYYY h:mm A",
  };

  var generated = {
    sameDay: "ddd MMM D h:mm A [(UTC]Z[)]",
    nextDay: "ddd MMM D h:mm A [(UTC]Z[)]",
    nextWeek: "ddd MMM D h:mm A [(UTC]Z[)]",
    lastDay: "ddd MMM D h:mm A [(UTC]Z[)]",
    lastWeek: "ddd MMM D h:mm A [(UTC]Z[)]",
    sameElse: "MMM D YYYY h:mm A [(UTC]Z[)]",
  };

  var _vehicle = _.template(
    '<dl><% if(!!permittable.vehicle) { %><dt class="vehicle"></dt><dd class="vehicle"><data class="vehicle id" value="<%= permittable.vehicle.id %>"><%= permittable.vehicle.display %></data></dd><% } if(!!permittable.tenant) { %><dt class="tenant <%= permittable.tenant.type %>"></dt><dd class="tenant <%= permittable.tenant.type %>"><data class="tenant id <%= permittable.tenant.type %>" value="<%= permittable.tenant.id %>"><%= permittable.tenant.display %></data></dd><% } %><dt class="issued"></dt><dd class="issued"><time class="issued" datetime="<%= permittable.issued.utc %>"><%= moment(permittable.issued.utc).format("ddd, MMM D, YYYY h:mm A") %></time></dd><dt class="permittable serial"></dt><dd class="permittable serial"><%= permittable.serial %></dd><dt class="permittable number"></dt><dd class="permittable number"><%= permittable.number %></dd></dl><aside><p>Please contact <%= _.get(permittable, "location.contact.name", "management office") %> for more details or if you believe there is an error.</p></aside>',
    {
      imports: {
        moment: moment,
      },
    }
  );

  var view = function (permittable) {
    //var container = document.querySelector("section.permittable");
    var container = document.querySelector(
      "section[data-record^='permittables/']"
    );

    component.$set({
      permittable,
    });

    if (!permittable) {
      //while(container.firstChild) container.firstChild.remove();
      // _.each(document.querySelectorAll("form.send input[name='container']"), function(input) {
      //     if(!!input) input.setAttribute("value", "");
      // });
      container.removeAttribute("data-permittable");
      return;
    }

    //container.innerHTML = '<header><h1>' + (!permittable.permittable ? "Banned" : "OK To Register") + ' From <br>' + (_.get(permittable, "attendant.title") || _.get(permittable, "location.name")) + '</h1></header>' + _vehicle({ permittable: permittable, }) + '<time data-valid datetime="' + permittable.generated + '">' + moment(permittable.generated).calendar(null, generated) + '</time>';

    //document.title = _.filter([ !permittable.permittable ? "Banned" : "OK To Register", _.get(permittable, "address.name") ]).join(" - ");

    container.setAttribute(
      "data-permittable",
      !!permittable.permittable ? "true" : "false"
    );

    root.setAttribute("data-record", "permittables/" + permittable.id);
    container.setAttribute("data-record", "permittables/" + permittable.id);

    // _.each(document.querySelectorAll("form.send input[name='container']"), function(input) {
    //     if(!!input) input.setAttribute("value", permittable.id);
    // });

    document.body.scrollIntoView();
  };

  api.permittable = {
    get: Promise.method(function (id) {
      var cached = api.cache.get(id);
      //console.log("getting cached");
      //console.log(cached);
      if (!!cached) {
        return cached.value;
      }

      var requested = new Date().toISOString();
      return (
        Promise.resolve(api.base())
          .then(function (base) {
            return fetch(
              base + "v1/permittables/" + id + "?viewpoint=" + requested
            );
          })
          // .then(function(url) {
          //     return fetch(url);
          // })
          .then(api.response.process)
          .then(function (data) {
            //var data = response.json();
            data.requested = requested;
            //console.log(data);

            var permittable = api.permittable.set(data);

            if (!permittable) return Promise.reject(new Error("No data"));

            var location = data.locations.items[data.locations.item];
            location.address =
              data.addresses.items[location.address] || location.address;
            var address =
              (!!permittable ? permittable.address : null) || location.address;
            // var attendant = (!!permittable ? permittable.attendant : null) || _.get(data, [ "attendants", "items", _.get(data, "attendants.item") ]);
            // attendant.location = data.locations.items[attendant.location] || attendant.location;

            //console.log(address);
            //var attendant = data.attendants.items[location.attendant];
            if (!!address)
              address = _.merge(address, _.pick(location, ["photo", "logo"]));

            //if(!!permit.vehicle) permit.vehicle = data.vehicles.items[permit.vehicle] || permit.vehicle;

            postal.publish({
              topic: "location.item",
              data: {
                generated: data.generated,
                item: location,
              },
            });

            postal.publish({
              topic: "address.item",
              data: {
                generated: data.generated,
                item: address,
              },
            });
            // postal.publish({
            //     topic   : "attendant.item",
            //     data    : {
            //         generated: data.generated,
            //         item:attendant,
            //     }
            // });

            return permittable;
          })
      );
    }),
    set: function (data) {
      var permittable = data.permittables.items[data.permittables.item];
      if (!!permittable) {
        permittable.generated = data.generated;
        permittable.attendant = _.get(data, [
          "attendants",
          "items",
          permittable.scope,
        ]);
        permittable.location = _.get(data, [
          "locations",
          "items",
          permittable.scope,
        ]);

        if (!!permittable.location)
          permittable.address = _.get(
            data,
            ["addresses", "items", permittable.location.address],
            permittable.location.address || permittable.address
          );
        if (!!permittable.attendant)
          permittable.address = _.get(
            data,
            ["addresses", "items", permittable.attendant.address],
            permittable.location.address || permittable.address
          );
        //if(!!permittable.attendant) permittable.address = data.addresses.items[permittable.attendant.address] || permittable.attendant.address || permittable.address;

        permittable.vehicle = _.get(data, [
          "vehicles",
          "items",
          permittable.container,
        ]);
        permittable.tenant = _.get(data, [
          "tenants",
          "items",
          permittable.container,
        ]);
        permittable.space = _.get(data, [
          "spaces",
          "items",
          permittable.container,
        ]);

        // if (!!permittable.tenant)
        //   permittable.tenant.type = _.get(data, "tenants.type");

        api.cache.set(permittable.id, permittable, data.generated);
      }
      return permittable;
    },
  };

  var _init = _.once(function (ctx) {
    // not sure what we do here...
  });

  var setup = function (ctx, next) {
    _init(ctx);
    next();
  };

  var show = function (ctx, next) {
    // show a specific violation
    root.setAttribute("data-record", "permittables/show");

    // reset

    view();

    api.permittable.get(ctx.params.id).then(function (permittable) {
      if (!permittable) return null;

      //console.debug(permittable);

      view(permittable);
      document.documentElement.classList.add("ready");
    });
  };

  var exit = function (ctx, next) {
    view();
    next();
  };

  _.each(["/banned/:id"], function (path) {
    page(path, show);
    page.exit(path, exit);
  });
})(window.page, ParkIQ.API);

export default window;
