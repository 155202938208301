(function(root, page) {

    function stop() {
        _.each(root.querySelectorAll("iframe[allow*='camera']"), function(loader) {
            loader.remove();
        });
    }


    function exit(ctx, next) {
        stop();

        if(!!next) next();
    }

    page.exit(exit);

}(document.documentElement, page));

export default window;