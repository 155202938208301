(function(root, Delegate) {

    var delegate = new Delegate(root);
    
    // print keycommand
    delegate.on("print", function(e) {
        
        var pdfToPrint = document.querySelector("section.pass a[type='application/pdf'][href]");
        if(!!pdfToPrint) {
            
            var pass = document.querySelector("section.pass");
            pass.classList.add("printing");
            
            var imgToPrint = pdfToPrint.href + "?fm=png8&w=2100&rect=.085,.075,.83,.80";

            //window.location.href = imgToPrint; // just open pdf...
            
            return Promise.join(Promise.delay(1000), ImagePrinter.print(imgToPrint)).then(function() {
                pass.classList.remove("printing");
            }, function() {
                // error
                pass.classList.remove("printing");
            });
        }
        
        // try to find
        //var pdf = document.querySelector("section.pass a[href*='.pdf'][rel~='print']");
        //if(!!pdf && pdf.href) window.location.href = pdf.href;
        
    });
    
    delegate.on("click", "section.pass a[type='application/pdf'][href]:not([download])", function(e) {
        e.preventDefault();
        this.dispatchEvent(new CustomEvent("print", { "bubbles": true }));
	});
    
    // pdf
    delegate.on("click", "section.pass a[type='application/pdf'][href][download]", function(e) {
        this.dispatchEvent(new CustomEvent("track", {
            bubbles: true,
            detail: {
                category: "pass.public", 
                action: "pdf", 
                label: window.location.href,
            },
        }));
	});
    
}(document.documentElement, window.Delegate || window.domDelegate.Delegate));

export default window;