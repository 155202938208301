<script>
  import { get, filter } from "lodash-es";

  export let property;
  export let policies;

  $: media =
    !!property &&
    !!get(property, "media.enabled") &&
    get(property, "media.format") === "smart-decal";
  $: violations =
    !!property && !!get(property, "violations.selfservice.enabled");
  $: account = !!property && get(property, "tenants.selfservice.enabled");
  $: safelist =
    !!property && !!get(property, "attendant.permits.selfservice.list.enabled");
  $: email = !!property && !!get(property, "tenants.selfservice.auth");
</script>

<nav data-records="permits/temporary">
  {#if policies}
    {#each policies.filter((item) => item.public !== false) as policy}
      <h1>{policy.title}</h1>
      <p class="alert">{get(policy, "alert.text", "")}</p>
      <p class="info">{get(policy, "info.text", "")}</p>
      {#if !!get(policy, "disabled")}
        <p>{get(policy, "disabled.title") || get(policy, "disabled.reason")}</p>
      {:else}
        <p class="rules">
          {filter([
            get(policy, "rules.summary"),
            get(policy, "metered.summary"),
            get(policy, "pricing.summary"),
          ]).join(" ")}<a href="/{property.id}/rules">Full rules...</a>
        </p>
        {#if !!get(policy, "issue.enabled", false)}
          {#if get(policy, "issue.url")}
            <a
              class="issue {filter([
                !!get(policy, 'media.request', false) &&
                  get(policy, 'media.format'),
              ]).join(' ')}"
              href={policy.issue.url}
              >{get(policy, "issue.title") ||
                "Register " + get(policy, "title", "")}</a
            >
          {:else if !!get(policy, "media.required", false) && get(policy, "media.format") === "smart-decal"}
            <a
              class="issue {filter([
                !!get(policy, 'media.request', false) &&
                  get(policy, 'media.format'),
              ]).join(' ')}"
              href="/{property.id}/permits/media/new?policy={policy.subject}"
              >{get(policy, "issue.title") ||
                "Register " + get(policy, "title", "")}</a
            >
          {:else if get(policy, "pricing.enabled")}
            <a
              class="issue {filter([
                !!get(policy, 'media.request', false) &&
                  get(policy, 'media.format'),
              ]).join(' ')}"
              href="https://pay.parkingattendant.com/{property.id}/permits/new?policy={policy.subject}"
              >{get(policy, "issue.title") ||
                "Register " + get(policy, "title", "")}</a
            >
          {:else if !!get(policy, "permit.continuous", false)}
            <a
              class="issue {filter([
                !!get(policy, 'media.request', false) &&
                  get(policy, 'media.format'),
              ]).join(' ')}"
              href="/{property.id}/permits/new?policy={policy.subject}"
              >{get(policy, "issue.title") ||
                "Register " + get(policy, "title", "")}</a
            >
          {:else}
            <a
              class="issue"
              href="/{property.id}/permits/temporary/new?policy={policy.subject}"
              >{get(policy, "issue.title") ||
                "Register " + get(policy, "title", "")}</a
            >
          {/if}
          {#if !!get(policy, "preauthentication.enabled")}
            <a
              href="{!!get(policy, 'pricing.enabled')
                ? 'https://pay.parkingattendant.com'
                : ''}/{property.id}/permits/temporary/link?policy={policy.subject}"
              >Preauthorize</a
            >
          {/if}
        {/if}
      {/if}
    {/each}
  {/if}
</nav>
{#if property}
  <nav>
    <h1>More</h1>
    <a href="/{property.id}/rules">Parking Rules</a>
    {#if safelist}
      <a href="/{property.id}/permits/temporary/valid">Current Safelist</a>
    {/if}
    {#if account}
      <a href="/{property.id}/account">Check Your Usage</a>
    {/if}
    {#if email}
      <a href="/{property.id}/account/email">Forgot Passcode</a>
    {/if}
    {#if violations}
      <a href="/{property.id}/violations">Lookup Violation</a>
    {/if}
    {#if media}
      <a href="/{property.id}/media">Lookup Smart Decal</a>
    {/if}
    <a href="/{property.id}/directions">Map & Directions</a>
  </nav>
{/if}
