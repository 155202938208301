<script>
  import { format, parseISO } from "date-fns";
  import { indexOf, sortBy } from "lodash-es";
  import MediaPlacement from "./MediaPlacement.svelte";

  export let updated;
  export let property;
  export let policies;
  export let documents;
  export let violations;
</script>

{#if updated}
  <header>
    <h1>Parking Rules</h1>
    <time data-valid datetime={updated || ""}
      >{format(parseISO(updated), "MMM d yyyy h:mm a zzz")}</time
    >
  </header>
  <ul>
    {#each sortBy( Object.values(policies?.items || {}).filter((item) => item.audience.public && (!item.amenity || item.amenity === "parking")), ["rank", "title"] ) as item}
      <li class="policy">
        <h2>{item.title}</h2>
        <p>{item.rules?.description || ""}</p>
        <p>{item.metered?.description || ""}</p>
        <p>{item.rules?.custom || ""}</p>
        <p>{item.instructions || ""}</p>
        <ul data-records="documents">
          {#each Object.values(item.documents?.items || {}) as item}
            <li>
              <p>
                <a href={item.url} target="_blank" type={item.type}
                  >{item.title || item.name}</a
                >
              </p>
            </li>
          {/each}
        </ul>
        <p>{item.pricing?.description || ""}</p>
      </li>
    {/each}
  </ul>
  <!-- {#if violations?.policies?.issued?.count > 0}
    <h2>Violations</h2>
    <ul>
      {#each sortBy( Object.values(violations.policies.issued.items), ["rank"] ) as item}
        <li><p>{item.name}: {item.description}</p></li>
      {/each}
    </ul>
  {/if} -->
  {#if property?.media?.placement}
    <!-- <h2>Smart Decals</h2> -->
    <MediaPlacement placement={property?.media?.placement} item={false} />
  {/if}
  <!-- <ul></ul>
        <% _.each(_.sortBy(_.filter(_.get(item, "issuers.items", []), function(item) { return ; }), [ "rank", "title" ]), function(item) { %><li class="policy"><h2><%= _.get(item, "title") %></h2><p><%= _.get(item, "rules.description", "") %></p><p><%= _.get(item, "metered.description", "") %></p><p><%= _.get(item, "rules.custom", "") %></p><p><%= _.get(item, "instructions", "") %></p><ul data-records="documents"><% _.chain(item).get("documents.items", []).map().value().forEach(function(document) { %><li><p><a href="<%= document.url %>" target="_blank" type="<%= document.type %>"><%= document.title || document.name %></a></p></li><% }); %></ul><p><%= _.get(item, "pricing.description", "") %></p></li><% }); %></ul>
    ' + <% if(!!_.get(item, "violations.policies.issued.enabled") && _.get(item, "violations.policies.issued.count", 0) > 0) { %><h2>Violations</h2><ul><% _.chain(item).get("violations.policies.issued.items", []).map().orderBy([ "rank" ]).value().forEach(function(item) { %><% }); %></ul><% } %> -->
{/if}
