<script context="module">
  import { identity, upperFirst } from "lodash-es";
  import { parseISO } from "date-fns";
  import { format, utcToZonedTime } from "date-fns-tz";
  import Record from "./RecordItem.svelte";
</script>

<script>
  export let permittable = null;
</script>

{#if permittable}
  <header>
    <h1>{false === permittable.permittable ? "Banned" : "Unbanned"}</h1>
  </header>
  <dl class="subjects">
    {#each [permittable.vehicle, permittable.media, permittable.space, permittable.tenant].filter(identity) as record}
      <dt>{record.title || record.format || record.type}</dt>
      <dd><Record item={record} /></dd>
    {/each}
  </dl>
  <dl class="subjects">
    <dt>Effective</dt>
    <dd>
      <time datetime={permittable.issued.utc}
        ><abbr title={parseISO(permittable.issued.utc).toString()}
          >{format(
            utcToZonedTime(
              parseISO(permittable.issued.utc),
              permittable.timezone
            ),
            "h:mm a EEE MMM d yyyy",
            {
              timeZone: permittable.timezone,
            }
          )}</abbr
        ></time
      >
    </dd>
    <dt>Record #</dt>
    <dd><data value={permittable.id}>{permittable.number}</data></dd>
    <dt>Synced</dt>
    <dd>
      <time datetime={permittable.generated}
        >{format(
          parseISO(permittable.generated),
          "MMM d yyyy h:mm a zzz"
        )}</time
      >
    </dd>
  </dl>
  <nav>
    <a class="rules" href="/{permittable.scope}/rules">Parking Rules</a>
  </nav>
  <aside class="instructions">
    {#each [].filter(identity) as text}
      <p>{text}</p>
    {/each}
  </aside>
{/if}
