(function(root, Delegate, api) {

    var delegate = new Delegate(root);
    
    /*delegate.on("load", "figure.pass img", function(e) {

        
        this.closest("figure.pass").classList.add("loaded");
        
    });*/
    
    // send
	delegate.on("click", "section.send a[href^='mailto:']", function(e) {
		e.preventDefault();

		document.documentElement.classList.add("send");
        var input = document.querySelector("section.send input[type='email']");
        if(!!input) input.focus();
        
        this.dispatchEvent(new CustomEvent("track", {
            bubbles: true,
            detail: {
                category: "permits.public", 
                action: "send.open", 
                label: window.location.href,
            },
        }));
		
		//$(document).trigger("trackevent", ["permits.public", "send.open", window.location.href]); 
	});
    
    delegate.on("click", function(e) {
		var sendcontainer = document.querySelector("section.send");
		if(!sendcontainer || sendcontainer == e.target || sendcontainer.contains(e.target)) return;
		document.documentElement.classList.remove("send");
	});
    
    api.send = function(form) {
        //var formData = new FormData(form);
        var formData = serialize(form);
        var requested = new Date().toISOString();
        return Promise.resolve(api.base()).then(function(base) {
            return fetch(base + "v1/send?viewpoint=" + requested + "&" + formData, {
                method: "POST",
                //body: formData,
            });
        }).then(api.response.process, api.response.onError);
        
    }
    
    delegate.on("submit", "form.send", function(e) {
		e.preventDefault();
		
        
        var form = this;

		
		var disable = _.filter(form.querySelectorAll("button,input,textarea,select"), function(input) {
            input.blur();
            return !input.disabled;
        });
        
        if(disable.filter(function(input) {
            return input.required && !input.value;
        }).length > 0) return; // required with no value
      
        
        if (form.classList.contains("submitting")) return;
        form.classList.add("submitting");
        
        var sent = api.send(form);
        
        disable.forEach(function(input) {	
            input.disabled = true;
        });

		sent.then(function(data) {
			//alert("sent");
		}, function() {
            form.classList.remove("submitting");
            disable.forEach(function(input) {
               input.disabled = false; 
            });
  		});
  		
		Promise.join(sent, Promise.delay(1000)).then(function() {
            form.classList.add("success");
            form.classList.remove("submitting");
		}, function() {
		
		}).delay(3000).then(function() {
			document.documentElement.classList.remove("send");
			//$("body").removeClass("send");
			disable.forEach(function(input) {
               input.disabled = false; 
            });
            form.reset();
            form.classList.remove("success");
		});
		

        
        form.dispatchEvent(new CustomEvent("track", {
            bubbles: true,
            detail: {
                category: "permits.public", 
                action: "send.sent", 
                label: window.location.href,
            },
        }));
		
	});
    
    _.each(document.querySelectorAll("form.send"), function(form) {
        if(!!form) form.setAttribute("novalidate", "novalidate");
    });
        
    
}(document.documentElement, window.Delegate || window.domDelegate.Delegate, ParkIQ.API));

export default window;