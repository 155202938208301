// update location
(function (page, postal, api) {
  var _contactTemplate = _.template(
    '<h2><%= !contact.title ? "Questions?" : contact.title %></h2><p><% if (!contact.title) { %>Contact <%= !contact.name && (!!contact.email || !!contact.phone) ? "us at " : "" %><% } %><%= contact.name %><% if (!!contact.name && (!!contact.email || !!contact.phone)) { %> at <% } %><% if (!!contact.email) { %><a href="mailto:<%= contact.email %>"><%= contact.email %></a><% } %><% if (!!contact.email && !!contact.phone) { %> or <% } %><% if (!!contact.phone) { %><span class="tel"><%= contact.phone %></span><% } %>.</p>'
  );

  var last = null;

  postal.subscribe({
    topic: "location.item",
    callback: function (data, envelope) {
      var ts = new Date(data.generated || data.ts);

      if (!!last && last.getTime() >= ts.getTime()) return; // this isn't newer

      var location = data.item;

      if (!location) return;
      //console.debug(location);

      _.each(
        document.querySelectorAll("input[name='location']"),
        function (input) {
          if (!!input) input.setAttribute("value", (input.value = location.id));
        }
      );

      // check for passcode lookup enabled

      var optional = {
        media:
          !!_.get(location, "media.enabled") &&
          _.get(location, "media.format") === "smart-decal",
        violations: !!_.get(location, "violations.selfservice.enabled"),
        "account/email": !!_.get(location, "tenants.selfservice.auth"),
        account: !!_.get(location, "tenants.selfservice.enabled"),
        "permits/temporary/valid": !!_.get(
          location,
          "attendant.permits.selfservice.list.enabled"
        ),
      };

      _.each(document.querySelectorAll("a[rel]"), function (a) {
        //a.setAttribute("href", attendant.url);

        if (optional[a.getAttribute("rel")] === false) {
          a.removeAttribute("href");
          return;
        }

        a.setAttribute(
          "href",
          "/" + _.filter([location.id, a.getAttribute("rel")]).join("/")
        );
      });

      // _.each(document.querySelectorAll("nav[data-records='media']"), function(elem) {
      //     if(!_.get(location, "tenants.permits")) elem.innerHTML = "";
      //     else elem.innerHTML = _assignedNavTemplate(location);
      // });

      // contacdt
      var contact = _.extend(
        {
          title: null,
          name: null,
          phone: null,
          email: null,
        },
        _.get(location, "contact")
      ); // change to location level

      // contact
      _.each(
        document.querySelectorAll("aside[data-record='contact']"),
        function (container) {
          container.innerHTML =
            !!contact.name || !!contact.phone || !!contact.email
              ? _contactTemplate({ contact: contact })
              : "";
        }
      );

      // _.each(document.querySelectorAll("a[rel='permits/continuous/new'],a[rel='media/activate']"), function(a) {
      //     //a.setAttribute("href", attendant.url);

      //     if(!!_.get(location, "media.permits.selfservice")) {
      //         a.setAttribute("href", "/" + _.filter([
      //             location.id,
      //             a.getAttribute("rel")
      //         ]).join("/"));
      //         //a.setAttribute("title", a.textContent = "Activate Smart Decal");
      //     } else {
      //         a.removeAttribute("title");
      //         a.removeAttribute("href");
      //     }

      // });

      last = ts;
    },
  });
})(page, postal, ParkIQ.API);

export default window;
