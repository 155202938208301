<script context="module">
  import identity from "lodash-es/identity";
  //import { href } from "../util/router";
  function href(item) {
    return "";
  }
</script>

<script>
  import RecordExtras from "./RecordExtras.svelte";
  import RecordLink from "./RecordLink.svelte";
  import RecordSpaceCovered from "./RecordSpaceCovered.svelte";

  export let url = false;
  export let item;
</script>

<RecordLink {item} {url}>
  <data
    class={["id", item.type, item._type, item.format]
      .filter(identity)
      .join(" ")}
    value={item.id || item.key}
    >{item.display}<slot
      ><RecordSpaceCovered {item} label={false} /><RecordExtras {item} /></slot
    ></data
  ><RecordSpaceCovered {item} label={true} /></RecordLink
>
