<script>
  import { chunk } from "lodash-es";

  export let item = null;
  $: keycode = item?.enabled && [item.code].filter((i) => null != i).join("");
</script>

{#if keycode}
  <data value={keycode} class="keypad">
    {#if null != item.pre}
      <span>
        {#each item.pre as char}
          <data class="key" value={char}>{char}</data>{/each}
      </span>
      <span />{/if}
    {#each keycode.length > 5 ? chunk([...keycode], 4) : [keycode] as group}
      <span>
        {#each group as char}
          <data class="key" value={char}>{char}</data>{/each}</span
      >
      <span />{/each}
    {#if null != item.post}
      <span>
        {#each item.post as char}
          <data class="key" value={char}>{char}</data>{/each}
      </span>
    {/if}</data
  >
{/if}
