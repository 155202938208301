import { get, merge, map, unset } from "lodash-es";
import { Api } from "@parkingboss/api";
import { updateItems, items } from "@parkingboss/svelte-utils";
import { format } from "date-fns-tz";
import pkg from "../../../package.json";

export const client = name.split("/")[1] || pkg.name;

export const api = Api({
  client,
  skipUrlRewrite: true,
});

const apiVersion = "v2";

export function base() {
  return api.settings.apiBase + apiVersion;
}

function updateFor(key, value) {
  if (!key || !value) return;

  // value can be a key containing a for or the map itself?
  const mapFor = value["for"] || value;

  items.update((state) => {
    if (!state[key]) state[key] = {};
    if (!state[key]["for"]) state[key]["for"] = {};
    Object.assign(state[key]["for"], mapFor); // update or add the items by key
    return state;
  });
}

export function viewpoint(offset) {
  return encodeURIComponent(
    format(new Date().getTime() + (offset || 0), "yyyy-MM-dd'T'HH:mm:ssxxx")
  );
}

function formDataToURLSearchParams(formData) {
  if (!formData || !formData.entries) return new URLSearchParams();

  // this should cover everything that supports iterating form data
  return new URLSearchParams([...formData.entries()]); // create an array from the entries iterator (edge 18 doesn't take an iterator)
}

export async function fetchCreateToken(scope, subject, password, context) {
  const res = await fetch(
    `${
      api.settings.apiBase
    }/accounts/auth/tokens?viewpoint=${viewpoint()}&scope=${scope}&subject=${subject}&password=${password}&context=${context}`,
    {
      method: "POST",
      //body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    // store?
    json.item = get(json, ["accounts", "items", get(json, "accounts.item")]);
    unset(json, "accounts");

    // store state
    auth.update(($auth) =>
      merge(
        $auth,
        {
          [scope]: json,
          [subject]: json,
        },
        {
          [json.item.scope]: json,
          [json.subject]: json,
        }
      )
    );

    return json;
    //return await fetchAndStoreUsers(scope);
  }

  return await responseJson(res);
}

export async function fetchCreatePermit(formData) {
  var query = formDataToURLSearchParams(formData).toString();

  const res = await fetch(
    `${api.settings.apiBase}/permits/temporary?viewpoint=${viewpoint()}&${
      query || "noformdataentries=true"
    }`,
    {
      method: "POST",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);
    storeSinglePermit(json);
    return json;
  } else if (res.status == 402) {
  }

  return await responseJson(res);
}

export async function fetchPayFee(fee, token, amount) {
  const res = await fetch(
    `${api.settings.apiBase}/v1/fees/${
      fee.id || fee
    }/payments?viewpoint=${viewpoint()}&source=${token}&amount=${amount}`,
    {
      method: "POST",
    }
  );

  return await responseJson(res);
}

function storeSinglePermit(json) {
  if (!json) return json;
  updateItems(merge({}, json.items, ...map(json, (value, key) => value.items))); // old style
  for (const [key, value] of Object.entries(json)) {
    if (!!value["for"]) updateFor(key, value);
  }
  return json;
}

export async function responseJson(response) {
  if (!response) return {};
  return response
    .text()
    .then(function (text) {
      if (!text)
        return {
          status: response.status,
        };

      return Promise.resolve(text)
        .then(JSON.parse)
        .catch(function (error) {
          return {
            status: response.status,
            message: text,
          };
        });
    })
    .catch(function (error) {
      return {
        status: response.status,
      };
    });
}

export async function fetchRequestPhysicalAccess(permit, via) {
  permit = permit?.id || permit;

  if (!permit) return null;

  const res = await fetch(
    `${
      api.settings.apiBase
    }v2/entry/request?viewpoint=${viewpoint()}&permit=${permit}&via=${via}`,
    {
      method: "POST",
      //body: formData,
    }
  );

  const json = await responseJson(res);

  if (null == json) return json;

  return Object.values(json.physicalaccess?.results ?? {})[0];

  return json;
}

export async function fetchPlateObservation(property, blob, coords) {
  const photo = await image.resize(
    blob,
    {
      width: 1280,
      height: 1280,
    },
    "image/jpeg",
    0.45
  );

  //console.log(URL.createObjectURL(photo));

  const data = new FormData();
  data.append("image", photo, `image${Date.now()}.jpg`);

  let res = await fetch(
    `${base()}/detections/vehicles?viewpoint=${viewpoint()}&scope=${
      property.id || property
    }&client=${client}&method=scanner`,
    {
      method: "POST",
      body: data,
    }
  );
  let json = await responseJson(res);
  return json;
}

export async function fetchMedia(property, media) {
  return fetch(
    `${base()}/${
      property ? "locations/" + (property?.id ?? property) : ""
    }/media/${media?.id ?? media}?viewpoint=${viewpoint()}`
  ).then(responseJson);
  // const json = await responseJson(res);
  // return json;
}
