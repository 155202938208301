(function(exports) {

  var finePointer = !!_.get(_.invoke(window, "matchMedia", "(pointer: fine)"), "matches");
  var coarsePointer = !finePointer;

  var initDate = function(name, maxFutureStartHours, night, dateStart, startOptions, days) {

      if(!dateStart) dateStart = new Date();

      //console.log(select);
     var maxFutureStartDays = Math.floor(maxFutureStartHours / 24);
     // this has a fixed start date (no future and night)
     var fixed = !!night && maxFutureStartDays <= 0 ? '<input type="hidden" name="' + name + '" value="' + moment(dateStart).format("YYYY-MM-DD") + '">' : '';
   return fixed + '<label class="date constrained now"><select name="' + name + '" ' + (maxFutureStartDays <= 0 ? 'disabled="disabled" ' : '') + 'class="date">'
   + (!!night ? _.reduce(_.range(0, 7), function(html, i) {

          if(i > maxFutureStartDays) return html;
          

          var m = moment(dateStart).add({days:i});

          if(!!days && !days[m.isoWeekday()]) return html; //doesn't support day of week

          return html + '<option value="' + m.format("YYYY-MM-DD") + '" label="' + m.format("dddd [night] (MMM D)") + '">' + m.format("dddd [night]") + '</option>';
      }, '') : _.reduce(_.range(0, maxFutureStartDays + 1), function(html, i) {
          if(i > maxFutureStartDays) return html;
          var m = moment(new Date()).add({days:i});

          if(!!days && !days[m.isoWeekday()]) return html; //doesn't support day of week

          return html + '<option value="' + m.format("YYYY-MM-DD") + '" label="' + m.calendar(null, {
              sameDay: '[Today]',
              nextDay: 'ddd MMM D',
              nextWeek: 'ddd MMM D',
              lastDay: 'ddd MMM D',
              lastWeek: 'ddd MMM D',
              sameElse: 'ddd MMM D'
          }) + '">' + m.format("ddd MMM D") + '</option>';
      }, startOptions))
      + '</select></label>';

  
  //return select;
  };
  
  var initStartDate = function(maxFutureStartHours, night, dateStart, days, nowOK) {

       if(nowOK !== false) nowOK = !dateStart && (!days || _.size(days) == 7); // now is only ok if not a fixed start date (e.g. retroactive) and all 7 days are option
      if(!dateStart) dateStart = new Date();

      var displayPreviousDayAsNight = night && moment(dateStart).hours() < 5; // considered night and start before 5am

      //console.log(select);
     var maxFutureStartDays = Math.floor(maxFutureStartHours / 24);
     // this has a fixed start date (no future and night)
     var fixed = !!night && maxFutureStartDays <= 0 ? '<input type="hidden" name="startDate" value="' + moment(dateStart).format("YYYY-MM-DD") + '">' : '';
   return fixed + '<label class="date constrained now"><select name="startDate" ' + (maxFutureStartDays <= 0 ? 'disabled="disabled" ' : '') + 'class="date">'
   + (!!night ? _.reduce(_.range(0, maxFutureStartDays), function(html, i) {

          if(i > maxFutureStartDays) return html;

          var m = moment(dateStart).add({days:i});
          var displayDate = m;
          if(displayPreviousDayAsNight) displayDate = displayDate.subtract({days:1});

          if(!!days && !days[m.isoWeekday()]) return html; //doesn't support day of week

          return html + '<option value="' + m.format("YYYY-MM-DD") + '" label="' + displayDate.format("ddd [night] (MMM D)") + '">' + displayDate.format("ddd [night]") + '</option>';

      }, '') : _.reduce(_.range(0, maxFutureStartDays), function(html, i) {
          if(i > maxFutureStartDays) return html;
          var m = moment(new Date()).add({days:i});

          if(!!days && !days[m.isoWeekday()]) return html; //doesn't support day of week

          return html + '<option value="' + m.format("YYYY-MM-DD") + '" label="' + m.format("ddd MMM D") + '">' + m.format("ddd MMM D") + '</option>';
      }, nowOK ? '<option value="" label="Right now">Right now</option>' : ''))
      + '</select></label>';

  
  //return select;
  };
  
  var initTime = function(name, minHour, maxHour, startMinute, disabled) {

      if(!minHour && 0 !== minHour) minHour = 0;
      if(!maxHour && 0 !== maxHour) maxHour = 23;
      var increment = 15;
      //if(!startMinute) startMinute = 0;

      //disabled = (disabled !== false);
      
      if(minHour == maxHour) return '<input type="hidden" name="' + name + '" value="' + moment({hour: minHour, minute: startMinute || 0}).format("HH:mm") + '">';

      // go over the clock and build a list of times
      var times = [ ];

      if(maxHour < minHour) {
          var i = 12 * 60;
          while(i < 24 * 60) {
              times.push(i);
              i+=increment;
          }
          i = 0
          while(i < 12 * 60) {
              times.push(i);
              i+=increment;
          }
      } else {
          i = 0
          while(i < 24 * 60) {
              times.push(i);
              i+=increment;
          }
      }



      var items = times.filter(function(minutes) {
          if(minHour <= maxHour) return minutes >= (minHour * 60) && minutes <= (maxHour * 60);
          return minutes >= (minHour * 60) || minutes <= (maxHour * 60);
      });

      // var i = (minHour * 60) + (startMinute || 0);
      // items.push(i);
      // i+= increment;
      // while(i <= maxHour * 60) {
      //     items.push(i);
      //     i+=increment;
      // }
      //_.range(minHour, maxHour + 1)
      
      return '<label class="time' + (disabled ? ' disabled': '') + '"><select name="' + name + '" class="time"' + (disabled ? ' disabled="disabled"': '') + '>'
       + _.reduce(items, function(html, i) {
          //var m = moment({hour: i, minute: 0});
          var m = moment({hour:0, minute: 0}).add({m:i});
          
          return html + '<option value="' + m.format("HH:mm") + '"' + (m.hours() == moment(new Date()).hours() ? ' selected' : '') + '>' + m.format("h:mm A") + '</option>';
      }, "")
       + '</select></label>';
};
  
  var initStartTime = function(minHour, maxHour, startMinute) {

      return initTime("startTime", minHour, maxHour, startMinute);
};

var initDuration = function(increment, minHours, maxHours, night, initial) {
      
      //if(minHours == maxHours) return "";
  
  console.log(increment);
  console.log(minHours);
  console.log(maxHours);


  var days = 24 == increment;
      if(minHours == maxHours && maxHours <= 24) days = false; // single 24-hour option shouldn't be days
  //var def = minHours; // = 6
      var def = moment.duration(initial || "PT6H");
  if(minHours == maxHours) increment = maxHours;
      var overnight = night && increment >= 12;
      var chooseOpenEndTime = 1 == minHours && 7 * 24 <= maxHours; 
  
  //console.log(night);
      //console.log(durationIncrement);
      
      //console.log(_.range(minHours, maxHours + 1, increment));
      
      var hours = _.filter(_.range(minHours, maxHours + increment, increment), function(h) {

          if(h == maxHours) return true; // always allow the max

          if(h > maxHours) return false;

          // standard time spread algorithm

          if(h > 10 && h % 2 > 0) return false; // over 10 we're only going to use increments of 2 
          //if(maxHours > 48 && h > 10  && h < 16 && h % 4 > 0) return false; // if max over 48, over 10 we're only going to use increments of 4 hours (remove 10)
          if(increment >= 4 && h < 24) return true; // between min and 24 we're gonna use intervals of min if increment greater or equal to 4 hours
          if(h > 12 && h % 6 > 0) return false; // over 12 we're only going to use increments of 6 hours
          //if(h > 24 && h % 12 > 0) return false; // over 24 we're only going 
          if(h > 30 && h % 12 > 0) return false; // over 24 we're only going to use increments of 12 hours
          if(maxHours > 72 && h > 72 && h % 24 > 0) return false; // if max greater than 72, switch to 24 hour blocks after 72
          return true;
              
      });

      var labels = {};
      _.each(hours, function(h) {

          var i = h; //!overnight && !days && increment == h  ? h : (h / increment);
          if(!!days || !!overnight) i = (h / increment);
          if(h > 72) i = h / 24; // force 24 hour blocks

          if(0 != (h * 60) % 60) {
              return _.set(labels, h, (h * 60  + " minutes"));
          }

          _.set(labels, h, (i + (!!overnight ? " night" : (days || h > 72 ? " day" : " hour")) + (1 == i ? "" : "s")));
      });

      //console.log(hours);

      /*if(hours.length < 6) return '<label class="range"><input type="range" name="duration" value="' + hours[0] +'" min="' + hours[0] + '" max="' + hours[hours.length - 1] + '" step="1"><span></span></label>'*/
      
      /*if(hours.length < 20) return '<fieldset class="duration"><ul>' +
          _.reduce(hours, function(html, h) {
              
              var d = moment.duration({ hours: h });
              //var h = d.asHours();
              var i = !overnight && !days && increment == h  ? h : (h / increment);
              
              var ts = [];
              if(d.days() > 0) {
                  ts.push(d.days());
                  ts.push(".");
              }
              if(d.hours() < 10) ts.push("0");
              ts.push(d.hours());
              ts.push(":");
              if(d.minutes() < 10) ts.push("0");
              ts.push(d.minutes());
              
              return html + '<li><label class="radio"><input name="duration" type="radio" value="' + ts.join("") + '"' + (def == h ? ' checked' : '') + '><span data-interval="' + (!!overnight ? " night" : (days ? "day" : "hour")) + (1 == i ? "" : "s") + '">' + i + '</span></label></li>';
              //(!!overnight ? " night" : (days ? " day" : " hr")) + (!days || 1 == i ? "" : "s") + 
              
          }, [])
      + '</ul></fieldset>';*/

      var simplified = 1 < hours.length && 3 >= hours.length; // 2 & 3 opts by default
      simplified = simplified || (1 < hours.length && coarsePointer);

      var fixed = (minHours == maxHours ? ('<input type="hidden" name="duration" value="' + moment.duration({ hours: minHours }).toJSON() + '">') : "");

      if(simplified) return fixed + '<ul>'
  + _.reduce(hours, function(html, h) {
    
    var d = moment.duration({ hours: h });
          return html + '<li><label><input type="radio" name="duration" value="' + d.toJSON() + '"' + (false && def.asHours() == d.asHours() ? ' checked' : '') + '><span>' + _.get(labels, h) + '</span></label></li>';
          
  }, [])
      + (chooseOpenEndTime ? '<li><label><input type="radio" name="duration" value=""><span>Other...</span></label></li></u>' : '');
  
  // account for exclusive _range end
      return fixed + (!!fixed && !!night ? '' : '<label class="duration"><select name="duration" ' + (minHours == maxHours ? 'disabled="disabled" ' : '') + 'class="duration">'
  + _.reduce(hours, function(html, h) {
    
    var d = moment.duration({ hours: h });
    return html + '<option value="' + d.toJSON() + '"' + (def.asHours() == d.asHours() ? ' selected' : '') + '>' + _.get(labels, h) + '</option>';
          
      }, [])
      + (chooseOpenEndTime ? '<option value="">Other...</option>' : '')
      + '</select></label>');
      

  
  //return select
      
  };
  
  function dateFixedTimeSelect(name, smallest, largest) {

      console.log(name, smallest, largest);

      var item = smallest;
      var items = [];
      while(item < largest) {
          items.push(item);
          item = dateFns.addDays(item, 1);
      }

      return '<label class="date time"><select name="' + name + '">' +  _.reduce(items, function(html, item) {

          return html + '<option value="' + dateFns.format(item, "YYYY-MM-DDTHH:mm:ss") + '" label="' + dateFns.format(item, "ddd MMM D h:mm A") + '">' + dateFns.format(item, "ddd MMM D h:mm A") + '</option>';
      }, '') + '</select></label>';
  }
  
  exports.DateTimeDuration = function(container, settings) {
      
      if(!container) return;
      
      if(!settings) {
          while(container.firstChild) container.firstChild.remove();
          return;
      }

      //console.log(settings);

  var minDuration, maxDuration;
  var duration = null;
  var increment = null;
  var minStart, maxStart, minEndTime, maxEndTime;
      var start = null;
      var end = null;
      var maxFutureStartHours = moment.duration(_.get(settings, "start.future", "6.00:00:00")).asHours();
      var retroactive = null;
      var night = !!_.get(settings, "duration.nights", false) || !!_.get(settings, "start.night", false);
      if(!!_.has(settings, "start.retroactive")) retroactive = moment.duration(_.get(settings, "start.retroactive", "00:00:00"));

      var hasStartTime = !!_.get(settings, "start.time");
      var hasEndTime = !!_.get(settings, "end.time");
  
  if(_.isString(_.get(settings, "start.time"))) {
    minStart = maxStart = start = moment.duration(_.get(settings, "start.time", "00:00:00"));
      } else {
    minStart = moment.duration(_.get(settings, "start.time.min", "00:00:00"));
    maxStart = moment.duration(_.get(settings, "start.time.max", "23:59:59"));
      }
      
      if(_.isString(_.get(settings, "end.time"))) {
    minEndTime = maxEndTime = end = moment.duration(_.get(settings, "end.time", "00:00:00"));
      } else {
    minEndTime = moment.duration(_.get(settings, "end.time.min", ""));
    maxEndTime = moment.duration(_.get(settings, "end.time.max", ""));
  }
  
  if(_.isString(settings.duration)) {
    minDuration = maxDuration = duration = increment = moment.duration(settings.duration);
  } else {
    minDuration = moment.duration(_.get(settings, "duration.min", "01:00:00"));
    maxDuration = moment.duration(_.get(settings, "duration.max", "1.00:00:00"));
    increment = moment.duration(_.get(settings, "duration.increment", "01:00:00"));
  }
  
      if(!!increment) increment = increment.asHours();
      
  
  //console.log(minStart);
  //console.log(maxStart);
  //console.log(minDuration);
  //console.log(maxDuration);
  
  var minDurationHours = minDuration.asHours();
      var maxDurationHours = maxDuration.asHours();

      //console.log(minDurationHours, increment);
      
      // - only use api increment
      //if(!!increment && !!minDurationHours) increment = Math.max(increment, minDurationHours);
  
  //increment = (minDurationHours / 24 >= 1 && minDurationHours % 24 == 0 && maxDurationHours / 24 > 1 && maxDurationHours % 24 == 0) ? 24 : 1;
  var days = 24 == increment;
  
      //var night = !!start && start.hours() >= 19; // fixed starting time >= 7pm

      if(!!start && start.hours() >= 19) night = true; // fixed start on or after 7pm
      if(!!start && start.hours() < 6) night = true; // fixed start before 6am
      if(!!start && start.hours() >= 18 && minDuration.asHours() > 8) night = true;
      
      // this is a special case with fixed start and duration
      if(!!start && start.hours() >= 16 && !!duration && duration.hours() < 24 && duration.hours() > 10) night = true;

      if(!!start && !!end && start.hours() < end.hours()) night = false; // start before end is not night
      
      var startDate = null;

      if(!!start && !!retroactive) {

          for(var i = -1; i <= 1; i++) {

              var prevDateStart = moment(new Date()).add({days:i}).set({ hour: start.hours(), minute: start.minutes(), second: start.seconds(), millisecond: start.milliseconds() });

              if(moment(new Date()).subtract(retroactive).isBefore(prevDateStart)) {
                  startDate = prevDateStart;
                  //console.log("new startdate=", startDate, i);
                  break;
              }

          }

      }

      //var prevDateStart = null;

      //if(!!start && !!retroactive) prevDateStart = moment(new Date()).subtract({days:1}).set({ hour: start.hours(), minute: start.minutes(), second: start.seconds(), millisecond: start.milliseconds() });

      //console.log(prevDateStart, retroactive, moment(new Date()).subtract(retroactive), moment(new Date()).subtract(retroactive).isBefore(prevDateStart));

      // if(!!prevDateStart && moment(new Date()).subtract(retroactive).isBefore(prevDateStart)) startDate = prevDateStart;
      // else if(!!prevDateStart) {

      //     if(!!start && !!retroactive) prevDateStart = moment(new Date()).set({ hour: start.hours(), minute: start.minutes(), second: start.seconds(), millisecond: start.milliseconds() });
      //     if(!!prevDateStart && moment(new Date()).subtract(retroactive).isBefore(prevDateStart)) startDate = prevDateStart;
      // }

      
      if(maxDurationHours > minDurationHours && _.isString(_.get(settings, "start.time")) && _.isString(_.get(settings, "end.time"))) {

          // fixed start time, fixed end time, implicit duration flex required
          // give start and end datetime pickers

          var endDate = null;
          var maxEndDate =  dateFns.addHours(new Date(), maxFutureStartHours + maxDurationHours);

          for(var i = -1; i <= 1; i++) {

              var evalDateEnd = moment(new Date()).add({days:i}).set({ hour: end.hours(), minute: end.minutes(), second: end.seconds(), millisecond: end.milliseconds() });

              if(dateFns.isWithinRange(evalDateEnd, new Date(), maxEndDate)) {
                  endDate = evalDateEnd;
                  break;
              }

          }

          container.innerHTML = '<fieldset class="valid min">' + dateFixedTimeSelect("valid.min", startDate, dateFns.addHours(new Date(), maxFutureStartHours)) + '</fieldset><fieldset class="valid max">' + dateFixedTimeSelect("valid.max", endDate, maxEndDate) + '</fieldset>';


      } else if(!night && _.isString(_.get(settings, "start.time")) && !_.isString(_.get(settings, "end.time"))) {

          // not overnight, fixed start time, implicit end time, any duration
          // give start datetime picker + duration picker

          container.innerHTML = '<fieldset class="valid min">' + dateFixedTimeSelect("valid.min", startDate, dateFns.addHours(new Date(), maxFutureStartHours)) + '</fieldset><fieldset class="valid duration">' + initDuration(increment, minDurationHours, maxDurationHours, night, _.get(settings, "duration.initial")) + '</fieldset>';


      } else if(maxDurationHours > minDurationHours && _.isString(_.get(settings, "end.time"))) {
          
          // flex start time, fixed end time, implicit duration flex required
          // give start picker + end datetime picker

          var endDate = null;
          var maxEndDate =  dateFns.addHours(new Date(), maxFutureStartHours + maxDurationHours);

          for(var i = -1; i <= 1; i++) {

              var evalDateEnd = moment(new Date()).add({days:i}).set({ hour: end.hours(), minute: end.minutes(), second: end.seconds(), millisecond: end.milliseconds() });

              if(dateFns.isWithinRange(evalDateEnd, new Date(), maxEndDate)) {
                  endDate = evalDateEnd;
                  break;
              }

          }

          container.innerHTML = '<fieldset class="valid min">' + initStartDate(maxFutureStartHours, night, startDate, _.get(settings, "start.days"), !hasStartTime) + initTime("valid.min.time", minStart.hours(), maxStart.hours(), !!start ? start.minutes() : 0, !hasStartTime) + '</fieldset><fieldset class="valid max">' + dateFixedTimeSelect("valid.max", endDate, maxEndDate) + '</fieldset>';
          
      } else {

          // flexible start, flexible duration

          container.innerHTML = '<fieldset class="valid min">' + initStartDate(maxFutureStartHours, night, startDate, _.get(settings, "start.days"), !hasStartTime) + initTime("valid.min.time", minStart.hours(), maxStart.hours(), !!start ? start.minutes() : 0, !hasStartTime) + '</fieldset><fieldset class="valid duration">' + (hasEndTime ? '' : initDuration(increment, minDurationHours, maxDurationHours, night, _.get(settings, "duration.initial"))) + '</fieldset><fieldset class="valid max">' + (hasEndTime ? initTime("valid.max.time", minEndTime.hours(), maxEndTime.hours(), 0, false): '') + '</fieldset>';

      }
      //console.log('test');
      _.each(container.getElementsByTagName("select"), function(select) {
          //console.log(select);
         select.dispatchEvent(new CustomEvent("change", { "bubbles": true }));
      });

      _.invoke(container.querySelector("fieldset.valid.duration"), "addEventListener", "change", function(e) {

          console.log(e);

          //if(!_.invoke(e, "target.matches", "fieldset.valid.duration input:checked, fieldset.valid.duration select")) return;

          var input = e.target;

          var populate = input.checked !== false && input.value === "";



          //if(input.value !== "") return; // must be empty string
  
          var source = input.closest("fieldset");
          if(!source) return;

          var form = input.form || source.closest("form");
          if(!form) return;

          var target = form.querySelector("fieldset.valid.max");


          console.log(input, populate, source, target);

          if(!target) return;

          // could this be faster?
          target.innerHTML = !populate ? "" : initDate("valid.max.date", maxFutureStartHours + maxDurationHours) + initTime("valid.max.time");
          //source.innerHTML = "";
          _.each(target.getElementsByTagName("select"), function(select) {
              //console.log(select);
             select.dispatchEvent(new CustomEvent("change", { "bubbles": true }));
          });

      });

          //constrained(input.previousSibling, input);
  
  
};
}(window));
(function(root) {

  root.addEventListener("change", function(e) {

      if(!_.invoke(e, "target.matches", "form select.date")) return;

      var select = e.target;

      var label = select.closest("label");
      window.focus();
      var time = select.value != "";
      
      //console.log(time);
      //console.log(label);
      if(!time) label.classList.add("now");
      else label.classList.remove("now");
      
      var el = label;
      while(el = el.nextSibling) {
          //console.log(el);
          if(el.matches(".time")) {
              
              var timeSelect = el.querySelector("select.time");

              if(!time) {
                  el.classList.add("disabled");
                  timeSelect.disabled = true;
              } else {
                  el.classList.remove("disabled");
                  timeSelect.disabled = false;
                  timeSelect.dispatchEvent(new CustomEvent("change", { "bubbles": true }));
              }
              
          }
      }

  });

  // empty duration click
  // root.addEventListener("change", function(e) {

  //     if(!_.invoke(e, "target.matches", ".valid.duration input[value='']")) return;

  //     var input = e.target;

  //     var fieldset = input.closest("fieldset");
  //     if(!fieldset) return;
  //     fieldset.disabled = true;

  //     while(fieldset = fieldset.nextElementSibling) {
  //         if(fieldset.disabled) fieldset.disabled = false;
  //     }

  // });

  // var delegate = new Delegate(root);
  
  // delegate.on("change", "form.permit select.date", function (e) {
      
  //     //console.log(this);
  //     //console.log("value='" + this.value + "'");
      
  //     var select = this;
  //     //select.blur();
  //     var label = select.closest("label");
  //     window.focus();
  //     var time = select.value != "";
      
  //     //console.log(time);
  //     //console.log(label);
  //     if(!time) label.classList.add("now");
  //     else label.classList.remove("now");
      
  //     var el = label;
  //     while(el = el.nextSibling) {
  //         //console.log(el);
  //         if(el.matches(".time")) {
              
  //             var timeSelect = el.querySelector("select.time");

  //             if(!time) {
  //                 el.classList.add("disabled");
  //                 timeSelect.disabled = true;
  //             } else {
  //                 el.classList.remove("disabled");
  //                 timeSelect.disabled = false;
  //                 timeSelect.dispatchEvent(new CustomEvent("change", { "bubbles": true }));
  //             }
              
  //         }
  //     }
  // });
  
  
}(document.documentElement));

export default window;