<script>
  import { each, find, get, map } from "lodash-es";
  import { fetchMedia } from "../util/api";
  import go from "../util/router";
  import MediaScanner from "./MediaScanner.svelte";

  export let property = null;
  export let policy = null;

  async function onsubmit(e) {
    e.preventDefault();

    var form = e.target;

    if (!!form.matches(":invalid")) {
      alert("Please complete all fields");
      return;
    }

    var id = get(form.querySelector("input[name='media']"), "value", "");

    if (policy && id) return process(id);

    go("/" + (property?.id || property) + "/media/" + id);
  }
  async function onmedia(e) {
    if (policy && e?.detail?.id) return process(e.detail.id);
    e?.detail?.id &&
      go(
        "/" +
          (property?.id || property) +
          "/media/" +
          e.detail.id +
          "?qr=" +
          e.detail.type +
          "&scanner=inline"
      );
  }
  async function process(id) {
    if (!id) return;
    const scope = property?.id ?? property;
    const subject = policy?.subject ?? policy;

    const json = await fetchMedia(property, id);

    var item = get(json, "media.item");
    item = get(json, ["media", "items", item], item);
    if ("string" == typeof item) item = get(json, ["items", item], item);

    if (!item) return alert("Can't find this Smart Decal");

    // check scope/permits
    //if(get(record, "locations.item") !== scope) return alert("Smart Decal is valid for a different location")
    // not necessary, throws a 404

    //send to permit details or along to permit policy form

    // check it's policy
    var policies = get(json, "policies.items", {});
    each(policies, function (item, id) {
      if ("string" == typeof item)
        policies[item] = get(json, ["items", item], item);
    });
    var targetPolicy =
      get(policies, subject) || find(policies, { subject: subject });
    if (!!targetPolicy && !!targetPolicy.subject)
      return go(
        "/" +
          scope +
          "/permits/new?policy=" +
          targetPolicy.subject +
          "&media=" +
          item.key
      );
    if (!!subject && get(json, "permits.count", 0) <= 0)
      return go(
        "/" + scope + "/permits/new?policy=" + subject + "&media=" + item.key
      );

    return go("/" + scope + "/media/" + item.id);
  }
</script>

<section data-records="media/smartdecals/search">
  <h1>Lookup Smart&nbsp;Decal</h1>
  <MediaScanner on:media={onmedia} />
  <p>Scan Smart Decal barcode or enter the number:</p>
  <form novalidate data-ref="media/smartdecal" on:submit={onsubmit}>
    <input
      type="hidden"
      name="location"
      value={property?.id || property || ""}
    />
    <!-- <div data-role="group" data-record="media"></div> -->
    <label data-type="smart-decal" />
    <input
      class="media id"
      name="media"
      placeholder="Smart Decal number"
      type="text"
      pattern="[0-9]*"
      inputmode="numeric"
      autocapitalize="characters"
      autocorrect="off"
      autocomplete="off"
      spellcheck="false"
      maxlength="8"
      value=""
      required
    />
    <button type="submit">Continue</button>
  </form>
</section>
