<script>
  export let item;
  export let label = false;
</script>

{#if item.indoor === "garage"}
  <data class="indoor" value="garage"
    >{#if label}garage{/if}</data
  >
{:else if item.indoor === "yes"}
  <data class="indoor" value="yes"
    >{#if label}indoor{/if}</data
  >
{:else if item.covered === "carport" || item.parking === "carports"}
  <data class="covered" value="carport"
    >{#if label}carport{/if}</data
  >
{:else if item.covered === "yes"}
  <data class="covered" value="yes"
    >{#if label}covered{/if}</data
  >
{:else if item.outdoor === "yes"}
  <data class="outdoor" value="uncovered"
    >{#if label}uncovered{/if}</data
  >
{/if}
