<script>
    export let permit;
    $: fees = permit && permit.fees && permit.fees.items && Object.values(permit.fees.items);
    // $: console.log("fees=", fees);
    $: cancelled = permit && permit.cancelled;
</script>
{#if fees && fees.length}
        <ul class="fees">
            {#each fees as fee}
            <li>
                <h1>{fee.name}</h1>
                <data value="{fee.total.value}">{fee.total.display}</data>
                <ul>
                    {#each Object.values(fee.payments.items || []) as payment}
                    <li>
                        {#if payment.captured}
                        <data class="card" value="{payment.card.type}">{payment.card.last4}</data> <time datetime="{payment.captured.datetime}">Paid</time> 
                        {:else if cancelled}
                        <data class="card" value="{payment.card.type}">{payment.card.last4}</data> <time datetime="">Cancelled</time> 
                        {:else if payment.authorized && payment.capture && payment.capture.explicitly}
                        <data class="card" value="{payment.card.type}">{payment.card.last4}</data> <time datetime="{payment.authorized.datetime}">Pending</time>
                        {:else if payment.authorized}
                        <data class="card" value="{payment.card.type}">{payment.card.last4}</data> <time datetime="{payment.authorized.datetime}">Paid</time>
                        {:else}
                        <data class="card" value="{payment.card.type}">{payment.card.last4}</data> <time datetime="{payment.created}">Pending</time>
                        {/if}
                        </li>
                    {/each}
                </ul>
            </li>
            {/each}
        </ul>
    {/if}