<script>
  import { onMount, tick } from "svelte";
  import { get, each, reduce } from "lodash-es";
  import { Stripe } from "../util/stripe";
  import StripePayment from "./StripePayment.svelte";
  import PermitValid from "./PermitValid.svelte";
  import Contact from "./Contact.svelte";
  import { utcToZonedTime, format } from "date-fns-tz";
  import { route } from "../util/router";
  import { formDataToURLSearchParams } from "../util/form";

  //export let property;
  export let policy;
  export let form;

  export let vehicle;
  export let unit;
  export let space;
  export let media;

  export let notes;
  export let name;
  export let email;
  export let tel;

  let policyId;
  let item;

  const api = ParkIQ.API;

  //$: console.log("policy=", policy);

  // is there a race condition here?
  $: (async () => (item = await Promise.resolve(policy)))();

  let submitting = false;
  let submittable = false;

  let payment;
  let requestPayment;
  let feesElement;

  let paymentMethod;
  let paymentToken;

  $: hasPricing = policy && policy.pricing;
  $: paymentRequired = payment && payment.required;

  $: testValidateIssue = hasPricing && !payment;

  $: if (item && item.id != policyId) {
    //console.log("policychange=", policy);
    policyId = item.id;
    clearValues(); // clear on policy change
  }

  let values = {};
  function onValue(e) {
    //if(!e.target.name) return;
    // values = Object.assign(values, {
    //     [e.target.name]: e.target.value
    // });
    //values[e.target.name] = e.target.value;
    //values = values;
    //console.log("values=",values);

    submittable = isSubmittable(form, policy, payment, values);
  }

  function clearValues() {
    payment = null;
    paymentMethod = null;
    requestPayment = null;
    values = {};
    onValue();
  }

  function pricing(form, data) {
    payment = data && data.payment;
  }

  function arePolicyRequirementsMet(policy, values) {
    //console.log("checking policy requirements", form.checkValidity(), values);
    return true; // don't bother
    if (!policy) return false;
    if (get(policy, "vehicle.required", false) && !values.vehicle) return false;
    if (get(policy, "media.required", false) && !values.media) return false;
    if (get(policy, "space.required", false) && !values.space) return false;
    if (get(policy, "tenant.required", false) && !values.tenant && !values.unit)
      return false;
    if (get(policy, "authentication", false) && !values.token) return false;
    if (get(policy, "name.required", false) && !values.name) return false;
    if (get(policy, "email.required", false) && !values.email) return false;
    if (get(policy, "tel.required", false) && !values.tel) return false;
    return true;
  }

  async function onSubmit(e) {
    e.preventDefault();

    //console.log(this);

    if (!submittable) return;
    if (submitting) return;

    submitting = true;

    const form = e.target;

    if (paymentRequired && paymentMethod && requestPayment) {
      var paymentResult = await requestPayment();
      //console.log("paymentresult=", paymentResult);
      paymentToken =
        paymentResult && paymentResult.token && paymentResult.token.id;
      if (!paymentToken) {
        submitting = false;
        return; // done!
      }
      await tick();
    }

    var input;

    if (!!(input = form.querySelector("input[name='vehicle']:invalid"))) {
      alert("Please enter a License Plate");
      return;
    }

    if (!!(input = form.querySelector("input[name='tenant']:invalid"))) {
      alert("Please enter visiting");
      return;
    }

    if (!!(input = form.querySelector("input[name='reason']:invalid"))) {
      alert("Please enter reason");
      return;
    }

    if (!!(input = form.querySelector("input[name='name']:invalid"))) {
      alert("Please enter name");
      return;
    }

    if (
      !!(input = form.querySelector("input[type='email']:required:enabled")) &&
      !input.value
    ) {
      alert("Please enter a contact email");
      return;
    }

    if (
      !!(input = form.querySelector("input[type='tel']:required:enabled")) &&
      !input.value
    ) {
      alert("Please enter a contact phone number");
      return;
    }

    if (
      !!(input = form.querySelector("input[name='token']:required:enabled")) &&
      !input.value
    ) {
      alert("Please enter passcode");
      return;
    }

    if (!!form.checkValidity && !form.checkValidity()) {
      alert("Please check all fields");
      return;
    }

    var formData = new FormData(form);
    var searchParams = formDataToURLSearchParams(formData);

    var requested = new Date().toISOString();

    // lets resolve base just to be sure
    var min = Promise.delay(1 * 1000);
    return Promise.resolve(api.base())
      .then(function (base) {
        return (
          base +
          "v1/permits/temporary?viewpoint=" +
          requested +
          "&" +
          ((searchParams && searchParams.toString()) || "")
        );
      })
      .then(function (url) {
        return fetch(url, {
          method: "POST",
          body: formData,
        });
      })
      .then(api.response.process)
      .then(function (data) {
        // OK
        // have data from response

        // permit
        var permit = api.permit.set(data);
        if (!permit) return Promise.reject(new Error("No Permit Was Created"));

        // send
        // we can process while disabled
        var to = [];
        each(
          form.querySelectorAll("fieldset.contact select"),
          function (select) {
            //console.log(select);
            if (!select || !select.value) return; // nothing
            //console.log(select.value);
            each(select.value.split(","), function (name) {
              var input = form.querySelector("input[name='" + name + "']");
              //console.log(input);
              if (!input || !input.value) return;
              //console.log(input.value);
              to.push(input.value);
            });
          }
        );

        //form.reset();

        postal.publish({
          topic: "permit.created",
          data: {
            generated: data.generated,
            item: permit,
          },
        });

        // trigger but move on without waiting...
        //console.log(to);
        if (to.length > 0) {
          var sent = (function (formData) {
            var requested = new Date().toISOString();
            return Promise.resolve(api.base())
              .then(function (base) {
                return fetch(
                  base + "v1/send?viewpoint=" + requested + "&" + formData,
                  {
                    method: "POST",
                    //body: formData,
                  }
                );
              })
              .then(api.response.process);
          })(
            reduce(
              to,
              function (result, item) {
                return result + "&to=" + encodeURIComponent(item);
              },
              "container=" + permit.id
            ).replace(/%20/g, "+")
          );
        }

        //console.log("caching");
        //api.cache.set(permit.id, permit, data.generated); // set data
        return min
          .then(function () {
            return route("/p/" + permit.id);
          })
          .then(function () {
            // cleanup
            submitting = false;
            form.reset();
          });
        //return route("/p/" + permit.id);
      })
      .then(null, function (error) {
        // better catch errors inside
        //console.debug(error);
        var data = error.data;
        //var responseText = error.text;
        //console.log(data);
        var response = error.response;
        var status = error.status;
        //console.debug(response);
        //console.log(response.status);

        submitting = false;

        // unpaid violation
        if (error.message && data?.violations?.latest) {
          alert(error.message);
          return route(`/violations/${data.violations.latest}`);
        }

        // bad request
        if (status === 400) {
          alert(error.message);
          return null;
        }

        if (status === 422) {
          alert(error.message);
          return null;
        }

        // unauthorized - passcode
        if (status === 401) {
          alert("Whoops, incorrect passcode");
          return null;
        }

        // conflict - not enough time left
        if (status === 409 || status === 403) {
          if (!!data) {
            var permittable = !!data.permittables
              ? api.permittable.set(data)
              : null;

            // banned vehicle
            //if(!!permittable && !!permittable.vehicle) {
            if (!!permittable) {
              //api.cache.set(permittable.vehicle.id, permittable, data.generated); // set data
              return route("/banned/" + permittable.id); // need to switch to generic permittable?
            }

            // assigned permit(s)
            if (!!data.permits && !!data.permits.items) {
              alert(
                "Sorry, vehicles with assigned permits may not be registered"
              );
              return null;
            }

            // banned media

            if (!!data.interval) {
              // blocked interval
              alert("Sorry, registration failed: " + get(data, "message", ""));
              return null;
            }
          }

          // default status
          //alert("Sorry, unable to register this vehicle - it may not have enough time remaining or be banned from parking");
          //return null;
        }

        // forbidden - banned
        if (status === 403) {
          alert(
            error.message ||
              "Sorry, this vehicle is banned from registering. Please contact us for more information"
          );
          return null;
        }

        // conflict - no time
        if (status === 409) {
          alert("Sorry, there isn't enough parking time remaining");
          return null;
        }

        // payment required
        if (status === 402) {
          return pricing(form, data);
        }

        // issue test passed
        if (status === 204) {
          return pricing(form, data);
        }

        alert("Whoops, something went wrong while trying to register");
      });
  }

  $: if (form) {
    form.onsubmit = onSubmit;
    // form.oninput = onValue;
    // form.onchange = onValue;

    //form.removeEventListener("change", formChangeClearPrice);

    form.addEventListener("change", formChangeClearPrice);
    form.addEventListener("input", formChangeClearPrice);

    form.addEventListener("change", onValue);
    form.addEventListener("input", onValue);
    form.addEventListener("reset", clearValues);
  }

  function formChangeClearPrice(e) {
    //console.log("formChange", e);
    if (
      e &&
      e.target &&
      e.target.matches &&
      e.target.matches("form > fieldset.valid ~ * *")
    )
      return;
    // only consider valid or earlier in this
    // replace with something better?

    //var form = e && e.target && (e.target.form || (e.target.closest && e.target.closest("form")));
    //console.log("formChange", e, form);
    pricing();
  }

  onMount(function () {
    // wire up submit?
    // document.removeEventListener("submit", onSubmit);
    // document.addEventListener("submit", onSubmit);
    // watch form
  });

  function isSubmittable(form, policy, payment, values) {
    return (
      !!form &&
      (!form.checkValidity || !!form.checkValidity()) &&
      arePolicyRequirementsMet(policy, values) &&
      (!payment || !payment.required || !!paymentMethod)
    );
  }

  $: if (payment || paymentMethod) onValue();

  $: form && form.classList.toggle("submitting", submitting);

  $: if (feesElement) feesElement.scrollIntoView();

  let filesElement;

  $: if (filesElement) {
    filesElement.addEventListener("change", function (e) {
      if (!e.target.matches("input[type='file']")) return;

      var input = e.target;
      var img = input.parentNode.querySelector("img");
      if (input.files.length <= 0) {
        if (img) img.remove();
        return;
      }

      if (!img) img = document.createElement("img");

      img.src = URL.createObjectURL(input.files[0]);

      input.parentNode.appendChild(img);

      if (!!input.labels)
        _.each(input.labels, function (label) {
          label.classList.add("value");
        });
    });
  }
</script>

{#if policy}
  <PermitValid {policy} />
  <Contact {name} {email} {tel} {notes} {policy} />
  {#if policy}
    <fieldset class="files">
      {#if policy && policy.files && policy.files.items}
        <ul bind:this={filesElement}>
          {#each policy.files.items as item}
            <li>
              <label class:required={item.required} class="file"
                ><span>{item.title}</span><input
                  type="file"
                  name="files"
                  required={item.required}
                  accept={item.format}
                /><span /><img /></label
              >
            </li>
          {/each}
        </ul>
      {/if}
    </fieldset>
  {/if}
  <!-- <fieldset class="payment">
<h1>Payment required to park:</h1> -->
  <!-- <data class="price" value="<%= get(item, "fees.item.payments.balance.value", 0) %>"><%= get(item, "fees.item.payments.balance.display", "$0.00") %></data><input type="hidden" name="payment.description" value="<%= get(item, "fees.item.description") %>" /><input type="hidden" name="payment.amount" value="<%= get(item, "fees.item.payments.balance.value") %>" /> -->
  <!-- </fieldset> -->
  {#if payment}
    {#if payment.interval}
      <input name="valid" value={payment.interval} type="hidden" />
      <ul class="payment times">
        {#each payment.interval
          .split("/")
          .map( (v, i) => [i > 0, v, v && utcToZonedTime(new Date(v), policy.timezone)] ) as [isMax, utc, local]}
          <li>
            <h1>{isMax ? "End" : "Start"}</h1>
            <time datetime={utc ?? ""}
              >{(local && format(local, "EEE MMM d h:mm a")) ||
                "Until revoked"}</time
            >
          </li>
        {/each}
      </ul>
    {/if}
    <!-- <% [ get(item, "permits.item.valid.min"), get(item, "permits.item.valid.max") ].forEach(function(item, i) { %><time datetime="<%= item.utc %>"><%= dateFns.format(item.local, "ddd, MMM D h:mm A") %></time><% }); %> -->
    <ul class="payment fees" bind:this={feesElement}>
      {#each Object.values(payment.fees.items) as fee}
        <li>
          <h1>{fee.name}</h1>
          <data value={fee.total.value}>{fee.total.display}</data>
        </li>
      {:else}
        <li>
          <h1>Free</h1>
          <data value="0">$0.00</data>
        </li>
      {/each}
      <!-- <li>
            <h1>Total</h1>
            <data value="{payment.total.value}">{payment.total.display}</data>
        </li> -->
    </ul>
    {#if paymentRequired}
      <StripePayment
        bind:method={paymentMethod}
        {payment}
        bind:requestPayment
      />
      <!-- <p>Rules and cancellation policy</p> -->
    {/if}
    <p class="rules">
      By continuing you acknowledge the <a
        href="/{policy.scope.id || policy.scope}/rules"
        target="_blank">Parking Rules</a
      >
    </p>
  {/if}
  <fieldset class="control">
    {#if paymentRequired && paymentMethod && !paymentMethod.card}
      <input type="hidden" name="payment.source" value={paymentToken || ""} />
      <button
        type="submit"
        disabled={!submittable || submitting}
        class={Object.entries(paymentMethod || {})
          .filter(([k, v]) => v)
          .map(([k, v]) => k)
          .join(" ")}>Continue</button
      >
    {:else if paymentRequired}
      <!-- <data class="total" value="{payment.total.value}">{payment.total.display}</data> -->
      <input type="hidden" name="payment.source" value={paymentToken || ""} />
      <button
        type="submit"
        disabled={!submittable || submitting}
        class={Object.entries(paymentMethod || {})
          .filter(([k, v]) => v)
          .map(([k, v]) => k)
          .join(" ")}>Pay</button
      >
    {:else if testValidateIssue}
      <input type="hidden" name="issue" value="false" />
      <button
        type="submit"
        name="issue"
        value="false"
        disabled={!submittable || submitting}>Continue</button
      >
    {:else}
      <button type="submit" disabled={!submittable || submitting}
        >{policy.issue.title}</button
      >
    {/if}
  </fieldset>
{/if}
