<script>
  import { Stripe, canUseWallet } from "../util/stripe";

  export let payment;
  export let method = null;

  let enterCardInfo = false;
  let tryUseWallet = true;
  let walletInfo = null;
  let validEnteredCard = false;

  let cardContainer;

  let elements = Stripe.elements();
  let cardElement = elements.create("card");

  $: console.log("payment=", JSON.stringify(payment));

  let paymentRequest;
  $: canUsePaymentRequest = paymentRequest && paymentRequest.canMakePayment(); // is a promise
  // $: if(paymentRequest) {
  //     // changed
  //     canUsePaymentRequest = ;
  // }

  // monitor status and setup the payment request
  $: if (payment || !paymentRequest)
    paymentRequest = paymentRequestFor(payment); // init or update...

  function paymentRequestFor(payment) {
    console.log("paymentRequestFor", paymentRequest, payment);

    if (!paymentRequest) {
      paymentRequest = Stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: payment.payee || "Total",
          amount: Object.values(payment.fees.items || payment.fees).reduce(
            (sum, fee) => {
              if (fee.contingent) return sum;
              return sum + fee.total.value;
            },
            0
          ),
        },
        displayItems: Object.values(payment.fees.items || payment.fees).map(
          (fee) => ({
            label: fee.name,
            amount: fee.total.value,
          })
        ),
      });
    } else {
    }

    //paymentRequest.update
    console.log(paymentRequest);

    return paymentRequest;
  }

  $: if (cardContainer && cardElement) {
    cardElement.mount(cardContainer);
    cardElement.on("change", function (event) {
      validEnteredCard = event.complete;
    });
  }

  $: method = enterCardInfo
    ? enterCardInfo &&
      cardElement &&
      validEnteredCard && {
        card: true,
      }
    : tryUseWallet && walletInfo;

  $: console.log("method=", method);

  export async function requestPayment() {
    // prioritize entered card?
    console.log(
      "requestPaymentToken",
      tryUseWallet,
      paymentRequest,
      canUsePaymentRequest,
      await canUsePaymentRequest,
      validEnteredCard
    );

    if (enterCardInfo && cardElement && validEnteredCard) {
      return Stripe.createToken(cardElement).then((result) => {
        if (result.error) {
          alert("Card error");
          console.log("error", result.error);
        }
        return (result.token && result) || null;
      });
    }
    if (tryUseWallet && paymentRequest && !!(await canUsePaymentRequest)) {
      return new Promise(function (resolve, reject) {
        paymentRequest.on("token", function (event) {
          console.log("payment request token", event);

          resolve((event.token && event) || null);
          if (event.complete) event.complete("success");
          paymentRequest = null;
        });

        paymentRequest.on("cancel", function (event) {
          // user cancelled the payment request
          console.log("payment request cancelled");
          resolve((paymentRequest = null));
        });

        paymentRequest.show();
      });
    }

    // returns a promise...
    // payment has all the
  }

  // try to get wallet, offer enter card info
  // if no wallet, enter card info

  canUseWallet.then((result) => {
    walletInfo = result;
    tryUseWallet = !!result;
    if (!tryUseWallet) enterCardInfo = true;
  });
</script>

{#if tryUseWallet && !walletInfo}
  <fieldset class="payment-stripe loading">
    Checking payment options...
  </fieldset>
{:else}
  <fieldset class="payment-stripe">
    <p>
      How do you want to pay? <a href="https://stripe.com" target="_blank"
        >Secure</a
      >
    </p>
    <ul class="payment types">
      {#if tryUseWallet && walletInfo}
        <li>
          <input
            id="stripe-method-wallet"
            type="radio"
            bind:group={enterCardInfo}
            value={false}
          /><data
            class="payment wallet"
            value={Object.entries(walletInfo || {})
              .filter(([k, v]) => v)
              .map(([k, v]) => k)
              .join(" ")}
            >{walletInfo.applePay
              ? "Apple Pay"
              : walletInfo.googlePay
              ? "Google Pay"
              : "Saved payment info"}</data
          >
        </li>
        <li>
          <input
            id="stripe-method-card"
            type="radio"
            bind:group={enterCardInfo}
            value={true}
          /><data
            class="payment cards"
            value="visa mastercard americanexpress discover"
            >Enter new card</data
          >
        </li>
      {/if}
      {#if enterCardInfo}
        <li>
          <fieldset class="payment-card" bind:this={cardContainer} />
        </li>
      {/if}
    </ul>
  </fieldset>
{/if}
<!-- 
// {:else}
//     {#if tryUseWallet && walletInfo}
//     <p>Use {walletInfo.applePay ? "Apple Pay" : walletInfo.googlePay ? "Google Pay" : "saved card"}</p>
//     {/if}
//     {#if enterCardInfo || !tryUseWallet}
//     <fieldset class="payment-card" bind:this={cardContainer}></fieldset>
//     {/if}
// {/if} -->
