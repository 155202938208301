<script>
  export let url = false;
  export let item;
  function href(item) {
    return "";
  }
</script>

{#if url}
  <a href={true === url ? href(item) : url}><slot /></a>
{:else}
  <slot />
{/if}
