//import NewPermitValid from "./components/PermitValid.svelte";
import NewPermit from "./components/NewPermit.svelte";

(function (page, qs, api) {
  // var vehicleField = _.template('<% if(!!_.get(vehicle, "request") && !_.get(vehicle, "item")) { %><label class="vehicle id<%= vehicle.required ? " required" : "" %>"><span></span><input type="text" maxlength="10" autocorrect="off" autocapitalize="characters" spellcheck="false" name="vehicle" <%= vehicle.required ? "required" : "" %>  /></label><% } %>', {
  //     variable:"vehicle",
  // });

  let component = null;

  var _vehiclePre =
    '<% if(!!_.get(vehicle, "item") && !!_.get(vehicle, "request") && _.get(vehicle, "item") !== true) { %><dl><dt>Vehicle</dt><dd><data data-record="vehicle" value="<%= _.get(vehicle, "item") %>"><%= _.get(vehicle, "item") %></data><input type="hidden" name="vehicle" value="<%= _.get(vehicle, "item") %>" /></dd></dl><% } %>';
  var _vehicleSelect =
    '<% if(_.size(vehicle.items) > 0) { %><select tabindex="-1"><option value="">--</option><% _.each(_.get(vehicle, "items"), function(item) { %><option><%= item %></option><% }); %></select><% } %>';
  var _vehicleField =
    '<% if(!!_.get(vehicle, "request") && !_.get(vehicle, "item")) { %><label data-record="vehicle" class="vehicle id<%= !!_.get(vehicle, "required") ? " required" : "" %><%= _.size(vehicle.items) > 0 ? " datalist" : ""%>"><span></span><input type="text" maxlength="10" autocorrect="off" autocapitalize="characters" spellcheck="false" name="vehicle" list="<%= _.size(vehicle.items) > 0 ? "permit-vehicles" : "" %>" <%= !!_.get(vehicle, "required") ? "required" : "" %>  /><% if(_.size(vehicle.items) > 0) { %><datalist id="permit-vehicles"><% _.each(_.get(vehicle, "items", []), function(item) { %><option><%= item.toUpperCase() %></option><% }); %></datalist><% } %></label>' +
    _vehicleSelect +
    "<% } %>";

  var _vehicle = _.template(_vehiclePre + _vehicleField, {
    variable: "vehicle",
  });

  var _tenantPre =
    '<% if(!!_.get(tenant, "item") && !!_.get(tenant, "request") && _.get(tenant, "item") !== true) { %><dl><dt><%= _.get(tenant, "title") %></dt><dd><data data-record="tenant" data-type="<%= _.get(tenant, "format") %>" value="<%= _.get(tenant, "item") %>"><%=  _.get(tenant, "item") %></data><input type="hidden" name="tenant" value="<%= _.get(tenant, "item") %>" /></dd></dl><% } %>';

  var _tenantSelect =
    '<% if(tenant.predefined) { %><select tabindex="-1"><option value="">--</option><% _.each(_.get(tenant, "items"), function(item) { %><option><%= item %></option><% }); %></select><% } %>';

  var _tenantField =
    '<% if(!!_.get(tenant, "request") && !_.get(tenant, "item")) { %><label class="<%= tenant.type %> tenant id<%= tenant.required ? " required" : ""%><%= tenant.predefined ? " constrained" : ""%>" data-record="tenant" data-type="<%= tenant.type %>"><span></span><input type="text" name="tenant" autocorrect="off" autocapitalize="characters" spellcheck="false" value="" maxlength="<%= tenant.predefined ? "30" : "8" %>" <%= tenant.required ? "required" : ""%> list="<%= tenant.predefined ? "permit-tenants" : "" %>" /><% if(tenant.predefined) { %><datalist id="permit-tenants"><% _.each(_.get(tenant, "items", []), function(item) { %><option><%= item.toUpperCase() %></option><% }); %></datalist><% } %></label>' +
    _tenantSelect +
    "<% } %>";

  var _tenant = _.template(_tenantPre + _tenantField, {
    variable: "tenant",
  });

  var _authPre =
    '<% if(!!_.get(auth, "auth") && !_.get(auth, "expired")) { %><figure class="preauth"><figcaption>Preauthorized <time datetime="<%= _.get(auth, "expires") %>"> until <%= dateFns.format(auth.expires, "ddd MMM D, YYYY h:mm A") %></time></figcaption><dl><dt><%= _.get(auth, "title") %></dt><dd><data data-record="tenant" data-type="<%= _.get(auth, "type") %>" value="<%= _.get(auth, "name") %>"><%=  _.get(auth, "name") %></data><!--<input type="hidden" name="tenant" value="<%= _.get(auth, "name") %>" />--></dd><dt>Passcode</dt><dd><data data-record="auth" value="<%= _.get(auth, "auth") %>">••••</data><input type="hidden" name="Authorization" value="<%= _.get(auth, "auth") %>" /></dd></dl></figure><% } %>';
  // if auth required and either none preset or expired
  var _authField =
    '<% if(!!_.get(auth, "required") && (!_.get(auth, "auth") || !!_.get(auth, "expired"))) { %><label class="password auth id required" data-record="auth"><span></span><input type="text" pattern="[0-9]*" inputmode="numeric" autocorrect="off" autocomplete="off" spellcheck="false" name="token" required /></label><% } %>';

  var _preauth = _.template(_authPre, {
    variable: "auth",
    imports: {
      dateFns: dateFns,
    },
  });

  var _auth = _.template(_authField, {
    variable: "auth",
    imports: {
      dateFns: dateFns,
    },
  });

  var _mediaField =
    '<% if(!!_.get(media, "item") && !!_.get(media, "request") && _.get(media, "item") !== true) { %><dl><dt>Smart Decal</dt><dd><data data-record="media" data-type="<%= _.get(media, "format") %>" value="<%= _.get(media, "item") %>"><%=  _.get(media, "item", "").toUpperCase() %></data><input type="hidden" name="media" value="<%= _.get(media, "item") %>" /></dd></dl><% } %>';

  var mediaField = _.template(
    _mediaField +
      '<% if(!!_.get(media, "request") && !_.get(media, "item")) { %><label class="media id<%= media.required ? " required" : ""%> <%= media.format || "" %>"><span></span><input type="text" name="media" autocorrect="off" autocapitalize="characters" spellcheck="false" value="" maxlength="10" <%= media.required ? "required" : ""%> /></label><% } %>',
    {
      variable: "media",
    }
  );

  var _spacePre =
    '<% if(!!_.get(item, "item") && !!_.get(item, "request") && _.get(item, "item") !== true) { %><dl><dt>Parking In</dt><dd><data data-record="space" value="<%= _.get(item, "item.id", item.item) %>"><%=  _.get(item, "item.display", item.item).toUpperCase() %></data><input type="hidden" name="space" value="<%= _.get(item, "item.id", item.item) %>" /></dd></dl><% } %>';

  var _spaceSelect =
    '<% if(item.predefined) { %><select tabindex="-1"><option value="">--</option><% _.each(_.get(item, "items", []), function(item) { %><option><%= item.toUpperCase() %></option><% }); %></select><% } %>';

  var _spaceField =
    '<% if(!!item && !!item.request && !item.item) { %><label class="space id<%= item.required ? " required" : ""%><%= item.predefined ? " constrained" : ""%>"><span>Parking in</span><input type="text" name="space" list="<%= item.predefined ? "permit-spaces" : "" %>" autocorrect="off" autocapitalize="characters" spellcheck="false" value="" maxlength="<%= item.predefined ? "30" : "8" %>" <%= item.required ? "required" : ""%> /><% if(item.predefined) { %><datalist id="permit-spaces"><% _.each(_.get(item, "items", []), function(item) { %><option><%= item.toUpperCase() %></option><% }); %></datalist><% } %></label>' +
    _spaceSelect +
    "<% } %>";

  var spaceField = _.template(_spacePre + _spaceField, {
    variable: "item",
  });

  var reasonField = _.template(
    '<% if(!!reason && !!reason.request) { %><label class="reason visiting id description required<%= reason.required ? " required" : ""%>"><span></span><input type="text" name="reason" autocorrect="off" spellcheck="false" value="" maxlength="20" <%= reason.required ? "required" : ""%> /></label><% } %>',
    {
      variable: "reason",
    }
  );

  var notesField = _.template(
    '<% if(!!_.get(field, "request", false)) { %><label class="notes<%= !!_.get(field, "required", false) ? " required" : "" %>"><span><%= _.get(field, "label", "") %></span><input type="text" name="notes" <%= !!_.get(field, "required", false) ? "required" : "" %> value="" spellcheck="false" /></label><% } %>',
    {
      variable: "field",
    }
  );

  var nameField = _.template(
    '<% if(!!name.request) { %><label class="name<%= name.required ? " required" : "" %>"><span>Contact name</span><input type="text" name="name" <%= name.required ? "required" : "" %> value="" spellcheck="false" autocomplete="name" /></label><% } %>'
  );
  var emailField = _.template(
    '<% if(!!email.request) { %><label class="email<%= email.required ? " required" : "" %>"><span>Contact email</span><input type="email" name="email" <%= email.required ? "required" : "" %> value="" spellcheck="false" autocomplete="email" /></label><% } %>'
  );
  var telField = _.template(
    '<% if(!!tel.request) { %><label class="phone tel<%= tel.required ? " required" : "" %>"><span>Contact phone</span><input type="tel" name="tel" <%= tel.required ? "required" : "" %> value="" spellcheck="false" autocomplete="tel" /></label><% } %>'
  );

  var filesFields = _.template(
    '<ul><% _.each(files, function(item) { %><li><label class="file <%= item.required ? " required" : "" %>"><span><%= item.title %></span><input type="file" name="files" <%= item.required ? "required" : "" %> accept="<%= item.format %>" /><span></span><img></label></li><% }); %></ul>',
    {
      variable: "files",
    }
  );

  var sendptions = _.template(
    '<% if(!!_.get(email, "send", false) || !!_.get(tel, "send", false)) { %><label class="notification value"><span></span><select><% if(!!_.get(email, "send", false) && !!_.get(tel, "send", false)) { %><option value="email,tel" title="Email & text confirmation">Email & text confirmation</option><% } if(!!_.get(email, "send", false)) { %><option value="email" title="Email confirmation only">Email confirmation only</option><% } if(!!_.get(tel, "send", false)) { %><option value="tel" title="Text confirmation only">Text confirmation only</option><% } %><option value="" title="Don\'t send confirmation">Don\'t send confirmation</option></select><span></span></label><% } %>'
  );

  var buttonsHtml = _.template(
    '<% var payment = !!_.get(item, "payments.enabled") || !!_.get(item, "pricing.enabled"); %><input type="hidden" name="issue" value="true" /><% if(payment) { %><button type="submit" name="issue" value="false">Continue</button><% } else { %><p class="rules">By continuing you acknowledge the <a href="/<%= _.get(item, "location.id", item.location) %>/rules" target="_blank">Parking Rules</a></p><button type="submit" name="issue" value="true"><%= _.get(item, "issue.title") %></button><% } %>',
    {
      variable: "item",
    }
  );

  // var paymentHtml = _.template('<p>Secure payments powered by <a href="https://stripe.com/docs/security/stripe" target="_blank">Stripe</a></p>', {
  //     variable:"item",
  // });

  var view = function (
    policy,
    vehicle,
    tenant,
    media,
    space,
    auth,
    notes,
    name,
    email,
    tel
  ) {
    //console.log(attendant, vehicle);

    var form = document.querySelector("form[data-create='permit']");
    if (!form) return;

    form.reset();
    form.setAttribute("novalidate", "novalidate");
    form.classList.remove("ready");
    form.classList.remove("disabled");
    _.each(form.querySelectorAll("h1.disabled"), function (h1) {
      h1.remove();
    });

    if (!policy) {
      return;
    }

    _.each(form.querySelectorAll("header:first-child"), function (elem) {
      var title = _.get(policy, "title", "");
      var action = _.get(policy, "issue.title");
      //if(title.length < 16) title = title.replace(" ", "&nbsp;")
      var titleText = _.filter([
        //_.get(policy, "issue.title"),
        title,
      ]).join(" for ");
      var titleHtml = _.filter([
        //_.get(policy, "issue.title"),
        title.replace(" ", "&nbsp;"),
      ]).join(" for ");

      elem.innerHTML =
        "<h1 title=" +
        titleText +
        ">" +
        titleHtml +
        "</h1><p>" +
        action +
        " with the following info:</p>";
      // if(!!title) {
      //     elem.setAttribute("title", titleText);
      //     elem.innerHTML = titleHtml;
      // } else {
      //     elem.removeAttribute("title");
      //     elem.innerHTML = "";
      // }
    });

    var disabled =
      _.get(policy, "disabled.reason") ||
      _.get(policy, "issue.disabled.title") ||
      _.get(policy, "issue.disabled.reason");

    if (!!disabled) {
      _.each(form.querySelectorAll("fieldset"), function (fieldset) {
        fieldset.innerHTML = "";
      });

      form.classList.add("disabled");
      _.invoke(form.querySelector("aside.disabled"), "remove");
      form.insertAdjacentHTML(
        "afterbegin",
        '<aside class="disabled"><h1>' + disabled + "</h1></aside>"
      );
    } else {
      form.querySelector("fieldset.id").innerHTML = !!policy
        ? '<input name="location" type="hidden" value="' +
          (policy.location.id || policy.location) +
          '" /><input name="policy" type="hidden" value="' +
          policy.subject +
          '" />'
        : "";

      // only do separate media if required
      _.set(
        form.querySelector("fieldset.media"),
        "innerHTML",
        _.get(policy, "media.required", false)
          ? mediaField(
              _.defaults(_.clone(_.get(policy, "media")), { item: media })
            )
          : ""
      );

      // only do additional media if optional
      form.querySelector("fieldset.vehicle").innerHTML =
        _vehicle(
          _.defaults(_.clone(_.get(policy, "vehicle")), { item: vehicle })
        ) +
        (!_.get(policy, "media.required", false) &&
        _.get(policy, "media.request", false)
          ? mediaField(
              _.defaults(_.clone(_.get(policy, "media")), { item: media })
            )
          : "");

      // parse pre-auth
      var jwt = null;
      var secure = null;
      if (!!auth) {
        //auth = auth.replace(/[*]/g, "."); // fix period escaping
        try {
          jwt = jwt_decode(_.get(_.invoke(auth, "split", " "), "[1]"));
        } catch (error) {
          jwt = null;
        }
      }

      if (!!jwt) {
        secure = {
          required: !!_.get(policy, "authentication"),
          id: jwt.sub,
          title: _.get(policy, "tenant.title"),
          type: _.get(policy, "tenant.type"),
          name: jwt.name || jwt.unique_name,
          expires: dateFns.format(jwt.exp * 1000),
          expired: dateFns.isPast(jwt.exp * 1000),
          valid:
            dateFns.format((jwt.nbf || jwt.iat) * 1000) +
            "/" +
            dateFns.format(jwt.exp * 1000),
          auth: auth,
          jwt: jwt,
          subject: _.get(policy, "title", ""),
          href: window.location,
        };
        //console.log("result", result);
      }

      if (!!secure && !secure.expired && !tenant) tenant = secure.name; // preset tenant for now...
      //if(!!secure && !!secure.expired && !tenant) tenant = secure.name;
      //if(!!secure && !secure.expired) tenant = true;

      form.querySelector("fieldset.tenant").innerHTML = _tenant(
        _.defaults(_.clone(_.get(policy, "tenant")), { item: tenant })
      );

      _.set(
        form.querySelector("fieldset.preauth"),
        "innerHTML",
        _preauth(secure || { required: _.get(policy, "authentication") })
      );

      _.set(
        form.querySelector("fieldset.auth"),
        "innerHTML",
        _auth(secure || { required: _.get(policy, "authentication") })
      );

      //console.log(auth, decoded);
      _.set(
        form.querySelector("fieldset.visiting"),
        "innerHTML",
        !!policy
          ? spaceField(
              _.defaults(_.clone(_.get(policy, "space")), { item: space })
            ) + reasonField(policy.reason || {})
          : ""
      );

      //console.log(policy.space);
      // var validFieldset = form.querySelector("fieldset.valid");
      // validFieldset.innerHTML = "";
      // if(policy.validity && policy.validity.items) {
      //     validComponent = new NewPermitValid({
      //         target: validFieldset,
      //         props: {
      //             valid: policy.validity,
      //             container: validFieldset
      //         }
      //     });
      // }
      // else if(policy.valid && policy.valid.items) {
      //     validComponent = new NewPermitValid({
      //         target: validFieldset,
      //         props: {
      //             valid: policy.valid,
      //             container: validFieldset
      //         }
      //     });
      // } else if(!!policy.start || !!policy.duration) DateTimeDuration(validFieldset, policy);
      //else form.querySelector("fieldset.valid").innerHTML = "";

      // form.querySelector("fieldset.contact").innerHTML = !!policy ? (notesField(policy.notes) + nameField({ name: policy.name || {}, }) + emailField({ email: policy.email || {}, }) + telField({ tel: policy.tel || {}, })) + (!!_.get(policy, "send.enabled") ? sendptions({
      //     email: policy.email || {},
      //     tel: policy.tel || {},
      // }) : "") : "";

      //form.querySelector("fieldset.files").innerHTML = !!policy ? (filesFields(_.get(policy, "files.items", []))) : "";

      // _.each(form.querySelectorAll("fieldset.contact select"), function(select) {
      //     select.dispatchEvent(new CustomEvent("change", { "bubbles": true }));
      // });

      // form.querySelector("fieldset.payment").innerHTML = "";
      // //form.querySelector("fieldset.payment").innerHTML = paymentHtml(attendant);

      // form.querySelector("fieldset.control").innerHTML = buttonsHtml(policy);

      component =
        component ||
        new NewPermit({
          target: form,
          props: {
            form: form,
            policy: undefined,
            vehicle: undefined,
            unit: undefined,
            media: undefined,
            space: undefined,
            name: undefined,
            tel: undefined,
            email: undefined,
            notes: undefined,
          },
        });
      component.$set({
        policy: policy,
        name: name,
        tel: tel,
        email: email,
        notes: notes,
      });

      // if(component) {
      //     // update
      //     console.log("updating existing component");
      //     component.$set({
      //         policy:policy
      //     });
      // } else {
      //     component = new NewPermit({
      //         target: form,
      //         props: {
      //             form: form,
      //             policy: policy
      //         }
      //     });
      // }

      // _.each(form.querySelectorAll("input[type='tel'][name]"), function(input) {
      //     new Formatter(input, {
      //         "pattern": "({{999}}) {{999}}-{{9999}}"
      //     });
      // });
    }

    form.classList.add("ready");

    //document.title = _.filter([ attendant.title , _.get(attendant, "address.name") ]).join(" - ");
  };

  var _init = _.once(function (ctx) {
    // not sure what we do here...
  });

  var setup = function (ctx, next) {
    _init(ctx);
    next();
  };

  var root = document.documentElement;

  function init(permitId, policyId, params, jwt) {
    // load permit
    // load policy, override policy
    // augment/override w params
    // jwt wins
  }

  var show = function (ctx, next) {
    root.setAttribute("data-records", "permits/new");

    // reset

    var id = ctx.query.policy;

    var attendant = api.attendant
      .get(ctx.params.location)
      .then(function (attendant) {
        //console.log("attendant.id", attendant.id);
        //console.log("attendant", attendant);

        if (!id) id = _.get(attendant, "attendants.item");

        var policy =
          _.get(attendant, ["issuers", "items", id]) ||
          _.find(attendant.issuers.items, { subject: id });

        console.log("policy", "newpermit", id, policy);

        if (!policy || !policy.id) {
          alert("whoops, something went wrong!");
          return;
        }

        if (!!policy && !!policy.id) {
          //var title = _.filter([ policy.title , _.get(attendant, "address.name") ]).join(" - ");
          //document.title = title;
          //if(!!attendant.id && id !== attendant.id && !!window.history && !!history.replaceState) history.replaceState({} , title, ctx.pathname.replace("/" + id + "/", "/" + attendant.id + "/"));
        }
        var query = new URLSearchParams(window.location.search); // has better url processing rules
        var tenant =
          query.get("tenant") ||
          _.get(ctx, "query.tenant") ||
          _.get(ctx, "params.tenant");
        var vehicle =
          query.get("vehicle") ||
          _.get(ctx, "query.vehicle") ||
          _.get(ctx, "params.vehicle");
        var space =
          query.get("space") ||
          _.get(ctx, "query.space") ||
          _.get(ctx, "params.space");

        // try and get the space from the policy metadata
        if (space)
          space =
            policy.spaces?.items?.[space] || policy.spaces?.[space] || space;

        var media =
          query.get("media") ||
          _.get(ctx, "query.media") ||
          _.get(ctx, "params.media");
        var name =
          query.get("name") ||
          _.get(ctx, "query.name") ||
          _.get(ctx, "params.name");
        var notes =
          query.get("notes") ||
          _.get(ctx, "query.notes") ||
          _.get(ctx, "params.notes");
        var email =
          query.get("email") ||
          _.get(ctx, "query.email") ||
          _.get(ctx, "params.email");
        var tel =
          query.get("tel") ||
          _.get(ctx, "query.tel") ||
          _.get(ctx, "params.tel");

        view(
          policy,
          vehicle,
          tenant,
          media,
          space,
          _.get(ctx, "query.Authorization") ||
            _.get(qs.parse(ctx.hash), "Authorization") ||
            _.get(ctx, "params.Authorization"),
          notes,
          name,
          email,
          tel
        );

        document.documentElement.dispatchEvent(
          new CustomEvent("ready", { bubbles: true })
        );

        return attendant;
      });

    //document.querySelector("form.permit.new").setAttribute("novalidate", "novalidate");
    //next();

    return attendant;
  };

  var exit = function (ctx, next) {
    view();
    next();
  };

  _.each(
    [
      //"/l/:location",
      "/:location/permits/temporary/new",
      "/:location/permits/new",
      // "/"
    ],
    function (path) {
      page(path, show);
      page.exit(path, exit);
    }
  );
})(window.page, window.qs || window.Qs, ParkIQ.API);

export default window;
