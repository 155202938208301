<script>
    import { toMilliseconds } from "duration-fns";
    //export let valid;
    //export let container;
    export let policy;

    let select;
    let container;

    $: if(select) select.dispatchEvent(new CustomEvent("change", { "bubbles": true }));

    $: valid = policy && (policy.validity || policy.valid);
    $: items = valid && valid.items && Object.entries(valid.items);
    $: durations = policy && policy.valid && policy.valid.duration && policy.valid.duration.items && Object.entries(policy.valid.duration.items || {});
    $: console.log({durations});
    $: if(policy && !items && container) DateTimeDuration(container, policy);
</script>
{#if policy && items}
<fieldset class="valid">
<label for="new-permit-valid">
<select id="new-permit-valid" bind:this={select} name="valid" readonly={items.length < 2} disabled={!items.length}>
    {#each items as [ interval, label ]}
    <option value="{interval}">{label}</option>
    {:else}
    <option value="">not currently available</option>
    {/each}
</select>
</label>
</fieldset>
{:else if policy && !items && durations && durations.length}
<fieldset class="valid">
<label for="new-permit-valid-duration">
<select id="new-permit-valid-duartion" bind:this={select} name="duration" readonly={durations.length < 2}>
    {#each durations.sort(([a], [b]) => toMilliseconds(a) - toMilliseconds(b)) as [ interval, label ]}
    <option value="{interval}">{label}</option>
    {/each}
</select>
</label>
</fieldset>
{:else if policy && !items && !durations?.length && (!!policy.start || !!policy.duration)}
<fieldset class="valid" bind:this={container}>
</fieldset>
{/if}