(function(page, api, root) {


    var go = function(location) {
        if(!location) return null;
        return window.location.href = "https://parkingboss.com/" + location;
    };

    function show(ctx) {
        return go(ctx.params.location);
    };

    function resolve(ctx) {
        return go(ctx.params.location || api.LocationAlias.fromHost(window.location.hostname || window.location.host))
    }

    _.each({
        "/resident":resolve,
        "/tenant":resolve,
        "/:location/resident":resolve,
        "/:location/activate":resolve,
        "/activate":resolve,
    }, function(handler, path) {
        //console.log(path, handler);
        page(path, handler);
    });


}(page, ParkIQ.API, document.documentElement));

export default window;