

(function(postal, root) {

	document.title = "Loading...";
	
	var last = null;
	
	// postal.subscribe({
	// 	topic:"tenants.items",
	// 	callback:function(data, envelope) {

	// 		//console.log("tenants", data);
			
	// 		var type = _.get(data, "type");
	// 		if(!!type) _.each(root.querySelectorAll("label.tenant.id"), function(elem) {
    //             elem.setAttribute("data-type", _.get(data, "type"));
    //         });
	// 	},
	// });

    postal.subscribe({
		topic:"location.item",
		callback:function(data, envelope) {
			
			_.each(root.querySelectorAll("input[name='location']"), function(elem) {
                elem.setAttribute("value", elem.vlaue = _.get(data, "item.id"));
            });
		},
	});

})(postal, document.documentElement);

export default window;