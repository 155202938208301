<script context="module">
  import { identity, upperFirst } from "lodash-es";
  import { parseISO } from "date-fns";
  import { format, utcToZonedTime } from "date-fns-tz";
</script>

<script>
  import MediaPlacement from "./MediaPlacement.svelte";
  import RecordItem from "./RecordItem.svelte";

  export let details = null;
  $: console.log("details=", details);
  $: active =
    details?.permits?.items &&
    Object.values(details.permits.items).filter((p) => !p.valid.expired);
</script>

{#if details}
  <header>
    <h1>{details.item.title}</h1>
    <time
      datetime={details.generated}
      class="status"
      class:active={details.status.valid}
      class:revoked={details.status.revoked}>{details.status.title}</time
    >
  </header>

  <dl class="subjects">
    {#each [details.item].filter(identity) as record}
      <dt>{record.title || record.format || record.type}</dt>
      <dd><RecordItem item={record} /></dd>
    {/each}
  </dl>
  <!-- <dl class="subjects">
    <dt>Status</dt><dd></dd>
</dl> -->
  <!-- {#each Object.values(details.valid || []) as permit}
    <dl class="subjects">
      {#each [permit.vehicle].filter(identity) as record}
        <dt>{record.title || record.format || record.type || "vehicle"}</dt>
        <dd>
          <data
            class={[
              "id",
              record._type || record.type || "vehicle",
              record.format,
            ]
              .filter(identity)
              .join(" ")}>{record.display || record}</data
          >
        </dd>
      {/each}
      {#each [permit.space].filter(identity) as record}
        <dt>Parking In</dt>
        <dd>
          <data
            class={["id", record._type || record.type || "space", record.format]
              .filter(identity)
              .join(" ")}>{record.display || record}</data
          >
        </dd>
      {/each}
    </dl>
  {/each} -->
  {#each Object.values(active ?? []) as permit}
    <dl class="subjects">
      {#if permit.vehicle}
        <dt>Vehicle</dt>
        <dd><RecordItem item={permit.vehicle} /></dd>
      {/if}
      {#if permit.space}
        <dt>parked in</dt>
        <dd><RecordItem item={permit.space} /></dd>
      {/if}
    </dl>
  {/each}
  <dl class="subjects">
    <dt>Synced</dt>
    <dd>
      <time datetime={details.generated}
        >{format(parseISO(details.generated), "MMM d yyyy h:mm a zzz")}</time
      >
    </dd>
  </dl>
  <nav>
    {#each Object.values(details.policies || []).filter((policy) => policy.issue.enabled) as policy}
      <a
        class="permit"
        href="/{policy.scope}/permits/new?policy={policy.subject}&media={details
          .item.key}">{upperFirst(policy.issue.title)} for {policy.title}</a
      >
    {/each}
    <a class="rules" href="/{details.property.id}/rules">Parking Rules</a>
  </nav>
  <MediaPlacement placement={details.property.media.placement} />
  <aside class="instructions">
    {#each ["Smart Decal must be active and valid for the vehicle and location. Use of an inactive, revoked, or invalid Smart Decal may result in citation, booting, or towing at owner's expense."].filter(identity) as text}
      <p>{text}</p>
    {/each}
  </aside>
{/if}
