import { qs } from "@parkingboss/utils";
import { params } from "./params";

// bootstrap the previously loaded page
export const router = self.page;

export default router;

// qs mechanics
router("*", function(ctx, next) {

    // don't run auth, we're running out of band

    ctx.query = Object.assign(qs.parse(ctx.querystring), qs.parse(window.location.hash.substr(1)));
    next();
});

export function state(ctx, next) {
    params.set(Object.assign({}, ctx.params, ctx.query, qs.parse(location.hash && location.hash.substring(1))));
    if(!!next) next();
}

export function route(url) {
    router(url);
}

export function end(ctx) {
    state(ctx);
}

export function query(key, value, path) {
    const qs = new URLSearchParams(window.location.search);

    if(typeof key == "object") {
        for(const [ k, v ] of Object.entries(key)) {
            if(v) qs.set(k, v);
            else qs.delete(k);
        }
    } else {
        if(value) qs.set(key, value);
        else qs.delete(key);
    }

    
    let q = qs.toString();
    if(q) q = "?" + q;
    route((path || window.location.pathname)+q)
}