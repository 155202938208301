import { thumbnail } from "../util/filepreview";

// update address
(function () {
  var last = null;
  var _addressTemplate = _.template(
    '<aside><h1 class="org n"><%= address.name %></h1><p class="adr"><% if(!!address.street) { %><span class="street-address"><%= address.street %></span> <% } %><span class="locality"><%= address.city %></span> <span class="region"><%= address.state %></span><% if(!!address.postal) { %> <span class="postal-code"><%= address.postal %></span><% } %></p><% if(!!address.map.url) { %><a class="map directions" rel="map" target="_blank" href="<%= address.map.url %>"></a><% } %></aside>'
  );

  postal.subscribe({
    topic: "address.item",
    callback: function (data, envelope) {
      //var ts = new Date(data.generated || data.ts);

      //if(!!last && last.getTime() >= ts.getTime()) return; // this isn't newer

      var address = data.item;
      //console.debug(address);

      var ts = new Date(address.updated);

      if (!!last && last.getTime() >= ts.getTime()) return;

      var container = document.querySelector("header.address.vcard");
      container.innerHTML = _addressTemplate({ address: address });

      //var footer = document.querySelector("body > footer");

      _.set(
        document.querySelector("title"),
        "textContent",
        (document.title = "Parking at " + address.name)
      );

      _.each(document.querySelectorAll("main > footer img"), function (img) {
        if (!!address.logo)
          img.setAttribute("src", thumbnail(address.logo.url, 400));
        else if (address.logo === false) img.removeAttribute("src");
      });

      var figure = document.createElement("figure");
      //map.classList.add("map");
      container.appendChild(figure);
      //map.innerHTML = '<span></span>';

      var width = Math.min(figure.offsetWidth, 1280);
      var sm = width < 980;

      if (sm && !!address.photo) {
        figure.classList.remove("map");
        if (!!address.photo)
          figure.style.backgroundImage =
            "url(" + thumbnail(address.photo.url) + ")";
      } else {
        figure.classList.remove("map");

        var height = Math.min(figure.offsetHeight, 1280);
        //var width = Math.min(figure.offsetWidth, 1280);

        var center = "";
        if (address.latitude != 0 && address.longitude != 0)
          center = address.latitude + "," + address.longitude;
        else
          center =
            address.street +
            ", " +
            address.city +
            ", " +
            address.state +
            " " +
            address.postal;

        var marker = sm
          ? "pin-s%28" + address.longitude + "," + address.latitude + "%29/"
          : "";

        var url = [
          "https://api.mapbox.com/styles/v1/mapbox/navigation-guidance-day-v4/static/",
          "geojson(",
          encodeURIComponent(
            JSON.stringify({
              type: "Feature",
              geometry: address.area,
              properties: {
                //     "stroke": "%23222",
                //     "stroke-opacity": 0.5,
                //     "stroke-width": 2,
                //     "fill": "%23222",
                //     "fill-opacity": 0.05
              },
            })
          ),
          ")/",
          //marker,
          address.longitude,
          ",",
          address.latitude,
          ",14,0,0/",
          width,
          "x",
          height,
          window.devicePixelRatio > 1.5 ? "@2x" : "",
          "?logo=false&attribution=false&access_token=pk.eyJ1IjoibGVtZXN1cmllciIsImEiOiJWcEhvYVNjIn0.aSEzIDJPY_ujnCkPDgS9Aw",
        ].join("");

        //console.log(url);

        //https://api.mapbox.com/styles/v1/mapbox/navigation-guidance-day-v4/static/geojson(%7B%22type%22%3A%22Feature%22%2C%22geometry%22%3A%7B%22type%22%3A%22Polygon%22%2C%22coordinates%22%3A%5B%5B%5B-122.19181097934953%2C47.97911950548507%5D%2C%5B-122.19153226543104%2C47.97911330079976%5D%2C%5B-122.19151935932585%2C47.979460355506504%5D%2C%5B-122.19129116506369%2C47.97945883833764%5D%2C%5B-122.19128188600389%2C47.97958763360238%5D%2C%5B-122.1910719557691%2C47.97959773604371%5D%2C%5B-122.19107268600413%2C47.97970080004902%5D%2C%5B-122.19116802094021%2C47.97970253946794%5D%2C%5B-122.19142120929493%2C47.979708789472056%5D%2C%5B-122.1917122395299%2C47.97971820586596%5D%2C%5B-122.19172096976497%2C47.97965353442851%5D%2C%5B-122.1922488%2C47.9796626%5D%2C%5B-122.1922649%2C47.9795809%5D%2C%5B-122.1922583814103%2C47.97946826640421%5D%2C%5B-122.1918062%2C47.979458%5D%2C%5B-122.19181097934953%2C47.97911950548507%5D%5D%5D%7D%2C%22properties%22%3A%7B%22stroke%22%3A%22%2523222%22%2C%22stroke-opacity%22%3A0.5%2C%22stroke-width%22%3A2%2C%22fill%22%3A%22%2523222%22%2C%22fill-opacity%22%3A0.05%7D%7D)/-122.19165,47.97927,12,0,0/1280x971@2x?logo=false&attribution=false&access_token=pk.eyJ1IjoibGVtZXN1cmllciIsImEiOiJWcEhvYVNjIn0.aSEzIDJPY_ujnCkPDgS9Aw

        //figure.style.backgroundImage = "url(" + url + ")";
        figure.setAttribute("style", "background-image:url('" + url + "');");
      }

      _.each(
        document.querySelectorAll("aside[data-record='instructions']"),
        function (container) {
          container.innerHTML = !!address.instructions
            ? "<p>" +
              address.instructions +
              (/[0-9a-z]$/gi.test(address.instructions) ? "." : "") +
              "</p>"
            : "";
        }
      );

      _.each(
        document.querySelectorAll("aside[data-record='disclaimer']"),
        function (container) {
          container.innerHTML = !!address.disclaimer
            ? "<p>" +
              address.disclaimer +
              (/[0-9a-z]$/gi.test(address.disclaimer) ? "." : "") +
              "</p>"
            : "";
        }
      );

      /*var img = document.createElement("img");
            img.addEventListener("load", function(e) {
                map.style.backgroundImage = "url(" + url + ")";
                window.setTimeout(function() {
                    map.classList.add("loaded");
                }, 100);
            });
            img.setAttribute("src", url);
            if(!!img.complete) img.dispatchEvent(new CustomEvent("load", { "bubbles": true }));*/

      last = ts;
    },
  });
})();

export default window;
