(function(api) {
    _.extend(api.Permits, {
        list:Promise.method(function(id) {

                
            var requested = new Date().toISOString();
            return Promise.resolve(api.base())
            .then(function(base) {
                return fetch(base + "v1/locations/" + id + "/permits/vehicles/temporary?viewpoint=" + dateFns.format(requested));
            })
            // .then(function(url) {
            //     return fetch(url);
            // })
            .then(api.response.process)
            .then(function(json) {

                json.requested = requested;
                
                var location = api.Items.get(json, "locations");
                // var attendant = api.Items.get(json, "attendants");
                // if(!!attendant) attendant.location = _.get(json, [ "locations", "items", attendant.location ], attendant.location);


                var address = api.Items.get(json, "addresses");
                /*var location = _.get(json, ["locations", "items", _.get(json, "locations.item")]);
                var attendant = _.get(json, ["attendants", "items", _.get(json, "attendants.item")]);
                var address = _.get(json, ["addresses", "items", _.get(json, "addresses.item")]);*/

                if(!!address) address = _.merge(address, _.pick(location, [ "photo", "logo" ]));

                var permits = _.get(json, "permits.items");
                var types = _.get(json, "media.types.items", _.get(json, "media.types"));
				var users = _.get(json, "users.items");
				var vehicles = _.get(json, "vehicles.items");
				var tenants = _.get(json, "tenants.items");
				var spaces = _.get(json, "spaces.items");
                
                var tenantType = _.get(json, "tenants.type");

                // filter down to unique vehicles
                permits = _.uniqBy(_.map(permits), "vehicle");
                
                permits = _.map(permits, function(item) {
                    
                    item._type = "permit";
					
					//api.Items.vehicle(item, vehicles);
					//api.Items.tenant(item, tenants);
					//api.Items.space(item, spaces);
					
				
                    
                    //if(!!item.tenant) item.tenant.type = item.tenant.type || tenantType;
                    
                    if(!!item.media && item.media.type && !!types && _.isString(item.media.type)) item.media.type = types[item.media.type];

                    //return item;
                    
                    return api.Permits.normalize(item);
                    
                });
                
                postal.publish({
                    topic   : "location.item",
                    data    : {
                        generated: json.generated,
                        item:location,
                    }
                });
                
                postal.publish({
                    topic   : "address.item",
                    data    : {
                        generated: json.generated,
                        item:address,
                    }
                });
                
                // postal.publish({
                //     topic   : "attendant.item",
                //     data    : {
                //         generated: json.generated,
                //         item:attendant,
                //     }
                // });

                var data = {
                    items:permits,
                    //attendant:attendant,
                    address:address,
                    requested:json.requested,
                    generated:json.generated,
                };

                postal.publish({
                    topic   : "permits.vehicles",
                    data    : data,
                });
                
                
                return data;

            });
         }),
    });
}(ParkIQ.API));

// LOCATION
(function(postal, api) {
    
    var id = null;
    var last = null;
    
    postal.subscribe({
        topic:"location.item",
        callback:function(data, envelope) {
            
            var ts = new Date(data.generated || data.ts);
            
            if(!!last && last.getTime() >= ts.getTime()) return; // this isn't newer
            
            var item = data.item;
            
            if(!!item && item.id == id) return; // same
            //console.debug(location);

            // if no attendant or no permits list
            if(!item || !_.get(item, "attendant.permits.list")) {
                id = null;
                return;
            }

            id = item.id;
            //console.log(id);
            //location change, trigger load
            api.Permits.list(id);

            last = ts;

        },
    });

        // trigger a refresh
    window.requestInterval(function() {
        if(!id) return;
        api.Permits.list(id);
    }, 1.5 * 60 * 1000);

    // refresh on permit created
    postal.subscribe({
        topic:"permit.created",
        callback:function(data, envelope) {
            
            if(!id) return;
            api.Permits.list(id);

        },
    });
        
}(postal, ParkIQ.API));

// udpated
(function(postal, api, root, section) {

    var view = function(permits) {

        _.each(permits.items, function(collection, key) {

                        
            var list = section.querySelector("ul[data-records='" + key + "']");
            if(!list) return;

            //console.log(collection);
            
            var items = _.sortBy(collection, [ "vehicle.display", "vehicle" ]);

            list.innerHTML = _.reduce(items, function(html, item) {
                //console.log(item);
                return html + api.Templates[item._type](item);
            }, "");
            list.classList[list.hasChildNodes() ? "remove" : "add"]("empty");
            
            //section.querySelector("nav[data-records] a[data-records='" + key + "']").setAttribute("data-records-count", collection.length);
            
        });
        
        var time = section.querySelector("time[data-valid]");
        time.setAttribute("datetime", permits.generated);
        time.dispatchEvent(new CustomEvent("change", { bubbles:true, }));

        
    };

    var last = null;
    
    postal.subscribe({
        topic:"permits.vehicles",
        callback:function(data, envelope) {
            
            var ts = new Date(data.generated || data.ts);
            
            if(!!last && last.getTime() >= ts.getTime()) return; // this isn't newer

            var permits = data;

            view({
                generated: permits.generated,
                items: {
                    "permits/transient/valid": permits.items,
                },
                //attendant:permits.attendant,
                //address:permits.address,
            });

            last = ts;

            var count = _.size(permits.items);
            _.each(document.querySelectorAll("a[rel='permits/temporary'],a[rel='permits/transient']"), function(a) {	
                a.setAttribute("data-records-count", count);
            });

        },
    });
        
}(postal, ParkIQ.API, document.documentElement, document.querySelector("section[data-records='permits/transient/valid']")));

(function(page, api, root, section) {
    
    var _init = _.once(function(ctx) {
        
        // not sure what we do here...
        
    });
    
    var setup = function(ctx, next) {
        
        _init(ctx);
        next();
        
    };

    


     var index = function(ctx, next) {

        //console.log("permits.index", ctx.params.location);
        
        // show a specific violation
        root.setAttribute("data-records", "permits/transient/valid");
        
        // reset
        var time = section.querySelector("time[data-valid]");
        time.setAttribute("datetime", "");
        time.dispatchEvent(new CustomEvent("change", { bubbles:true, }));
        var list = section.querySelector("ul[data-records='permits/transient/valid']");
        while(list.firstChild) list.firstChild.remove();
        

        // load and update data
        ctx.permits = api.Permits.list(ctx.params.location).then(function(permits) {
            //document.title = _.filter([ "Safelist" , _.get(permits, "attendant.title"), _.get(permits, "address.name") ]).join(" - ");
            document.documentElement.classList.add("ready");
            return permits;
        });
        
        //next();
    };
    
     var exit = function(ctx, next) {
        //view();
        return next();
    };

    page("/:location/permits/temporary", function(ctx) {
        page("/" + ctx.params.location + "/permits/temporary/valid")
    });

    page("/:location/permits/vehicles", function(ctx) {
        page("/" + ctx.params.location + "/permits/temporary/valid")
    });

    _.each([
        "/:location/permits/temporary/valid",
     ], function(path) {
        page(path, index);
        page.exit(path, exit);
    });
    
    
}(page, ParkIQ.API, document.documentElement, document.querySelector("section[data-records='permits/transient/valid']")));

(function(page, api, root) {
    
    var _init = _.once(function(ctx) {
        
        var id = ctx.params.location;

        var attendant = api.attendant.get(id)
        .then(function(attendant) {

            if(!!attendant && !!attendant.id) {

                var title = _.filter([ "Parking at " , _.get(attendant, "address.name") ]).join(" ");

                if(!!attendant.id && id !== attendant.id && !!window.history && !!history.replaceState) history.replaceState({} , title, ctx.pathname.replace("/" + id + "/", "/" + attendant.id + "/"));

            }

            document.documentElement.dispatchEvent(new CustomEvent("ready", { "bubbles": true }));

        });
        
    });
    
    var setup = function(ctx, next) {
        
        _init(ctx);
        next();
        
    };


     var index = function(ctx, next) {
        
        // show a specific violation
        root.setAttribute("data-records", "permits/transient/info");

    };
    
     var exit = function(ctx, next) {
        //view();
        return next();
    };

    _.each([
        "/:location/permits/temporary/info",
     ], function(path) {
        page(path, setup, index);
        page.exit(path, exit);
    });
    
    
}(page, ParkIQ.API, document.documentElement));

export default window;