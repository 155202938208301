import Media from "../components/MediaDetails.svelte";

const container = document.querySelector("article[data-record]");

const component = new Media({
  target: container,
  props: {
    details: null,
  },
});
(function (page, api, root, section, postal) {
  // get an auth that will pass header if there, nothing if not
  function tryAuthorize() {
    // custom auth public & authenticated
    return null;
    // return api.Auth.header()
    // .catch(function(error) {
    //     return null;
    // });
  }

  function view(record) {
    component.$set({
      details: record,
    });

    if (!record) {
      //while (section.firstChild) section.firstChild.remove();
      //section.removeAttribute("data-valid");
      section.removeAttribute("data-permits");
      section.setAttribute("data-record", "media");
      return record;
    }

    //document.title = "Smart Decal";

    var admin = _.get(record, "admin", "");
    if (!!admin) {
      window.location.href = admin;

      return record;
    }

    if (!record.item) return record;

    //console.log("record", record);

    root.setAttribute("data-record", "media/" + _.get(record, "item.id", ""));
    section.setAttribute(
      "data-record",
      "media/" + _.get(record, "item.id", "")
    );

    // section.innerHTML =
    //   header(record) +
    //   template(record) +
    //   permits(record) +
    //   form(record) +
    //   footer(record);
    section.setAttribute(
      "data-permits",
      _.get(record, "permits.length", 0) > 0 ? "true" : "false"
    );

    section.setAttribute(
      "data-valid",
      _.get(record, "item.status.valid", false).toString()
    );
    //root.setAttribute("data-permits", _.get(record, "permits.length", 0) > 0 ? "true" : "false");
    // root.setAttribute(
    //   "data-attendant",
    //   !!_.get(record, "attendant") ? "true" : "false"
    // );

    //section.setAttribute("data-user", _.get(record, "user.id", ""));
    //section.setAttribute("data-activated", _.get(record, "activated", false) === true ? "true" : "false");
    //root.setAttribute("data-media-format", _.get(record, "item.type.format") || "");

    //form(record.attendant, record.item);
    //location(record.location);

    // _.each(section.querySelectorAll("input[type='tel'][name]"), function(input) {
    //     new Formatter(input, {
    //         "pattern": "({{999}}) {{999}}-{{9999}}"
    //     });
    // });

    return record;
  }

  api.Media = {
    get: Promise.method(function (id, location) {
      console.log(id);

      /*
            return Promise.join(api.base(), id, function(base, id) {
                return api.fetch("GET", base + "media/" + id + "?ts=" + requested, null, api.Auth.header());
            })
            */

      var requested = new Date().toISOString();
      return api
        .fetch(
          "GET",
          Promise.join(api.base(), location, id, function (base, location, id) {
            return (
              base +
              "v1/" +
              (!!location ? "locations/" + location + "/" : "") +
              "media/" +
              id +
              "?viewpoint=" +
              encodeURIComponent(dateFns.format(requested))
            );
          }),
          null,
          tryAuthorize()
        )
        .then(function (json) {
          //console.log(json);

          var generated = json.generated;

          json.requested = requested;

          var item = _.get(json, "media.item");
          item = _.get(json, ["media", "items", item], item);

          //if(!item) return json;

          var location = _.get(json, [
            "locations",
            "items",
            _.get(json, "locations.item"),
          ]);
          if (!!location)
            location.admin = _.get(
              json,
              "admin.url",
              json.admin || location.admin
            );
          var admin = null;
          if (!!location)
            admin = _.get(json, "admin.url", json.admin || location.admin);
          if (!!admin)
            admin =
              admin.substr(0, admin.lastIndexOf("/")) + "/media/" + item.id;

          // break admin early...
          if (!!admin) {
            return {
              requested: json.requested,
              generated: json.generated,
              admin: admin,
            };
          }

          var address = _.get(json, [
            "addresses",
            "items",
            _.get(json, "addresses.item"),
          ]);

          if (!!address)
            address = _.merge(address, _.pick(location, ["photo", "logo"]));

          var tenantType = _.get(json, "tenants.type");

          //var location = _.get(json, ["locations", "items", _.get(json, "locations.item")]);
          //var address = _.get(json, ["addresses", "items", location.address], location.address);

          if (!!location)
            postal.publish({
              topic: "location.item",
              data: {
                generated: generated,
                item: location,
              },
            });

          if (!!address)
            postal.publish({
              topic: "address.item",
              data: {
                generated: generated,
                item: address,
              },
            });

          var permits = _.get(json, "permits.items");

          //var types = _.get(json, "media.types.items", _.get(json, "media.types"));

          var users = _.get(json, "users.items");
          var vehicles = _.get(json, "vehicles.items");
          var tenants = _.get(json, "tenants.items", []);
          var spaces = _.get(json, "spaces.items");
          var notes = json.notes;

          // if (!!item.type)
          //   item.type = _.get(json, ["media", "types", item.type], item.type);

          var policies = _.map(
            _.get(json, "policies.items", []),
            function (item) {
              return item;
            }
          );

          if (!!json.tenants)
            postal.publish({
              topic: "tenants.items",
              data: {
                generated: json.generated,
                predefined: !!_.get(json, "tenants.predefined"),
                type: _.get(json, "tenants.type"),
                items: _.map(_.get(json, "tenants.items", [])),
              },
            });

          if (!!permits)
            permits = _.map(permits, function (item) {
              api.Items.vehicle(item, vehicles);
              api.Items.tenant(item, tenants);
              api.Items.space(item, spaces);

              api.Items.user(item.issued, users);
              api.Items.notes(item, notes);

              item.contact = _.get(json, ["contacts", "items", item.id]);

              if (!!item.tenant)
                item.tenant.type = _.get(item, "tenant.type", tenantType);

              return item;
            });

          var valid = _.filter(permits, function (item) {
            return item.expired !== true;
          });

          var user = _.get(json, "users.item");
          if (!!user) user = _.get(json, ["users", "items", user], user);

          var status = {
            activated: permits.length > 0,
            revoked: permits.length > 0 && valid.length <= 0,
            valid: valid.length > 0,
            title:
              permits.length > 0
                ? valid.length == 0
                  ? "Revoked"
                  : "Active"
                : "Activation Required",
          };

          var title = _.filter([
            _.get(valid, "length", 0) > 0 ? "Active" : "",
            _.get(item, "title"),
            _.get(permits, "length", 0) > 0
              ? _.get(valid, "length", 0) <= 0
                ? "Revoked"
                : ""
              : "Activation Required",
          ]).join(" ");

          return {
            //type: "media",
            title,
            status,
            requested: json.requested,
            generated: json.generated,
            policies: policies,
            admin: admin,
            item: item,
            property: location,
            tenants: json.tenants,
            user: user,
            //permits: permits,
            activated: permits.length > 0,
            revoked: permits.length > 0 && valid.length == 0,
            valid: valid,
            permits: _.get(json, ["permits", "for", item.id]),
          };
        })
        .catch(function (error) {
          //if(error.status === 404) alert("Whoops, not found!");
          //else alert("Whoops, something went wrong!");
          console.log("catch", error);
          return Promise.reject(error);
        });
    }),
  };

  var selected = null;
  var scope = null;

  var _init = _.once(function (ctx) {
    // not sure what we do here...
  });

  function setup(ctx, next) {
    _init(ctx);
    next();
  }

  function media(media) {
    root.classList.add("ready");
    return Promise.resolve(media)
      .then(function (result) {
        //console.log("media", result);
        root.removeAttribute("data-records");
        root.setAttribute("data-record", "media/show");
        return result;
      })
      .then(view);
  }

  function search(location) {
    scope = location;

    root.setAttribute("data-records", "media/search");
    root.removeAttribute("data-record");

    startScanner(root.querySelector("[data-records='media'] figure.scanner"));

    root.classList.add("ready");

    _.each(root.querySelectorAll("form[data-record='media']"), function (form) {
      form.reset();
      form.setAttribute("novalidate", "");

      _.each(form.querySelectorAll("input[name='location']"), function (input) {
        if (!!input) input.setAttribute("value", (input.value = location));
      });
    });

    //document.title = "New Smart Decal";
  }

  function show(ctx, next) {
    // root.setAttribute("data-record", "media/show");
    // root.removeAttribute("data-records");

    return Promise.join(
      ctx.params.location,
      ctx.params.media,
      function (location, id) {
        if (id !== selected)
          return Promise.resolve(view()).then(function () {
            return api.Media.get((selected = id), location);
          });
        return api.Media.get((selected = id), location);
      }
    ).then(media);
  }

  var init = _.once(function (ctx) {
    var id = ctx.params.location;

    var attendant = api.attendant.get(id).then(function (attendant) {
      if (!!attendant && !!attendant.id) {
        var title = _.filter([
          "Parking at ",
          _.get(attendant, "address.name"),
        ]).join(" ");

        if (
          !!attendant.id &&
          id !== attendant.id &&
          !!window.history &&
          !!history.replaceState
        )
          history.replaceState(
            {},
            title,
            ctx.pathname.replace("/" + id + "/", "/" + attendant.id + "/")
          );
      }

      document.documentElement.dispatchEvent(
        new CustomEvent("ready", { bubbles: true })
      );
    });
  });

  function activate(ctx, next) {
    //root.removeAttribute("data-record");

    init(ctx);

    return search(ctx.params.location);
  }

  function exit(ctx, next) {
    //view();
    next();
  }

  _.each(["/:location/media/activate"], function (path) {
    page(path, activate);
    page.exit(path, exit);
  });

  _.each(["/:location/media/:media"], function (path) {
    page(path, show);
    page.exit(path, exit);
  });

  // media qr handler
  function go(url) {
    if (!url) return url;

    stopScanner();

    page(url);
  }

  var result = _.debounce(
    function (result) {
      console.log(result);

      // parse the url
      var url = _.attempt(Reflect.construct, URL, [result]); // not a url

      console.log("url", url);

      if (!url || !scope) return;

      // decal...
      var d =
        url.searchParams.get("d") || _.get(url.pathname.split("/d/"), "1");
      if (!!d)
        return go("/" + scope + "/media/" + d + "?qr=decal&scanner=inline");
    },
    1000,
    {
      leading: true,
    }
  );

  function onmessage(e) {
    //console.log(e);
    // e.data
    // e.source
    // e.origin
    if (!e.data || !_.startsWith(e.data, "http")) return; // not a url
    result(e.data);
  }

  window.addEventListener("message", onmessage);

  document.addEventListener("message", onmessage);

  function startScanner(fig) {
    if (!fig) return;
    //console.log(container);
    //container.querySelector("figure.scanner").innerHTML = '<iframe src="" allow="camera"></iframe>';

    stopScanner(); // remove any previous

    var iframe = document.createElement("iframe");
    iframe.setAttribute("allow", "camera");
    iframe.setAttribute(
      "src",
      "https://s3.us-east-2.amazonaws.com/qr-code-reader.parkingboss.com/index.html"
    );
    //iframe.setAttribute("srcdoc", '<!doctype html><meta charset=utf-8><meta http-equiv=X-UA-Compatible content="IE=edge"><meta name=viewport content="width=device-width,initial-scale=1"><link rel="shortcut icon" href=data:image/x-icon;, type=image/x-icon><link rel=stylesheet href=https://s3.us-east-2.amazonaws.com/qr-code-reader.parkingboss.com/styles/app.a7e01188.css?1><script src=https://s3.us-east-2.amazonaws.com/qr-code-reader.parkingboss.com/scripts/main.1156c4bf.js defer></script><div class=wsk-layout><div class=wsk-layout__content><div id=camera class=Camera><canvas class=Camera-display></canvas><div class="CameraRealtime hidden"><video class=Camera-video muted autoplay playsinline></video><div class="Camera-toggle wsk-button wsk-button--fab"><input type=checkbox id=checkbox-1 class=Camera-toggle-input> <label for=checkbox-1><img class=front src=/images/ic_camera_front_24px.06217390.svg> <img class=rear src=/images/ic_camera_rear_24px.f006fc49.svg></label></div></div><div id=camera-fallback class="CameraFallback hidden"><form method=post action=/scan-image class=CameraFallback-form><input type=file accept=image/* capture=camera id=qrcode-input name=qrcode class=CameraFallback-input> <label for=qrcode-input><img src=/images/ic_file_upload_24px.6b30d59f.svg></label> <img class=CameraFallback-image></form></div></div></div></div>');

    fig.appendChild(iframe);
  }

  function stopScanner() {
    _.each(root.querySelectorAll("iframe[allow*='camera']"), function (loader) {
      loader.remove();
    });
  }

  // scan click
  root.addEventListener("click", function (e) {
    if (
      !_.invoke(e, "target.matches", "[data-records='media'] a[href$='#scan']")
    )
      return;

    e.preventDefault();

    startScanner(
      e.target.closest("[data-records='media']").querySelector("figure.scanner")
    );
  });

  root.addEventListener("submit", function (e) {
    if (
      !_.invoke(
        e,
        "target.matches",
        "form[data-record='media'],form[data-ref='media']"
      )
    )
      return;

    e.preventDefault();

    var form = e.target;

    if (!!form.matches(":invalid")) {
      alert("Please complete all fields");
      return;
    }

    if (form.classList.contains("submitting")) return;

    var disable = _.map(
      form.querySelectorAll("button,input,textarea,select")
    ).filter(function (input) {
      input.blur();
      return !input.disabled;
    });

    form.classList.add("submitting");

    //var formData = new FormData(form);

    var location = _.get(
      form.querySelector("input[name='location']"),
      "value",
      ""
    );

    var id = _.get(form.querySelector("input[name='media']"), "value", "");

    disable.forEach(function (input) {
      input.disabled = true;
    });

    var requested = new Date().toISOString();

    // lets resolve base just to be sure
    var min = Promise.delay(1 * 1000);
    return media(api.Media.get(id, location))
      .then(function (record) {
        var id = _.get(record, "item.id");
        document.body.scrollIntoView();
        if (!!id)
          window.history.pushState(
            null,
            document.title,
            "/" + location + "/media/" + id
          );
      })
      .then(function () {
        return min;
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 404:
            alert("Can't find this Smart Decal");
            break;
          case 403:
          case 401:
            alert("Smart Decal has already been activated");
            break;
          case 422:
            alert("Unable to activate this Smart Decal");
            break;
          default:
            alert("Whoops, something went wrong!");
            break;
        }
      })
      .finally(function () {
        form.classList.remove("submitting");
        disable.forEach(function (input) {
          input.disabled = false;
        });
      });
    /*
        .then(function(record);
        return Promise.resolve(api.base())
        .then(function(base) {
            return base + "media/" + formData.get("media");
        })
        .then(function(url) {
            return fetch(url);
        })
        .then(function(response) {

            if(response.ok) return response.json();

            // this might be better as a status error
            switch(response.status) {
                case 404:
                    alert("Can't find this Smart Decal")
                    break;
                case 403:
                    alert("Smart Decal has already been activated")
                    break;
            }

            return null;

        })
        .then(function(json) {
            if(!json) return json;

            var item = _.get(json, "media.item");
            if(!!item) item = _.get(json, ["media", "items", item], item);

            window.history.pushState(null, document.title, "/media/" + item.id);
            return media(json);

            //page("/media/" + item.id);

            //return json;

        })
        .catch(function() {

            alert("Whoops, something went wrong!");

        })*/
  });

  section.addEventListener("submit", function (e) {
    if (
      !_.invoke(e, "target.matches", "form[data-record='permits/continuous']")
    )
      return;

    e.preventDefault();
    var form = e.target;

    if (!!form.matches(":invalid")) {
      alert("Please complete all fields");
      return;
    }

    if (form.classList.contains("submitting")) return;

    var disable = _.map(
      form.querySelectorAll("button,input,textarea,select")
    ).filter(function (input) {
      input.blur();
      return !input.disabled;
    });

    form.classList.add("submitting");

    var formData = new FormData(form);

    disable.forEach(function (input) {
      input.disabled = true;
    });

    var requested = new Date().toISOString();

    // lets resolve base just to be sure
    var min = Promise.delay(1 * 1000);

    return Promise.resolve(api.base())
      .then(function (base) {
        return api.fetch(
          "POST",
          base + "v1/permits/continuous",
          formData,
          tryAuthorize()
        );
      })
      .then(function (json) {
        if (!json) return;

        // get the permit created
        var permit = _.get(json, "permits.item");
        if (!!permit)
          permit = _.get(json, ["permits", "items", permit], permit);

        if (!permit) return Promise.reject(new Error("No permit was created"));
        if (!!permit.media)
          return media(
            api.Media.get(_.get(permit, "media.id", permit.media))
          ).then(function (response) {
            document.body.scrollIntoView();
            return response;
          });

        return (window.location.href = permit.url);
      })
      .then(function () {
        return min;
      })
      .catch(function (response) {
        switch (response.status) {
          case 400:
            // bad request, tripped by any missing required info
            alert("Please confirm all info is entered and correct");
            break;
          case 401:
            // unauthorized, only tripped by mismatch passcode
            alert("Incorrect passcode");
            break;
          case 403:
          case 409:
            // forbidden or conflict
            alert(
              "This Smart Decal may not be activated, please contact your parking manager"
            );
            break;
          default:
            alert("Whoops, something went wrong!");
            break;
        }
      })
      .finally(function () {
        form.classList.remove("submitting");
        disable.forEach(function (input) {
          input.disabled = false;
        });
      });
  });
})(window.page, ParkIQ.API, document.documentElement, container, postal);

export default window;
