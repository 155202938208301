(function(exports) {

    var reserved = [ "q", "qr", "my", "passes", "permits", "attendant", "validate"];

    exports.LocationAlias = {
        fromHost:function(host) {
            if(!host) return null;
        
            host = host.toLowerCase().replace("www.", "");
            var s = host.split(".");
            if(s.length < 3) return null;
            var id = s[0];
            if(_.includes(reserved, id)) return null;

            return id;
        },
    };

}(ParkIQ.API));

export default window;